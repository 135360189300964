import BasicLayout from 'src/layout/Basic';
const Division = () => {
  return (
    <BasicLayout>
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><a href="homepage.html">Inicio</a></li>
            <li className="breadcrumb-item active">Administracion</li>
          </ol>
          <div className="row">
            <h1 className="col-lg-6 mt-4">División Territorial</h1>
            <div className="form-group col-lg-6">
              <input className="form-control py-4" id="inputDepto" type="input" placeholder=" Departamento" />
            </div>
          </div>
          <div className="card mb-4">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-bordered" id="dataTable" width="100%" cellPadding="0">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>DEPARTAMENTO</th>
                      <th>SUPERFICIE PLANTADA (HA)	</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>13</td>
                      <td>ATLANTIDA</td>
                      <td>0.00</td>
                      <td>
                        <div className="row">
                          <div className="col-lg-6"><a className="table-small-btn"><i className="fa fa-search"></i></a></div>
                          <div className="col-lg-6"><a className="table-small-btn"><i className="fa fa-pencil-alt"></i></a></div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>ATLANTIDA</td>
                      <td>0.00</td>
                      <td>
                        <div className="row">
                          <div className="col-lg-6"><a className="table-small-btn"><i className="fa fa-search"></i></a></div>
                          <div className="col-lg-6"><a className="table-small-btn"><i className="fa fa-pencil-alt"></i></a></div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </BasicLayout>
  )
};

export default Division;