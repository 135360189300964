import { gql } from '@apollo/client';
const LOGIN = gql`
mutation login(
  $userName: String!
  $password: String!){
  authenticate(AuthCredentials:{
    name: $userName,
    password: $password
  }){
    accessToken{
      jwtToken
      payload
    }
    refreshToken{
      token
   }
   idToken{
     jwtToken
     payload
   }
  }
}
`;

export default LOGIN;