import { gql } from '@apollo/client';
const GET_ALDEAS = gql`
query Aldeas(
  $id:Float!
){
  find_Aldeas(
    Aldeas:{
      id:$id
    }
  ){
    id
    nombre
  }
}
`;
  export default GET_ALDEAS;

