import Moment from 'react-moment';
import {
  getMuestreoInfo_getMuestreo,
  getMuestreoInfo_getMuestreo_muestreo_detail
} from "src/apollo/queries/types/getMuestreoInfo";
import numeral from 'numeral';

interface MuestreoGrilloProps {
  muestreo: getMuestreoInfo_getMuestreo,
  muestreoDetail: getMuestreoInfo_getMuestreo_muestreo_detail
  tabs: string[],
  currentTab: string
}
const MuestreoGrillo = (props: MuestreoGrilloProps) => {
  const { fecha, metadata, finca, } = props.muestreo;
  const { total, positivos, incidencia, tipo_plaga_enfermedad, registros } = props.muestreoDetail;
  const {tabs, currentTab} = props;
  return (
    <>
      <div
        key="information"
        className={`tab-pane table-responsive fade ${currentTab === tabs[0] ? 'show active' : ''}`}
        id="tab1" role="tabpanel" aria-labelledby="tab1">
        <table className="table table-bordered mt-4" width="100%" cellSpacing="0">
          <tbody>
            <tr>
              <td>Fecha</td>
              <td>
                <Moment format="YYYY-MM-DD">{fecha}</Moment>
              </td>
            </tr>
            <tr>
              <td>Plantas</td>
              <td>30</td>
            </tr>
            <tr>
              <td>Huerta</td>
              <td>{finca.nombre}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>{total}</td>
            </tr>
            <tr>
              <td>Positivos</td>
              <td>{positivos}</td>
            </tr>
            <tr>
              <td>Incidencia</td>
              <td>{numeral(incidencia).format('0.00')}</td>
            </tr>
            <tr>
              <td>Metadata</td>
              <>
                {metadata ?
                  <td>{Object.keys(metadata).map((element: string) => (
                    <div key={element}>{`${element.toUpperCase()}: ${metadata[element]}`}</div>
                  ))}</td>
                  : null}
              </>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        key={`muestreos`}
        className={`tab-pane fade ${currentTab === tabs[1] ? 'show active' : ''}`}
        id="tab2"
        role="tabpanel"
        aria-labelledby="tab2">
        <h4>{`Muestreo de ${tipo_plaga_enfermedad}`}</h4>
        {registros ?
          <table className="table-striped table table-bordered" width="100%" cellSpacing="0">
            <tbody>
              <tr>
                <td>Planta</td>
                <td>No de Plantas con perforaciones</td>
              </tr>
              {registros.map((registro: any, index: number) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{registro.perforaciones}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          :
          <h5>La Información del registro no se encuentra disponible</h5>
        }
      </div>
    </>
  )
}

export default MuestreoGrillo;