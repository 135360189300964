import { RefreshStartAction, RefreshEndAction, REFRESH_START, REFRESH_END } from '../types/refresh.types';

export const refreshStart = () : RefreshStartAction => {
  return {
    type: REFRESH_START
  }
}

export const refreshEnd = () : RefreshEndAction => {
  return {
    type: REFRESH_END
  }
}
