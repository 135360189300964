import { gql } from '@apollo/client';
const UPDATE_USER = gql`
mutation userUpdate(
    $username: String!
    $name: String!
    $family_name: String!
    $approved: Boolean
    $level: [String!]
    ){
    updateUser(AdminUpdateUserDto:
    {
        username:  $username
        name:  $name
        family_name: $family_name
        approved: $approved
        level: $level
    }){
    id
    username
    name
    family_name
    approved
    level
    }
}
`;

export default UPDATE_USER;