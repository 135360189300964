import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { refreshToken, refreshTokenVariables } from 'src/apollo/queries/types/refreshToken';
import REFRESH_TOKEN from 'src/apollo/queries/refreshToken';

const useReportSSO = (username: string, refreshToken: string)=> {
  const [ getRefreshToken , {error, data, loading}]= useMutation<refreshToken, refreshTokenVariables>(
    REFRESH_TOKEN,   
    {
      variables: {
        refreshToken,
        username
      },
      onError(){},
    },
  )

  useEffect(()=>{
    getRefreshToken();
  }, [getRefreshToken, refreshToken]);

  return {
    data,
    error,
    loading,
  };
};

export default useReportSSO;