import {gql} from '@apollo/client';

const FINCAS_BY_INSTITUCION = gql`
query absoluteFincas{
    absoluteFincas{
      id
      nombre
      latitud
      longitud
      altura
      porcentaje_sombra
      densidad_arboles_ha
      superficie
      anio_plantacion
      tecnico{
        username
        name
        id
      }
      tipo_cafe
      tipo_finca
      variedad      
    }
  }
`;

export default FINCAS_BY_INSTITUCION;