import { ListItem, NavList } from "src/models/ListItem";

export const USER_START = 'USER_START';
export const USER_OUT = 'USER_OUT';
export const USER_SET_LEVEL = 'USER_LEVEL';
export const USER_TOGGLE_SIDEBAR = 'USER_TOGGLE_SIDEBAR';

export interface UserState {
  userId: string
  name: string
  roles: string[]
  sidebar: any[]
  navBar: any[]
  currentLevel: string
  toogleSidebar: boolean
}

export interface UserStart {
  userId: string
  name: string
  roles: string[]
  sidebar: ListItem[]
  navBar: NavList[]
  currentLevel: string
}

export interface UserStartAction {
  type: typeof USER_START,
  userInfo: UserStart
}

export interface UserOutAction {
  type: typeof USER_OUT
}

export interface UserSetLevel {
  type: typeof USER_SET_LEVEL,
  level: string
}

export interface UserToggleSidebar {
  type: typeof USER_TOGGLE_SIDEBAR,
  toggle: boolean
}

export 
  type UserActionTypes = UserStartAction | UserOutAction | UserSetLevel | UserToggleSidebar;
