import { useState } from 'react';
import { NavLink } from 'react-router-dom';
interface ConfirmCodeProps {
  onSubmit(code: string): void
  onChangeStep(step: string): void
  onSendAnotherCode(): void
  error: string
  code: string
}
const ConfirmCode = (props: ConfirmCodeProps) => {
  const [code, setCode] = useState(props.code);
  const [submited, setSubmited] = useState(false);
  let codeErrorMessage;
  const errorStyle = {
    borderColor: 'red',
  }
  const validations = {
    isValidCode: () => {
      if(code === ''){
        codeErrorMessage = 'El nombre no puede ir vacío';
        return false;
      }
      if(!(/^\d+$/).test(code)){
        codeErrorMessage = 'El Código solo debe tener numeros';
        return false;
      }
      return true;
    },
  };
  const handleSubmit = (event : React.FormEvent<HTMLFormElement>) =>{
    event.preventDefault();
    setSubmited(true);
    if(validations.isValidCode())
      props.onSubmit(code);
  }

  const back = (event : React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    props.onChangeStep('register');
  }

  const sendNewCode = (event : React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    props.onSendAnotherCode();
  }
  return (
    <>
      <div className="card-body">
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-4">
          <label className="small mb-1" htmlFor="code">Código de Verificacion</label>
          <input 
            className="form-control py-4" 
            id="code" 
            type="text"
            maxLength={6}
            value={code}
            onChange={(event)=>{setCode(event.target.value)}}
            placeholder="Ingresar Código"
            style={submited && !validations.isValidCode() ? errorStyle : {}} 
            />
            {
            codeErrorMessage ? 
            <h4 className="text-center red" >
              {codeErrorMessage}
            </h4>
            : null
          }
        </div>
        <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
          <button className="btn btn-primary btn-lg btn-block">Verificar Código</button>
        </div>
        {props.error ? 
            <div className="mt-4 mb-0">
              <h4 className="text-center font-weight-extrabold red" >{props.error}</h4>
            </div>
          : null
          }
      </form>
      <div className="card-footer text-center">
        <div className="small">
          <a href="/" onClick={sendNewCode}>
            Enviar Nuevo Código
          </a>
        </div>
        <div className="small">
          <a href="/" onClick={back}>
            Regresar
          </a>
        </div>
      </div>
    </div>
  </>
  )
}

export default ConfirmCode;