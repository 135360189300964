import { useEffect, useState } from 'react';
import BasicLayout from 'src/layout/Basic';
import {decodeToken} from 'src/utils/auth';
import CHANGE_PASSWORD from 'src/apollo/queries/chagePassword'
import {useMutation} from '@apollo/client';
import PreLoaderImage from 'src/assets/images/Preloader.gif';
import {authLogout} from 'src/store/actions/auth';
import { useDispatch } from 'react-redux';
import './changePassword.css';

const ChangePassword = () => {
    const [submited, setSubmited] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [oldPasswordError, setOldPasswordError] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [submitError, setSubmitError] = useState('');
    const [changeSuccess, setChangeSuccess] = useState(false);
    const token = localStorage.getItem("accessToken") || '';
    const dispatch = useDispatch();
    let userNameErrorMessage;
    let oldPasswordErrorMessage;
    let newPasswordErrorMessage;
    let confirmPasswordErrorMessage;
    const errorStyle = {
        borderColor: 'red',
    }
    const [changePassword, {data: dataChange, called: calledChange, loading: loadingChange, error: errorChange}] = useMutation(
        CHANGE_PASSWORD,
        {
            variables: {
                accessToken: token,
                oldPassword: oldPassword,
                newPassword: newPassword
            },
            onError(){}
        }
    );
    
    useEffect(()=>{
        if(dataChange && calledChange){
            setChangeSuccess(true)
            setTimeout(()=>{
                dispatch(authLogout());
            },3000);
        }
        if(errorChange){
            console.log(errorChange.message);
            if(errorChange.message === "Incorrect username or password." || 
            errorChange.message.includes('1 validation error detected') || 
            errorChange.message.includes('validation errors detected')){
                setOldPasswordError("Contraseña Incorrecta");
                setSubmitError('');
            }
            else if(errorChange.message.includes("Attempt limit exceeded")){
                setOldPasswordError('');
                setSubmitError('Número de intentos permitidos excedido, por favor inténtelo de nuevo más tarde.');
            }
            else {
                setOldPasswordError('');
                setSubmitError("Opps! Algo ha pasado al intentar cambiar la contraseña.");
            }
        }
    },[dataChange, calledChange, errorChange]);


    const validations = {
        isValidOldPassword: () => {
            if(oldPassword === ''){
              oldPasswordErrorMessage = 'La contraseña actual no puede ir vacía';
              return false;
            }
            return true;
        },
        isValidNewPassword : () => {
            if(newPassword === ''){
              newPasswordErrorMessage = 'La contraseña no puede ir vacía';
              return false;
            }
            if (
              newPassword.length < 8 ||
              !/[a-z]/.test(newPassword) ||
              !/[A-Z]/.test(newPassword) ||
              !/[0-9]/.test(newPassword) ||
              !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(newPassword)
            ){
              newPasswordErrorMessage = `La contraseña debe contener al menos 7 
                caracteres incluyendo mayúsculas, minúsculas, números y 
                símbolos`;
              return false;
            }
            return true;
        },
        isValidConfirmPassword: () => {
            if(confirmPassword === ''){
              confirmPasswordErrorMessage = 'La contraseña no puede ir vacía';
              return false;
            }
            if(newPassword !== confirmPassword){
              confirmPasswordErrorMessage = 'Las contraseñas no coinciden';
              return false
            }
            return true;
          }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmited(true);
        if(validations.isValidOldPassword() &&
          validations.isValidNewPassword() &&
          validations.isValidConfirmPassword()
        ) {
          changePassword();
        }
    }
    
    return (
        <BasicLayout>
            <main>
                <div className="container-fluid">
                    <ol className="breadcrumb mb-4">
                        <li className="breadcrumb-item"><a href="homepage.html">Inicio</a></li>
                        <li className="breadcrumb-item active">Cuenta</li>
                    </ol>
                    <div className="row">
                        <h1 className="col-lg-6 mt-4">Cambiar Contraseña</h1>                        
                    </div>
                    <div className="card mb-4">
                        <div className="card-body">
                            {loadingChange && 
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-6 mx-auto">
                                        <img src={PreLoaderImage} alt="loading" style={{ width: '100%'}}/>
                                    </div>
                                </div>
                            </div>}
                            {!loadingChange && !changeSuccess && <form onSubmit={handleSubmit}> 
                                <div className="form-group">
                                    <label 
                                        className="small mb-1" 
                                        htmlFor="inputOldPassword">Contraseña Actual
                                    </label>
                                    <input 
                                        type="password" 
                                        className="form-control py-4" 
                                        placeholder="Ingresar Contraseña Actual" 
                                        id="inputOldPassword"
                                        value={oldPassword}
                                        onChange={(e)=>{setOldPasswordError('');setOldPassword(e.target.value)}}
                                        style={(submited && !validations.isValidOldPassword()) || (oldPasswordError!=='') ? errorStyle : {}} 
                                    ></input>
                                    {
                                    oldPasswordErrorMessage ? 
                                    <h6 className="text-center red" >
                                        {oldPasswordErrorMessage}
                                    </h6>
                                    : null
                                    }
                                    {
                                    oldPasswordError!=='' ? 
                                    <h6 className="text-center red" >
                                        {!oldPasswordErrorMessage && oldPasswordError}
                                    </h6>
                                    : null
                                    }
                                </div>
                                <div className="form-group">
                                    <label 
                                        className="small mb-1" 
                                        htmlFor="inputNewPassword">Nueva Contraseña
                                    </label>
                                    <input 
                                        type="password"
                                        className="form-control py-4" 
                                        placeholder="Ingresar Nueva Contraseña" 
                                        id="inputNewPassword"
                                        value={newPassword}
                                        onChange={(e)=>setNewPassword(e.target.value)}
                                        style={submited && !validations.isValidNewPassword() ? errorStyle : {}} 
                                    ></input>
                                    {
                                    newPasswordErrorMessage ? 
                                    <h6 className="text-center red" >
                                        {newPasswordErrorMessage}
                                    </h6>
                                    : null
                                    }
                                </div>
                                <div className="form-group">
                                    <label 
                                        className="small mb-1" 
                                        htmlFor="inputConfirmPassword">Confirmar Nueva Contraseña
                                    </label>
                                    <input 
                                        type="password" 
                                        className="form-control py-4" 
                                        placeholder="Confirmar Nueva Contraseña" 
                                        id="inputConfirmPassowrd"
                                        value={confirmPassword}
                                        onChange={(e)=>setConfirmPassword(e.target.value)}
                                        style={submited && !validations.isValidConfirmPassword() ? errorStyle : {}} 
                                    ></input>
                                    {
                                    confirmPasswordErrorMessage ? 
                                    <h6 className="text-center red" >
                                        {confirmPasswordErrorMessage}
                                    </h6>
                                    : null
                                    }
                                </div>
                                <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                                    <button className="btn btn-primary btn-lg" type="submit">Cambiar contraseña</button>
                                </div>
                                {submitError!=='' ? 
                                    <div className="mt-4 mb-0">
                                        <h4 className="text-center font-weight-extrabold red" >{submitError}</h4>
                                    </div>
                                    : null
                                }
                            </form>}
                            {changeSuccess ? 
                                <div className="mt-4 mb-0">
                                    <h4 className="text-center font-weight-extrabold green" >Contraseña cambiada exitosamente</h4>
                                    <h6 className="text-center green">Cerrando sesión</h6>
                                    <div className="spinner mx-auto"></div>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </main>
        </BasicLayout>
    );
}

export default ChangePassword;