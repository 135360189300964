import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PowerBiReports_powerBiReports } from "src/apollo/queries/powerBi/types/PowerBiReports";
import { powerBiGetReports } from "src/store/actions";

const usePowerBiReport = (reportName: string)=> {
  const dispatch = useDispatch();
  const { powerbi } = useSelector((state : any) => state);
  const [reportId, setReportId] = useState('');
  const [embedUrl, setReportEmbed] = useState('');
  dispatch(powerBiGetReports());

  useEffect(() => {
    if(reportName && powerbi.reports.length){
      const currentReport = powerbi.reports.find((reportItem: PowerBiReports_powerBiReports   )=>{
        return reportItem.name === reportName
      });
      setReportId(currentReport.id);
      setReportEmbed(currentReport.embedUrl);
    }
  }, [powerbi.reports, reportName]);

  return {
    reportId,
    reportEmbedUrl: embedUrl,
  };
};

export default usePowerBiReport;