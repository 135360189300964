import {
  getMuestreoInfo_getMuestreo_muestreo_detail as MuestreoDetailType,
  getMuestreoInfo,
} from 'src/apollo/queries/types/getMuestreoInfo';
import Moment from 'react-moment';

interface MuestreoHeaderProps {
  muestreo: getMuestreoInfo
}
const MuestreoHeader = (props: MuestreoHeaderProps) => {
  const { muestreo } = props;
  const { fecha, fenologia, bandolas } = muestreo.getMuestreo;
  const { nombre } = muestreo.getMuestreo.finca;
  const { puntos, tipo_plaga_enfermedad } = muestreo.getMuestreo.muestreo_detail[0];
  return (
    <table className="table table-bordered" width="100%" cellSpacing="0">
      <tbody>
        <tr>
          <td colSpan={1}>
            <span className="bold">Nombre Finca</span>
            <span>{nombre}</span>
          </td>
          <td colSpan={1}>
            <span className="bold">Fecha</span>
            <span>
              <Moment format="YYYY-MM-DD">{fecha}</Moment>
            </span>
          </td>
          {tipo_plaga_enfermedad === 'Grillo Indiano' ? (
            <>
              <td colSpan={1}>
                <span className="bold">Plantas</span>
                <span>30</span>
              </td>
            </>
          ) : (
            <>
              <td colSpan={1}>
                <span className="bold">Fenologia</span>
                <span>{fenologia}</span>
              </td>
              <td colSpan={1}>
                <span className="bold">Bandolas</span>
                <span>{bandolas}</span>
              </td>
              <td colSpan={1}>
                <span className="bold">Plantas</span>
                <span>{puntos}</span>
              </td>
            </>
          )}
        </tr>
      </tbody>
    </table>
  )
};

export default MuestreoHeader;