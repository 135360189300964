import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import POWER_BI_GET_EMBED from "src/apollo/queries/powerBi/getEmbedToken";
import { PowerBiEmbed, PowerBiEmbedVariables } from "src/apollo/queries/powerBi/types/PowerBiEmbed";
import { powerBiGetReports } from "src/store/actions";

const useReportEmbed = (reportId: string)=> {
  const dispatch = useDispatch();
  const [embedToken, setEmbedToken] = useState('');
  const { powerbi } = useSelector((state : any) => state);
  dispatch(powerBiGetReports());

  const [ getEmbebToken , {error, data}]= useMutation<PowerBiEmbed, PowerBiEmbedVariables>(
    POWER_BI_GET_EMBED,
    {
      variables: {
        token: powerbi.accessToken,
        reportId, 
      },
      onError(){},
    },
  )
  useEffect(() => {
    if(reportId){
      getEmbebToken();
    }
}, [getEmbebToken, reportId]);

  useEffect(()=>{
    if(data){
      setEmbedToken(data.powerBiEmbedToken.token);
    }
  }, [data, error])

  return {
    embedToken,
    error,
  };
};

export default useReportEmbed;