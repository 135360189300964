import { gql } from '@apollo/client';
const GET_USER_TECNICO = gql`
query GetDBUsers(
  $skip:Float,
  $take:Float
){
  users(
    page:{
      take:$take,
      skip:$skip
    }
  ){
    id
    name
  }
}`;
  export default GET_USER_TECNICO;