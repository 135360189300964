import {gql} from '@apollo/client';

const FIND_INSTITUCION = gql`
query findAllInstitucion{
    findAllInstitucion{
      id
      pais
      nombre
    }
}
`;

export default FIND_INSTITUCION;