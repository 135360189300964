import { RefreshState, RefreshActionTypes, RefreshStartAction, RefreshEndAction, REFRESH_START, REFRESH_END} from '../types/refresh.types'

const initialState : RefreshState = {
    isRefreshing: false
}

const refreshStart = (state: RefreshState, action: RefreshStartAction) : RefreshState => {
    return {
      ...state,
      ...{
        isRefreshing: true
      }
    }
}

const refreshEnd = (state: RefreshState, action: RefreshEndAction) : RefreshState => {
    return {
      ...state,
      ...{
        isRefreshing: false
      }
    }
}

const reducer = (state = initialState, action: RefreshActionTypes) : RefreshState => {
    switch(action.type){
      case REFRESH_START: return refreshStart(state, action);
      case REFRESH_END: return refreshEnd(state, action);
      default:
        return state;
    }
  }
  
  export default reducer;
  