import * as Icons from '@fortawesome/free-solid-svg-icons';
import { ListItem } from 'src/models/ListItem';

const location = window.location.href;
export const AdminList: ListItem[] = [
  {
    title: 'Inicio',
    icon: Icons.faHome,
    link: '/',
    open: false,
  },
  {
    title: 'Reportes',
    icon: Icons.faRandom,
    open: location.includes('reporte-'),
    items: [/*{
      title: 'Padrón de Fincas',
      link: '/tecnico-padron',
    },*/{
      title: 'Reporte General',
      link: '/reporte-general',
    }, {
      title: 'Reporte por Finca',
      link: '/reporte-finca'
    }, {
      title: 'Altitudes',
      link: '/reporte-altitudes',
    }]
  },
  {
    title: 'Mapas',
    icon: Icons.faMap,
    open: location.includes('mapa-'),
    items: [{
      title: 'Temperatura',
      link: '/mapa-temperatura',
    }, {
      title: 'Precipitación',
      link: '/mapa-precipitacion',
    }, {
      title: 'Condiciones Favorables',
      link: '/mapa-condiciones',
    }, {
      title: 'Puntos Registrados',
      link: '/mapa-puntos',
    }, {
      title: 'Estaciones Meteorológicas',
      link: '/mapa-estaciones',
    }]
  },
  {
    title: 'Herramientas',
    icon: Icons.faTools,
    open: location.includes('herramienta-'),
    items: [{
      title: 'Registro (Historial)',
      link: '/herramienta-registros',
    },{
      title: 'Aplicaciones Móviles',
      link: '/herramienta-apps',
    }, {
      title: 'Manuales',
      link: '/herramienta-manuales',
    }]
  },  {
    title: 'Administración',
    icon: Icons.faToolbox,
    open: location.includes('admin-'),
    items: [{
      title: 'Padrón de Fincas',
      link: '/admin-padron',
    },
    {
      title: 'Asociar Fincas',
      link: '/admin-asociar',
     }, 
    {
      title: 'Usuarios',
      link: '/admin-usuarios',
    }
     , {
       title: 'Soporte',
       link: '/admin-soporte',
     }
   ]
  }
];

export const TechnicalList: ListItem[] = [
  {
    title: 'Inicio',
    icon: Icons.faHome,
    link: '/',
    open: false,
  },
  {
    title: 'Reportes',
    icon: Icons.faRandom,
    open: location.includes('reporte-') || location.includes('tecnico-'),
    items: [{
      title: 'Registros (Historial)',
      link: '/herramienta-registros',
    },
    {
      title: 'Padrón de Fincas',
      link: '/tecnico-padron',
    }, 
    {
      title: 'Reporte por Finca',
      link: '/reporte-finca'
    },
    ]
  },
  {
    title: 'Mapas',
    icon: Icons.faMap,
    open: location.includes('mapa-'),
    items: [{
      title: 'Temperatura',
      link: '/mapa-temperatura',
    }, {
      title: 'Precipitación',
      link: '/mapa-precipitacion',
    }, {
      title: 'Condiciones Favorables',
      link: '/mapa-condiciones',
    }, {
      title: 'Mapas de Puntos Registrados',
      link: '/mapa-puntos',
    }, {
      title: 'Estaciones Meteorológicas',
      link: '/mapa-estaciones',
    }]
  },
  {
    title: 'Herramientas',
    icon: Icons.faTools,
    open: location.includes('herramienta-'),
    items: [{
      title: 'Aplicaciones Móviles',
      link: '/herramienta-apps',
    }, {
      title: 'Manuales',
      link: '/herramienta-manuales',
    }]
  }
];

export const getSideBarContent = (level: string) => {
  if(level === 'Admin'){
    return [...AdminList];
  }
  if(level === 'Tecnico'){
    return [...TechnicalList];
  }
  return [];
}

