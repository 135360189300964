import { gql } from '@apollo/client';
const DELETE_MUESTREO = gql`
mutation deleteMuestreo(
    $MuestreoId: Float!
){
    deleteMuestreo(
        MuestreoId:$MuestreoId
    ){
        mensaje
    }
}
`
export default DELETE_MUESTREO;