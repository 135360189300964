import { 
  getMuestreoInfo, getMuestreoInfoVariables,
} from 'src/apollo/queries/types/getMuestreoInfo';
import GET_MUESTREO from 'src/apollo/queries/getMuestreo';
import { useQuery } from '@apollo/client';

const useGetMuestreo = (muestreoId: number) => {
  const { data, loading, error } = useQuery<getMuestreoInfo, getMuestreoInfoVariables>(
    GET_MUESTREO,
    {
      variables: {
        id: Number(muestreoId),
      },
      onError() {}
    }
  );
  
  return {
    data,
    loading,
    error,
  }
}

export default useGetMuestreo;
