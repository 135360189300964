import BasicLayout from 'src/layout/Basic';
const BitacoraSoporte = () => {
  const sizeRule = {minHeight: '41rem'}
  return (
    <BasicLayout>
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-4">  
            <li className="breadcrumb-item"><a href="homepage.html">Inicio</a></li>
            <li className="breadcrumb-item active">Administración</li>
          </ol>
          <div className="row">
            <h1 className="mt-4">Soporte</h1>
          </div>
          <div className="card my-4">
            <div className="card-body">
              <div className="row align-items-center">
                <h5 className="font-weight-extrabold col-lg-12">Solicite ayuda al equipo de administración y desarrollo de SATIHCAFE</h5><br/>
                
              </div>
              <div className="row align-items-center"><a className="btn btn-primary col-lg-3 mt-4" href="mailto:support@goithn.com">Contáctenos</a>
              </div>
            </div>
          </div>
          </div>
      </main>
    </BasicLayout>
  )
};

export default BitacoraSoporte;