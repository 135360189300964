import { gql } from "@apollo/client";

const UPDATE_MUESTREO_DETAIL = gql`
mutation updateMuestreoDetail(
  $id: ID!
  $registros: JSON!
  $incidencia: Float!
  $positivos: Float!
  $total: Float!
  ){
  updateMuestreoDetail(MuetreoRegisterInput:{
    id: $id
    registros: $registros
    incidencia: $incidencia
    positivos: $positivos
    total: $total
  }){
    id
    tipo_plaga_enfermedad
     puntos
     tipo
     total
     positivos
     incidencia
     registros
  }
}
`;

export default UPDATE_MUESTREO_DETAIL;