import { gql } from '@apollo/client';
const GET_ESTACION_INFO_BYNAME = gql`
query getEstacionInfoByName(
    $name: String!
    ){
        estacionInfoByName(EstacionInfoByNameDto: {
            name: $name
    })
  }
`
export default GET_ESTACION_INFO_BYNAME;