import { gql } from '@apollo/client';
const DELETE_USER = gql`
mutation deleteUser(
    $username: String!
){
    deleteUser(AdminDeleteUserDto:{
    username:$username
    }){
        mensaje
    }
}
`
export default DELETE_USER;