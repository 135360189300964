import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { HashRouter } from 'react-router-dom';
import './assets/css/styles.css';
import './assets/css/theme-ihcafe.css';
import './assets/css/powerbi.css';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import apolloClient from './apollo/index';
import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import authReducer from './store/reducers/auth';
import userReducer from './store/reducers/user';
import powerbiReducer from './store/reducers/powerbi';
import thunk from 'redux-thunk';
import refreshReducer from './store/reducers/refresh';

declare var window : any

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  powerbi: powerbiReducer,
  refresh: refreshReducer
});

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));

const app = (
  <Provider store={store}>
    <ApolloProvider client={apolloClient}>
      <HashRouter>
          <App />
      </HashRouter>
    </ApolloProvider>
  </Provider>
);

ReactDOM.render( app, document.getElementById( 'root' ) );
