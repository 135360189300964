import BasicLayout from 'src/layout/Basic';
import PowerBiReport from 'src/components/PowerBiReport';
import { useHistory } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import PreLoaderImage from 'src/assets/images/Preloader.gif';
import { useSelector } from 'react-redux';
import TabTitles from 'src/components/Tabs';

const Registros = () => {
  const history = useHistory();
  const reportRef = useRef<any>(null);
  const [loadingExport, setLoadingExport] = useState(false);
  const reporte = process.env.REACT_APP_REPORT as string;
  const { user } = useSelector((state: any) => state);
  const [filters, setFilters] = useState<any>([]);

  const tabs = [
    'Selección Múltiple',
    'Grillo Indiano'
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  useEffect(() => {
    if (user.currentLevel === 'Admin') {
      setFilters([]);
    } else {
      setFilters([{
        table: 'satihcafe tecnicos',
        column: 'username',
        value: user.userId
      }]);
    }
  }, [user.currentLevel, user.userId]);
  const onClickHyperlink = (event: any) => {
    const url = event.detail.url.split('#')[1].split('?');
    const parameters = url[1];
    history.push({
      pathname: url[0],
      search: `?${parameters}`,
    });
  };
  const onClickDownload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (reportRef.current) {
      setLoadingExport(true);
      reportRef.current?.exportData('xls', 'Registro', 'Registro.xlsx');
    }

  };
  const onFinishExport = () => {
    setLoadingExport(false);
  };
  const renderEnfermedades = () => {
    return (
      <div
        className="tab-pane table-responsive fade show active"
        id="tab1" role="tabpanel" aria-labelledby="tab1"
      >
        <PowerBiReport
          ref={reportRef}
          className="general-report"
          pageName={currentTab}
          filters={filters}
          reportName={reporte}
          customEvent={onClickHyperlink}
          onFinishExport={onFinishExport}
        />
      </div>
    );
  };

  return (
    <BasicLayout>
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><a href="/">Inicio</a></li>
            <li className="breadcrumb-item active">Herramientas</li>
          </ol>
          <div className="row">
            <h1 className="col-lg-9">Registros</h1>
            <div className="col-lg-3">
              <>
                {loadingExport ?
                  <div style={{ textAlign: 'center' }}>
                    <img src={PreLoaderImage} alt="loading" style={{ height: '150px' }} />
                  </div>
                  :
                  <a
                    className="btn btn-primary btn-sm"
                    href="#/"
                    onClick={
                      onClickDownload
                    }>
                    Descarga Excel
              </a>
                }
              </>

            </div>
          </div>
          <div className="row mt-4 mt-lg-0">
            <div className="col-12">
              <div className="card mb-4 card-muestreos">
                <div className="card-body">
                  <TabTitles
                    titles={tabs}
                    onClickTab={setCurrentTab}
                    current={currentTab}
                  />
                  <div className="tab-content pt-4" id="myTabContent">
                    {renderEnfermedades()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </BasicLayout>
  )
};

export default Registros;