import { gql } from '@apollo/client';
const GET_USERS = gql`
query findAllUsers{
    findAllUsers{
    name
    username
    family_name
    phone_number
    level
    approved
    email
  }
}
`;

export default GET_USERS;