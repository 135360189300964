import { gql } from "@apollo/client";

const POWER_BI_GET_EMBED = gql`
mutation PowerBiEmbed(
  $token: String! 
  $reportId: String!
  ) {
  powerBiEmbedToken(PowerBiToken: {
    token: $token
    reportId:$reportId
  }){
    token
  }
}
`;

export default POWER_BI_GET_EMBED;