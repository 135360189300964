import BasicLayout from 'src/layout/Basic';
import PowerBiReport from 'src/components/PowerBiReport';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const ReporteFinca = () => {
  const { user } = useSelector((state : any) => state);
  const [filters, setFilters] = useState<any>([]);
  const reporte = process.env.REACT_APP_REPORT as string;

  useEffect(()=>{
    if(user.currentLevel === 'Admin'){
      setFilters([]);
    }else{
      setFilters([{
        table: 'satihcafe tecnicos',
        column: 'username',
        value: user.userId
      }]);
    }
  },[user.currentLevel, user.userId]);
  return (
    <BasicLayout>
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><a href="homepage.html">Inicio</a></li>
            <li className="breadcrumb-item active">Reportes</li>
          </ol>
          <h1 className="mt-4">Reporte por Finca</h1>
          <div className="card mb-4">
          <div className="card-body">
            <PowerBiReport 
            className="general-report"
            pageName="Fincas"
            reportName={reporte}
            filters={filters}
          />
          </div>
        </div>
        </div>
      </main>
    </BasicLayout>
  )
};

export default ReporteFinca;