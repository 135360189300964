import { useHistory } from "react-router-dom";

interface SuccessProps {
  title : string
}
const SuccessForm = (props:SuccessProps) => {
  const { title } = props;
  const history = useHistory();
  const onClick = () => {
    history.push('/login');
  };
  return (
    <div className="card-body">
      <div className="form-group mb-4">
        <h4 className="text-center">{title}</h4>
      </div>
      <div className="form-group mt-4 mb-0">
        <button 
          className="btn btn-primary btn-block btn-lg"
          onClick={onClick}>
          Ir al inicio de Sesión
        </button>
      </div>
    </div> 
  );
}

export default SuccessForm;