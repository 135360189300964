import {gql} from '@apollo/client';

const  REFRESH_TOKEN = gql`
    mutation refreshToken(
        $username: String!
        $refreshToken: String!
    ){
        refreshToken(AuthRefreshTokenDto:{
            username: $username,
            refreshToken: $refreshToken
          })
          {
            idToken
            refreshToken
            accessToken
          }
    }
`;

export default REFRESH_TOKEN;