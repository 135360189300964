import { UserSetLevel, UserStart, UserStartAction, UserToggleSidebar, 
  USER_SET_LEVEL, USER_START, USER_TOGGLE_SIDEBAR 
} from '../types/user.types';

export const userStart = (userInfo: UserStart) : UserStartAction => {
  return {
    type: USER_START,
    userInfo,
  }
}

export const userSetLevel = (level: string) : UserSetLevel => {
  return {
    type: USER_SET_LEVEL,
    level
  }
}

export const userToggleSidebar = (toggle: boolean): UserToggleSidebar => {
  return {
    type: USER_TOGGLE_SIDEBAR,
    toggle,
  }
}
