import {gql} from '@apollo/client'

const FILTER_USERFINCAS = gql`
query findUserFincas(
    $parametro:String!
  ){
    findUserFincas(Filter: {
      parametro: $parametro
    }){
      user{
      id
      name
      username
      fincas{
        id
        tipo_finca
        nombre
        area
        lote
        aldea_n{
          id
          nombre
          municipios{
            id
            nombre
            departamento{
              id
              nombre
            }
          }
        }
        tipo_huerta
        variedad
        porcentaje_sombra
        densidad_arboles_ha
        superficie
        altura
        anio_plantacion
        tipo_cafe
        latitud
        longitud
        tecnico{
          id
          name
        }
        propietario{
          id
          name
        }
        productor{
          id
          name
        }
      }
    }
    }
  }
`;

export default FILTER_USERFINCAS;