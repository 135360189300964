import BasicLayout from 'src/layout/Basic';
import PowerBiReport from 'src/components/PowerBiReport';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const MapaEstaciones = () => {
  const { user } = useSelector((state : any) => state);
  const [filters, setFilters] = useState<any>([]);
  const reporte = process.env.REACT_APP_REPORT as string;

  useEffect(()=>{
    if(user.currentLevel === 'Admin'){
      setFilters([]);
    }else{
      setFilters([{
        table: 'satihcafe tecnicos',
        column: 'username',
        value: user.userId
      }]);
    }
  },[user.currentLevel, user.userId]);
  return (
    <BasicLayout>
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><a href="homepage.html">Inicio</a></li>
            <li className="breadcrumb-item active">Mapas</li>
          </ol>
          <h1 className="mt-4">Puntos Registrados</h1>
          <div className="card mb-4">
          <PowerBiReport 
                className="general-report"
                pageName="Mapa Puntos Registrados"
                reportName={reporte}
                filters={filters}
              />
          </div>
        </div>
      </main>
    </BasicLayout>
  )
};

export default MapaEstaciones;