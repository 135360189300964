import {gql} from '@apollo/client';

const CHANGE_PASSWORD = gql`
    mutation changePassword(
        $accessToken: String!
        $oldPassword: String!
        $newPassword: String!
    ){
        changePassword(AuthChangePassword:{
            accessToken: $accessToken
            oldPassword: $oldPassword
            newPassword: $newPassword
        })
    }
`;

export default CHANGE_PASSWORD;