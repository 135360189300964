import { gql } from '@apollo/client';
const ADD_PROPIETARIO = gql`
mutation add_propietario(
    $name:String!
    $family_name:String!
){
    add_propietario(Propietario:{
        name:$name
        family_name:$family_name
    }){
        id
        name
        family_name
       }    
  }`;

  export default ADD_PROPIETARIO;