import logoLogin from '../../assets/images/logoWithoutBackground.png';
interface HeaderProps {
  title: string;
  description?: string;
}
const Header = ({title, description}: HeaderProps) => {
  return (
    <div className="card-header">
      <img alt="" className="logo-authentication" src={logoLogin} />
      <h3 className="text-center font-weight-extrabold">{title}</h3>
      { description ? 
        <h4 className="text-center font-weight-extrabold">{description}</h4> 
        : null}
  </div>
  );
}

export default Header;