import BasicLayout from 'src/layout/Basic';
import WindyMaps from './MapaWindy';
const MapaTemperatura = () => {
  return (
    <BasicLayout>
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><a href="homepage.html">Inicio</a></li>
            <li className="breadcrumb-item active">Mapas</li>
          </ol>
          <h1 className="mt-4">Temperatura</h1>
          <div className="card mb-4">
            <WindyMaps overlay="temp" width="100%" height="900px"/>
          </div>
        </div>
      </main>
    </BasicLayout>
  )
};

export default MapaTemperatura;