export const POWER_BI_SUCCESS = 'POWER_BI_SUCCESS';
export const POWER_BI_FAIL = 'POWER_BI_FAIL';
export const POWER_BI_SET_REPORTS = 'POWER_BI_SET_REPORTS';

export interface PowerBiState {
  accessToken: string
  refreshToken: string
  reports: PowerBiReport[]
  error: string
}

export interface PowerBiReport{
  id: string
  name: string
  embedUrl: string
}

export interface PowerBiSuccessAction {
  type: typeof POWER_BI_SUCCESS
  accessToken : string
}

export interface PowerBiSuccess {
  accessToken : string
}

export interface PowerBiFailAction {
  type: typeof POWER_BI_FAIL
  error: string
}

export interface PowerBiSetReportsAction {
  type: typeof POWER_BI_SET_REPORTS
  reports: PowerBiReport[]
}

export 
  type PowerBiActionTypes =
    PowerBiSuccessAction |
    PowerBiFailAction |
    PowerBiSetReportsAction;