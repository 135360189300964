import {
  getMuestreoInfo_getMuestreo_muestreo_detail as MuestreoDetailType,
} from 'src/apollo/queries/types/getMuestreoInfo';
import ClassNames from 'classnames';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';

interface MuestreoRegistroProps {
  tipo: string
  title: string
  muestreoDetail: MuestreoDetailType[]
  bandolas: number
  plantaIndex: number
}

interface BandolaInfoType {
  plaga: string
  bandola1: string
  bandola2: string
}

interface RegisterDataType {
  bandolaInfo: BandolaInfoType[],
  total1: number
  total2: number
}

const initBandola = (index: number, elements: MuestreoDetailType[]): BandolaInfoType[] => {
  return elements.map((element) => {
    return {
      id: element.id,
      plaga: element.tipo_plaga_enfermedad,
      bandola1: element.registros[index].bandola_1.positivo,
      bandola2: element.registros[index].bandola_2 ? element.registros[index].bandola_2.positivo : '',
    }
  });
};

const MuestreoRegistro = forwardRef((props: MuestreoRegistroProps, ref: any) => {
  const { muestreoDetail, tipo, title, bandolas, plantaIndex } = props;
  const muestreosTypes = muestreoDetail.filter(
    (registro: MuestreoDetailType) => registro.tipo.toLowerCase() === tipo
  );
  const [bandolaInfo, setBandolaInfo] = useState(initBandola(plantaIndex, muestreosTypes));
  const [total1, setTotal1] = useState(0);
  const [total2, setTotal2] = useState(0);
  useEffect(() => {
    if (muestreosTypes.length > 0) {
      setBandolaInfo(initBandola(plantaIndex, muestreosTypes));
      setTotal1(muestreosTypes[0].registros[plantaIndex].bandola_1.total);
      if (bandolas > 1) {
        setTotal2(muestreosTypes[0].registros[plantaIndex].bandola_2.total);
      }
    }
  }, [plantaIndex]);

  useImperativeHandle(
    ref,
    () => ({
      getRegisterData(): RegisterDataType {
        return {
          total1: Number(total1),
          total2: Number(total2),
          bandolaInfo,
        }
      }
    }),
  );

  if (muestreosTypes.length === 0) {
    return null;
  }
  const classFirstInput = ClassNames({
    'col-6': bandolas === 1,
    'col-4': bandolas > 1
  });

  const onChange = (e: any, index: number, bandola: number) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    const newBandolas = [...bandolaInfo];
    if (bandola === 1) {
      newBandolas[index].bandola1 = value;
    }

    if (bandola === 2) {
      newBandolas[index].bandola2 = value;
    }
    setBandolaInfo(newBandolas);
  }

  const onChangeTotal = (e: any, bandola: number) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    if (bandola === 1) {
      setTotal1(value);
    }
    if (bandola === 2) {
      setTotal2(value);
    }
  }
  return (
    <>
      <div className="row">
        <div className="col-3"></div>
        <div className={`${classFirstInput} bold`} style={{ textAlign: "center" }}>
          <span>Bandola 1</span>
        </div>
        {
          bandolas > 1 ?
            <div className="col-4 bold" style={{ textAlign: "center" }}>
              <span>Bandola 2</span>
            </div> : null
        }
      </div>
      <div className="form-group row">
        <div className="col-3">
          <label className="small mb-1" htmlFor="inputCountry">{`${title} Total`}</label>
        </div>
        <div className={classFirstInput}>
          <input
            className="form-control py-4"
            value={total1}
            onChange={(e) => onChangeTotal(e, 1)}
          />
        </div>
        {
          bandolas > 1 ?
            <div className="col-4">
              <input
                className="form-control py-4"
                value={total2}
                onChange={(e) => onChangeTotal(e, 2)}
              />
            </div> : null
        }
      </div>
      {bandolaInfo.map((register: BandolaInfoType, index: number) => {
        return (
          <div key={index} className="form-group row">
            <div className="col-3">
              <label className="small mb-1" htmlFor="inputCountry">{register.plaga}</label>
            </div>
            <div className={classFirstInput}>
              <input
                className="form-control py-4"
                value={register.bandola1}
                onChange={(e) => onChange(e, index, 1)}
              />
            </div>
            {
              bandolas > 1 ?
                <div className="col-4">
                  <input
                    className="form-control py-4"
                    value={register.bandola2}
                    onChange={(e) => onChange(e, index, 2)}
                  />
                </div> : null
            }
          </div>
        )
      }
      )}
    </>
  );
});

export default MuestreoRegistro