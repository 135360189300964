import BasicLayout from 'src/layout/Basic';
import Aplicacion_web from 'src/assets/manuals/Aplicacion_web.pdf';
import Manual_App_Movil from 'src/assets/manuals/Manual_App_Movil.pdf';
import Manual_Tecnico_Aplicacion_Web from 'src/assets/manuals/Manual_Tecnico_Aplicacion_Web.pdf';
import Manual_Tecnico_app_movil from 'src/assets/manuals/Manual_tecnico_app_movil.pdf';

const Manuales = () => {
  return (
    <BasicLayout>
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><a href="homepage.html">Inicio</a></li>
            <li className="breadcrumb-item active">Herramientas</li>
          </ol>
          <h1 className="mt-4">Manuales</h1>
          <div className="card my-4">
            <div className="card-body">
              <div className="row align-items-center">
                <h5 className="font-weight-extrabold col-lg-9">Manual técnico para la plataforma web</h5><a className="btn btn-primary col-lg-3 mt-4" href={Manual_Tecnico_Aplicacion_Web} download>Descargar</a>
              </div>
            </div>
          </div>  
          <div className="card my-4">
            <div className="card-body">
              <div className="row align-items-center">
                <h5 className="font-weight-extrabold col-lg-9">Manual técnico para la aplicación móvil</h5><a className="btn btn-primary col-lg-3 mt-4" href={Manual_Tecnico_app_movil} download>Descargar</a>
              </div>
            </div>
          </div>          
          <div className="card my-4">
            <div className="card-body">
              <div className="row align-items-center">
                <h5 className="font-weight-extrabold col-lg-9">Manual de usuario para la plataforma web</h5><a className="btn btn-primary col-lg-3 mt-4" href={Aplicacion_web} download>Descargar</a>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-body">
              <div className="row align-items-center">
                <h5 className="font-weight-extrabold col-lg-9">Manual de usuario para la aplicación móvil</h5><a className="btn btn-primary col-lg-3 mt-4" href={Manual_App_Movil} download>Descargar</a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </BasicLayout>
  )
};

export default Manuales;