import * as Icons from '@fortawesome/free-solid-svg-icons';
import { NavList } from 'src/models/ListItem';

const AdminNavList : NavList[] = [
  {
    title: 'Cambiar Nivel',
    classValue: 'mr-0 mr-md-3 my-2 my-md-0',
    icon: Icons.faRandom,
    items: [{
      title: 'Administrador',
      functionName: 'changeAdmin',
    }, {
      title: 'Técnico',
      functionName: 'changeTecnico',
    }]
  }, 
  {
    title: 'Hola $$user.name$$',
    classValue: 'ml-md-0',
    icon: Icons.faUser,
    items: [{
      title: 'Cambiar Contraseña',
      functionName: 'handleSetup',
    }, {
      title: 'Salir',
      functionName: 'handleLogout',
    }]
  }
];


const TechnicalNavList : NavList[] = [
  {
    title: 'Hola $$user.name$$',
    classValue: 'mr-0 mr-md-3 my-2 my-md-0',
    icon: Icons.faUser,
    items: [{
      title: 'Cambiar Contraseña',
      functionName: 'handleSetup',
    }, {
      title: 'Salir',
      functionName: 'handleLogout',
    }]
  }
];

export const getNavBarContent = (role: string, userName: string, roles: string[]) : any[] =>{
  const hasAdmin = roles.indexOf('Admin') !== -1  || roles.indexOf('admin') !== -1 ;
  if(hasAdmin || role === 'Admin' || role[0] === 'Admin'  ||  role[0] === 'admin' ){
    const newList = [...AdminNavList];
    newList[1].title = `Hola ${userName}`;
    return newList;
  } else 
  if(role === 'Tecnico' || role[0] === 'Tecnico' || role[0] === 'tecnico'){
    const newList = [...TechnicalNavList];
    newList[0].title = `Hola ${userName}`;
    return newList;
  }

  return [];
};
