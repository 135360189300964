import BasicLayout from 'src/layout/Basic';
import { Link, useHistory } from 'react-router-dom';
import PowerBiReport from 'src/components/PowerBiReport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const MapaFincas = () => {
  const history = useHistory();
  const reporte = process.env.REACT_APP_REPORT as string;
  const { user } = useSelector((state : any) => state);
  const [filters, setFilters] = useState<any>([]);

  useEffect(()=>{
    if(user.currentLevel === 'Admin'){
      setFilters([]);
    }else{
      setFilters([{
        table: 'satihcafe tecnicos',
        column: 'username',
        value: user.userId
      }]);
    }
  },[user.currentLevel, user.userId]);
  return (
    <BasicLayout>
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item">
              <Link to="/">
                Inicio
              </Link>
            </li>
            <li className="breadcrumb-item active">Administracion</li>
            <li className="breadcrumb-item">
              <Link to="/admin-padron">
                Padron de Fincas
              </Link>
            </li>
          </ol>
          <div className="row">
          <h1 className="col-lg-10 mt-4">Mapa de Fincas</h1>
          <div className="col-lg-2">
          <a 
              className="nav-link btn btn-aceptar btn-sm mb-4"
              onClick={(e)=>{e.preventDefault();history.goBack()}}>
              <FontAwesomeIcon icon={faArrowAltCircleLeft}></FontAwesomeIcon>
              <span className="ml-2 d-none d-lg-inline">Regresar</span>
            </a>
          </div>
          </div>
          <div className="card mb-4">
            <div className="card-body">
              <PowerBiReport 
                className="general-report"
                pageName="Mapa de Fincas"
                reportName={reporte}
                filters={filters}
              /> 
            </div>
          </div>
        </div>
      </main>
    </BasicLayout>
  )
};

export default MapaFincas;