import {gql} from '@apollo/client';
const CREATE_USER = gql`
    mutation createUser(
        $name: String!
        $family_name: String!
        $username: String!
        $password: String!
        $gender: String!
        $level: [String!]
        $phone_number: String!
    ){
        createUser(User:{
            name: $name
            family_name: $family_name
            username: $username
            password: $password
            gender: $gender
            level: $level
            phone_number: $phone_number
        }){
            name
            family_name
        }
    }
`;
export default CREATE_USER;