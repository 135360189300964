import BasicLayout from 'src/layout/Basic';
import PowerBiReport from 'src/components/PowerBiReport';
import { NavLink } from 'react-router-dom';
import { Modal } from '@material-ui/core';
import { ModalBody} from 'reactstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { EstacionInfo } from 'src/models/Estacion';
import { useLazyQuery} from '@apollo/client';
import GET_ESTACION_INFO_BYNAME from 'src/apollo/queries/estacionInfoByName';
import {traducciones} from 'src/utils/traducciones'

const MapaEstacionesMeteorologicas = () => {
  const reporte = process.env.REACT_APP_REPORT as string;
  const [open, setOpen] = useState(false);
  const [currentPoint, setCurrentPoint] = useState<EstacionInfo>();
  const [estacionByname,setEstacionByName]=useState([]);
  const [errorAldea, setErrorAldea] = useState('');
  const [getEstacionInfoBbyName,{loading:loadingEstacionByName, error:errorEstacionByName, data:dataEstacionByName}]=useLazyQuery(GET_ESTACION_INFO_BYNAME,);
  let errorMessage= "La información no está disponible para la Aldea Seleccionada"
  
  const onClickEstacion = (event: any) => {
    const { visual, dataPoints } = event.detail;
    if (visual.type === 'map' && dataPoints && dataPoints.length) {
       const dataInfo = {} as any;
      dataPoints[0].identity.forEach((item: any)=>{
        dataInfo[item.target.column] = item.equals;
        try{
        getEstacionInfoBbyName({
          variables:{
            name: dataInfo?.Aldea
          }
      })}catch{
        setEstacionByName([]);
        return setErrorAldea(errorAldea);
      }
    });
      setCurrentPoint(dataInfo);
      setOpen(true);
    }
   };
 
  useEffect(()=>{
    if(!loadingEstacionByName && dataEstacionByName){
      setEstacionByName(dataEstacionByName.estacionInfoByName);
    }
    if(errorEstacionByName){
      setEstacionByName([]);
      setErrorAldea(errorMessage);
    }
  },[dataEstacionByName,loadingEstacionByName]);

  const handleClose = () => {
    setErrorAldea("");
    setOpen(false);
  };

  const body = (
    <div style={{ overflowY: 'auto', overflowX: 'hidden', position: 'absolute', width: '470px', height: '600px', backgroundColor: 'white', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: 10 }}>
      <ModalBody className="ml-3" style={{ overflowY: 'auto', position: 'absolute', backgroundColor: 'white', width: '430px' }}>
        <div className="pt-0 float-right">
          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </a>
        </div>
        <div className="card-body">
          <div className="card-title"> 
              <ul className="list-group list-group-flush">
                <li className="list-group-item"><label>Aldea: {currentPoint?.Aldea}</label></li>
                <li className="list-group-item"><label>Latitud: {currentPoint?.Latitud}</label></li>
                <li className="list-group-item"><label>Longitud: {currentPoint?.Longitud}</label></li>
                  {estacionByname.map((item:any)=>{
                    const label = traducciones.find(traduccion => traduccion.name === item.name)?.value || item.name;
                    return(<li key={item.name} className="list-group-item"><label>{label}: {item.value}</label></li>)
                  })}
              </ul>
              {errorAldea ? <h4 style={{marginTop:'10%'}} className="text-center red">{errorAldea}</h4>:null}
         </div>
        </div>
      </ModalBody>
    </div>
  );
  return (
    <BasicLayout>
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item">
              <NavLink to="/">Inicio</NavLink>
            </li>
            <li className="breadcrumb-item active">Mapas</li>
          </ol>
          <h1 className="mt-4">Estaciones Meteorológicas</h1>
          <div className="card mb-4">
            <div className="card-body">
              <PowerBiReport
                className="general-report"
                pageName="Estaciones Meteorologicas"
                reportName={reporte}
                onDataSelected={onClickEstacion}
              />
            </div>
          </div>
        </div>
      </main>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </BasicLayout>
  )
};

export default MapaEstacionesMeteorologicas;