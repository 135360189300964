import {gql} from '@apollo/client';

const GET_TECNICOS = gql`
query findUsersByLevel{
    findUsersByLevel{
      username
      name
      id
    }
  }
`;

export default GET_TECNICOS;