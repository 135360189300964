import BasicLayout from 'src/layout/Basic';
import {useEffect} from 'react';

interface NavItemProps{
  overlay: string
  width: string
  height: string
}
const WindyMaps = (props: NavItemProps) => {
  const {overlay, width, height} = props;
  const options = {
    key: 'GZcnUhY9Q0oyHCZayEx4mDS8Ra2Mibzs', // REPLACE WITH YOUR KEY !!!
    verbose: true,
  };

  useEffect(()=> {
        // Initialize Windy API
  (window as any).windyInit(options, (windyAPI: { store: any; broadcast: any; picker: any; }) => {
    const { store, broadcast, picker } = windyAPI;
    // broadcast is main Windy's event emmiter that
    // let you know what is happening inside
  
    // Change overlays programatically
    // const overlays = ['rain', 'wind', 'temp', 'clouds'];
    let i = 0;
    store.set('overlay', overlay);
//     picker.on('pickerOpened', (latLon: any) => {
//       // picker has been opened at latLon coords  
//       const { lat, lon, values, overlay } = picker.getParams();
//       // -> 48.4, 14.3, [ U,V, ], 'wind'
//       console.log(lat, lon, values, overlay);
  
//       // const windObject = utils.wind2obj(values);
//       // console.log(windObject);
//   });
  
  picker.on('pickerMoved', (latLon: any) => {
      // picker was dragged by user to latLon coords
  });
  
  picker.on('pickerClosed', () => {
      // picker was closed
  });
  
    // Observe the most important broadcasts
    broadcast.on('paramsChanged', (params: any) => {
    });
  
    broadcast.on('redrawFinished', (params: any) => {
        picker.open({ lat: 14.821572115749941, lon: -87.00413801671282 });
        });
    });
  });

  return (
       <div className="card-body" id="windy" style={{ width: width,
                height: height}}>
            </div>
  )
};

export default WindyMaps;