import { NavLink } from 'react-router-dom';
import ClassNames from 'classnames';
import { useRef, useState } from 'react';
import useOutsideClick from 'src/hooks/clickOutside';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface NavItemOptions {
  title: string
  functionName: string
}
interface NavItemProps{
  title: string
  classValue: string
  icon: IconDefinition
  navFunctions: {
    [key: string] : any
  }
  link?: string
  items?: NavItemOptions[]
}
const NavItem = (props: NavItemProps) => {
  const { classValue, title, items, link, navFunctions, icon } = props;
  const ref = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  
  useOutsideClick(ref, ()=>{
    setShowDropdown(false);
  });

  const dropdownClass = ClassNames({
    'show': showDropdown,
  });
  
  const toogleList = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    setShowDropdown(!showDropdown);
  };
  return (
    <ul ref={ref} className={`navbar-nav ml-auto ${classValue}`}>
      <li className="nav-item dropdown">
        { link ? 
          <NavLink 
            to={link}
            className="nav-link" 
            id="navLink" 
            role="button" 
            data-toggle="link" 
            aria-haspopup="true" 
            aria-expanded="false">
            <FontAwesomeIcon icon={icon} />
            <span className="ml-2 d-none d-lg-inline">{title}</span>
          </NavLink> : null  
        }
        {
          items ?
          <>
            <a 
              className="nav-link dropdown-toggle" 
              id="switchDropdown" 
              href="/"
              onClick={toogleList}
              role="button" 
              data-toggle="dropdown" 
              aria-haspopup="true" 
              aria-expanded="false">
              <FontAwesomeIcon icon={icon} />
              <span className="ml-2 d-none d-lg-inline">{title}</span>
            </a>
              <div className={`dropdown-menu dropdown-menu-right ${dropdownClass}`}aria-labelledby="userDropdown">
              {items.map((item: NavItemOptions, index: number)=>(
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a 
                  key={index} 
                  className="dropdown-item" 
                  href=""
                  onClick={
                    (e)=>{
                      e.preventDefault();
                      navFunctions[item.functionName]()
                      toogleList(e);
                    }
                  }>
                  {item.title}
                </a>
              ))} 
              </div>
          </>
          : null
        }
      </li>
  </ul>
  )
};

export default NavItem;