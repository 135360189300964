import GET_FINCAS from 'src/apollo/queries/getFincas';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import XLSX from 'xlsx';
import {Fragment} from 'react';

const  fileSaver = require('file-saver');
function s2ab(s: any){
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

const ExportButton = () => {
    const [buttonPushed, setButtonPushed] = useState(false);
    const [fincas, setFincas] = useState([]);
    const [hasFincas, setHasFincas] = useState(false);
    const {loading: loadingExport, data: dataExport} = useQuery(GET_FINCAS,{
        onCompleted(dataExport){
            if(dataExport.user.fincas.length){
                let tempFincas: any = [];
                const tecnico = dataExport.user.name;
                dataExport.user.fincas.map((finca: any) =>{
                    let aldea = finca.aldea_n!==null? finca.aldea_n.nombre: '';
                    let municipio = finca.aldea_n!==null? finca.aldea_n.municipios.nombre: '';
                    let departamento = finca.aldea_n!==null? finca.aldea_n.municipios.departamento.nombre: '';  
                    tempFincas.push({
                        id: finca.id, name: finca.nombre, lat: finca.latitud, lon: finca.longitud, altitud: finca.altura, sombra: Number(finca.porcentaje_sombra)>0? 'si':'no',
                        densidad: finca.densidad_arboles_ha, plantacion: finca.anio_plantacion,
                        nivel2: departamento, nivel3: municipio, nivel4: aldea, tecnico: tecnico,
                        tipo_cafe: finca.tipo_cafe, tipo_finca: finca.tipo_finca, variedad: finca.variedad
                    });
                });
                setHasFincas(true);
                setFincas(tempFincas);
            }       
            
        }
    });

    useEffect(()=>{
        if(fincas.length && buttonPushed){
            var heading = [[
                "id", "name", "lat", "lon", "altitud", "sombra", "densidad", 
                "plantacion", "nivel2", "nivel3", "nivel4", 
                "tecnico", "tipo_cafe", "tipo_finca", "variedad"
            ]];                  
            var ws = XLSX.utils.aoa_to_sheet(heading);
            XLSX.utils.sheet_add_json(ws, fincas, {
                header: heading[0],
                skipHeader: true,
                origin: -1
            });
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Fincas");
            var wbout = XLSX.write(wb, {bookType:'xlsx', bookSST:true, type: 'binary'});
            fileSaver.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), "fincas.xlsx");
            setButtonPushed(false)
        }
    },[fincas, buttonPushed]);


    return(
        <Fragment>
            {
            hasFincas ?
                <a 
                    className={
                        loadingExport || buttonPushed ? "btn btn-primary btn-sm mb-4 disabled": " btn btn-primary btn-sm mb-4"
                    } 
                    href="#" 
                    onClick={
                        (e)=>{
                            e.preventDefault(); 
                            setButtonPushed(true);               
                        }
                    } 
                    download>
                        {
                        loadingExport || buttonPushed ? 'Exportando Institución': 'Exportar Institución'
                        }
                </a>
            :
                null
            }
        </Fragment>
    );
}

export default ExportButton;