import { IconDefinition, faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClassNames from 'classnames';
import { ListSubItems } from 'src/models/ListItem';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router';
interface SideBarItemProps {
  id: number
  title: string
  classValue?: string
  icon: IconDefinition
  // TODO investigate how to declare functions
  navFunctions: {
    [key: string]: any
  }
  link?: string
  items?: ListSubItems[],
  open: boolean,
  onOpenList(index: number): void
}
const SideBarItem = (props: SideBarItemProps) => {
  const history = useHistory();
  const { title, icon, items, navFunctions, open, id, link } = props;
  const accordionClass = ClassNames({
    'show': open,
  });
  const openList = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    props.onOpenList(id);
  };
  return (
    <>
      {link ?
        <a
          className="nav-link"
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.preventDefault();
            props.onOpenList(id);
            history.push("/");
          }}>
          <div className="sb-nav-link-icon">
            <FontAwesomeIcon icon={icon} />
          </div>{title}
        </a>
        :
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a className="nav-link" href="" onClick={openList} >
          <div className="sb-nav-link-icon">
            <FontAwesomeIcon icon={icon} />
          </div>{title}
          {items ?
            <div className="sb-sidenav-collapse-arrow">
              <FontAwesomeIcon icon={open ? faAngleDown : faAngleRight} />
            </div>
            : null}
        </a>}
      {items ?
        <div
          className={`collapse ${accordionClass}`}
          id="collapseItem"
          aria-labelledby="headingOne"
          data-parent="#sidenavAccordion">
          <nav className="sb-sidenav-menu-nested nav">
            {items.map((item: ListSubItems, index: number) => (
              item.link ?
                <NavLink key={index}
                  to={item.link}
                  className="nav-link" >
                  {item.title}
                </NavLink>
                :
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a key={index}
                  className="nav-link"
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    if (item.functionName) {
                      navFunctions[item.functionName]();
                    }

                  }}>
                  {item.title}
                </a>
            ))}
          </nav>
        </div>
        : null}
    </>
  )
}

export default SideBarItem;