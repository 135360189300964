import { Redirect, Route, Switch } from 'react-router-dom';
import Home from './containers/Home';
import NotFound from './containers/NotFound';
import Authenticate from 'src/containers/Login';
import Register from 'src/containers/Register';
import ProtectRoute from './components/ProtectRoute';
import { authCheckState } from './store/actions';
import { useDispatch } from 'react-redux';
import ForgotPassword from './containers/ForgotPassword';
import FincasTecnico from './containers/Reportes/FincasTecnico'
import ReporteGeneral from './containers/Reportes/ReporteGeneral';
import AdminUsuarios from './containers/Administracion/AdminUsuarios';
import ReporteFinca from './containers/Reportes/ReporteFinca';
import ReporteAltitudes from './containers/Reportes/ReporteAltitudes';
import ReporteIAA from './containers/Reportes/ReporteIAA';
import MapaTemperatura from './containers/Mapas/MapaTemperatura';
import MapaPrecipitacion from './containers/Mapas/MapaPrecipitacion';
import MapaViento from './containers/Mapas/MapaViento';
import MapaCondicionesFavorables from './containers/Mapas/MapaCondicionesFavorables';
import MapaPuntos from './containers/Mapas/MapaPuntos';
import MapaPerspectivas from './containers/Mapas/MapaPerspectivas';
import MapaEstacionesMeteorologicas from './containers/Mapas/MapaEstacionesMeteorologicas';
import ApplicacionesMoviles from './containers/Herramientas/ApplicacionesMoviles';
import Manuales from './containers/Herramientas/Manuales';
import Registros from './containers/Herramientas/Registros';
import AsociarFincas from './containers/Administracion/AsociarFincas';
import PadronFincas from './containers/Administracion/PadronFincas';
import Division from './containers/Administracion/Division';
import RecuperarRegistros from './containers/Administracion/RecuperarRegistros';
import BitacoraSoporte from './containers/Administracion/BitacoraSoporte';
import MapaFincas from './containers/Mapas/MapaFincas';
import Muestreos from './containers/Herramientas/Muestreos';
import EditMuestreos from './containers/Herramientas/EditMuestreos';

import ChangePassword from './components/Auth/changePassword'
import ReportSSO from './containers/Reportes/ReportSSO';

const App = (props: any) => {
    const Admin = ['Admin'];
    const AdminAndTecnico = ['Admin', 'Tecnico'];
    const dispatch = useDispatch();
    dispatch(authCheckState());
    let routes = (
      <Switch>
          <Route path="/register" component={Register}></Route> 
          <Route path="/forgot" component={ForgotPassword}></Route>
          <Route path="/login" component={Authenticate}></Route>
          <Route path="/404" component={NotFound}></Route>
          <Route path="/report-sso" component={ReportSSO}></Route>
          <ProtectRoute path="/tecnico-padron" view={AdminAndTecnico} component={FincasTecnico}></ProtectRoute>
          <ProtectRoute path="/reporte-general" view={Admin} component={ReporteGeneral}></ProtectRoute>
          <ProtectRoute path="/reporte-finca"  view={AdminAndTecnico} component={ReporteFinca}></ProtectRoute>
          <ProtectRoute path="/reporte-altitudes"  view={Admin} component={ReporteAltitudes}></ProtectRoute>
          <ProtectRoute path="/reporte-iaa" view={Admin}  component={ReporteIAA}></ProtectRoute>
          <ProtectRoute path="/mapa-temperatura"  view={AdminAndTecnico} component={MapaTemperatura}></ProtectRoute>
          <ProtectRoute path="/mapa-precipitacion"  view={AdminAndTecnico} component={MapaPrecipitacion}></ProtectRoute>
          <ProtectRoute path="/mapa-viento" view={Admin} component={MapaViento}></ProtectRoute>
          <ProtectRoute path="/mapa-condiciones"  view={AdminAndTecnico} component={MapaCondicionesFavorables}></ProtectRoute>
          <ProtectRoute path="/mapa-puntos"  view={AdminAndTecnico} component={MapaPuntos }></ProtectRoute>
          <ProtectRoute path="/mapa-estaciones"  view={AdminAndTecnico} component={MapaEstacionesMeteorologicas}></ProtectRoute>
          <ProtectRoute path="/mapa-perspectivas" view={Admin} component={MapaPerspectivas}></ProtectRoute>
          <ProtectRoute path="/mapa-fincas" view={AdminAndTecnico} component={MapaFincas}></ProtectRoute>
          <ProtectRoute path="/herramienta-apps"   view={AdminAndTecnico}component={ApplicacionesMoviles}></ProtectRoute>
          <ProtectRoute path="/herramienta-manuales"  view={AdminAndTecnico} component={Manuales}></ProtectRoute>
          <ProtectRoute path="/herramienta-registros"  view={AdminAndTecnico}component={Registros}></ProtectRoute>
          <ProtectRoute path="/muestreos" view={AdminAndTecnico} component={Muestreos}></ProtectRoute>
          <ProtectRoute path="/editMuestreo" view={AdminAndTecnico} component={EditMuestreos}></ProtectRoute>
          <ProtectRoute path="/admin-padron"   view={Admin} component={PadronFincas}></ProtectRoute>
          <ProtectRoute path="/admin-asociar"  view={Admin} component={AsociarFincas}></ProtectRoute>
          <ProtectRoute path="/admin-usuarios" view={Admin}  component={AdminUsuarios}></ProtectRoute>
          <ProtectRoute path="/admin-division" view={Admin} component={Division}></ProtectRoute>
          <ProtectRoute path="/admin-recuperar" view={Admin} component={RecuperarRegistros}></ProtectRoute>
          <ProtectRoute path="/admin-soporte"  view={Admin} component={BitacoraSoporte}></ProtectRoute>
          <ProtectRoute path="/password-setup" view={AdminAndTecnico} component={ChangePassword}></ProtectRoute>
          <ProtectRoute exact path="/" component={Home}></ProtectRoute>
          <Redirect from="*" to="/404" />
        </Switch>
    );
    return routes;
}

export default App;
