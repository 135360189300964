import { gql } from "@apollo/client";

const RESEND_CODE = gql`
mutation resendCode(
  $username: String!
){
  resendConfirm(AuthUserOperation: {
    name: $username
  })
}
`;

export default RESEND_CODE