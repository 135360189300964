import Logo from 'src/assets/images/logoWithoutBackground.png';
import { NavLink, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { NavList } from 'src/models/ListItem';
import NavItem from 'src/components/NavItem';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSetLevel, userToggleSidebar } from 'src/store/actions/user';
import {useHistory} from 'react-router';

const NavContent = () => {
  const history = useHistory();
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state);
  const [sidebarToggle, setToggle] = useState(false);

  useEffect(()=>{
    setItems(user.navBar);
    setToggle(user.toogleSidebar);
  }, [user]);

  const toggleSidebar = () => {
    dispatch(userToggleSidebar(!sidebarToggle));
  };
  const NavFunctions = {
    handleLogout() {
      localStorage.clear();
      window.location.reload();
    },
    // TODO test function should be removed
    test(){
      console.log('test');
    },
    handleSetup(){
      history.push("/password-setup");
    },
    changeAdmin(){
      dispatch(userSetLevel('Admin'));
    },
    changeTecnico(){
      dispatch(userSetLevel('Tecnico'));
    }
  };

  return (
    <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
      <NavLink to="/" className="navbar-brand">
        <img src={Logo} alt="Logo"/>
        <span className="ml-2 d-none d-lg-inline">SATIHCAFE</span>
      </NavLink>
    <button 
      className="btn btn-link btn-sm order-1 order-lg-0 d-lg-none" 
      id="sidebarToggle"
      onClick={()=>{ toggleSidebar()}}>
        <i className="fas fa-bars"></i>
        <FontAwesomeIcon icon={Icons.faBars} />
    </button>
    {items.map((item: NavList, index: number)=>(
      <NavItem 
        key={index} 
        classValue={item.classValue}
        title={item.title}
        items={item.items}
        link={item.link}
        icon={item.icon}
        navFunctions={NavFunctions}
        />
      )
    )}
  </nav>
  );
};

export default NavContent;