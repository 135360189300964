import { gql } from "@apollo/client";

const POWER_BI_REPORTS = gql`
query PowerBiReports($token: String!) {
  powerBiReports(PowerBiToken: $token){
    id
    name
    embedUrl
  }
}
`;

export default POWER_BI_REPORTS;