import BasicLayout from 'src/layout/Basic';
import PowerBiReport from 'src/components/PowerBiReport';
import { NavLink } from 'react-router-dom';
const ReporteGeneral = () => {
  const reporte = process.env.REACT_APP_REPORT as string;
  return (
    <BasicLayout>
      <main>
      <div className="container-fluid">
        <ol className="breadcrumb mb-4">
          <li className="breadcrumb-item">
            <NavLink to="/">Inicio</NavLink>
          </li>
          <li className="breadcrumb-item active">Reportes</li>
        </ol>
        <h1 className="mt-4">Reporte General</h1>
        <div className="card mb-4">
          <div className="card-body">
          <PowerBiReport 
            className="general-report"
            pageName="Incidencia de Roya"
            reportName={reporte}
          /> 
          </div>
        </div>
      </div>
    </main>
  </BasicLayout>
  )
};

export default ReporteGeneral;