import { getNavBarContent } from 'src/utils/navBarContent';
import { getSideBarContent } from 'src/utils/sideBarContent';
import { UserActionTypes, UserSetLevel, UserStartAction, UserState, 
  UserToggleSidebar, 
  USER_SET_LEVEL, USER_START, USER_TOGGLE_SIDEBAR
} from '../types/user.types';

const initialState : UserState = {
  userId: '',
  name: '',
  roles: [],
  sidebar: [],
  navBar: [],
  currentLevel: '',
  toogleSidebar: false,
}

const userStart = (state: UserState, action: UserStartAction) : UserState => {
  return {
    ...state,
    ...{
      userId: action.userInfo.userId,
      name: action.userInfo.name,
      navBar: [...action.userInfo.navBar],
      sidebar: [...action.userInfo.sidebar],
      roles: [...action.userInfo.roles],
      currentLevel: action.userInfo.currentLevel,
    }
  }
}

const userSetLevel = (state: UserState, action: UserSetLevel) : UserState => {
  const navBar = getNavBarContent(action.level, state.name, state.roles);
  const sidebar = getSideBarContent(action.level);
  return {
    ...state,
    ...{
      navBar: [...navBar],
      sidebar: [...sidebar],
      currentLevel: action.level
    }
  }
}

const userToggleSidebar = (state: UserState, action: UserToggleSidebar) : UserState => {
  return {
    ...state,
    ...{
      toogleSidebar: action.toggle
    }
  }
};

const reducer = (state = initialState, action: UserActionTypes) : UserState => {
  switch(action.type){
    case USER_START: return userStart(state, action);
    case USER_SET_LEVEL: return userSetLevel(state, action);
    case USER_TOGGLE_SIDEBAR: return userToggleSidebar(state, action);
    default:
      return state;
  }
}

export default reducer;