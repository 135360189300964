import BasicLayout from 'src/layout/Basic';
const ReporteIAA = () => {
  return (
    <BasicLayout>
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><a href="homepage.html">Inicio</a></li>
            <li className="breadcrumb-item active">Reportes</li>
          </ol>
          <div className="row align-self-center">
            <h1 className="col-lg-8 mt-4">IAA - Cálculo de área afectada</h1>
            <div className="col-lg-2"><a className="btn btn-primary btn-sm my-3" href="#">Descarga Reporte de Area Afectada</a></div>
            <div className="col-lg-2"><a className="btn btn-primary btn-sm my-3" href="#">Descarga Calculo de Area Afectada</a></div>
          </div>
          <div className="card mb-4">
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="form-group col-lg-3">
                    <label className="small mb-1">Tipo</label>
                    <select className="form-control py-4 custom-select">
                      <option selected>Todas          </option>
                    </select>
                  </div>
                  <div className="form-group col-lg-3">
                    <label className="small mb-1">Fecha Inicial</label>
                    <input className="inputDate form-control py-4" type="input" placeholder="Seleccionar Fecha" />
                    </div>
                    <div className="form-group col-lg-3">
                      <label className="small mb-1">Fecha Final</label>
                      <input className="inputDate form-control py-4" type="input" placeholder="Seleccionar Fecha" />
                    </div>
                      <div className="form-group col-lg-3">
                        <button className="btn btn-lg btn-block btn-primary">Filtrar</button>
                      </div>
                    </div>
                </form>
                </div>
            </div>
              <div className="card table-responsive">
                <div className="card-body">
                  <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                    <thead>
                      <tr>
                        <th>Fecha</th>
                        <th>Semana</th>
                        <th>Huerta</th>
                        <th>Altitud</th>
                        <th>Departamento</th>
                        <th>Municipio</th>
                        <th>Plantas Totales</th>
                        <th>Plantas Enfermas</th>
                        <th>Superficie (W)</th>
                        <th>IAA (X)</th>
                        <th>X*W</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>2021-01-05		</td>
                        <td>1	</td>
                        <td>Joanz Rosa Perez, EL VIVERO	</td>
                        <td>1452	</td>
                        <td>LEMPIRA</td>
                        <td>PIRAERA</td>
                        <td>15</td>
                        <td>0</td>
                        <td>0.08</td>
                        <td>0.00</td>
                        <td>0.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        </main>
  </BasicLayout>
  )
};

export default ReporteIAA;