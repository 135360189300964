import { PowerBiActionTypes, PowerBiReport, PowerBiSetReportsAction, PowerBiSuccess, POWER_BI_FAIL, POWER_BI_SET_REPORTS, POWER_BI_SUCCESS } from "../types/powerbi.types";
import POWER_BI_AUTH_TOKEN from 'src/apollo/queries/powerBi/getAccessToken';
import { PowerBiAuthToken } from 'src/apollo/queries/powerBi/types/PowerBiAuthToken';
import client from 'src/apollo';
import { tokenExpired } from "src/utils/auth";
import { PowerBiReports, PowerBiReportsVariables } from "src/apollo/queries/powerBi/types/PowerBiReports";
import POWER_BI_REPORTS from "src/apollo/queries/powerBi/getReports";

export const powerBiSuccess = (tokenInfo: PowerBiSuccess): PowerBiActionTypes => {
  localStorage.setItem('powerbi.accessToken', tokenInfo.accessToken);
  return {
    type: POWER_BI_SUCCESS,
    accessToken: tokenInfo.accessToken,
  }
}

export const powerBiFail= (error: string) : PowerBiActionTypes => {
  return {
    type: POWER_BI_FAIL,
    error,
  }
}

export const powerBiSetReports = (reports: PowerBiReport[]) : PowerBiSetReportsAction =>{
  return {
    type: POWER_BI_SET_REPORTS,
    reports,
  }
}

export const powerBiGetReports = () => {
  return (dispatch: any, currentSate: any) => {
    const {reports, accessToken} = currentSate().powerbi;

    if(!reports.length) {
      client.query<PowerBiReports, PowerBiReportsVariables>({
        query: POWER_BI_REPORTS,
        variables:{
          token: accessToken
        },
      }).then((response)=>{
        dispatch(powerBiSetReports(response.data.powerBiReports));
      }).catch((e)=>{
        dispatch(powerBiFail('Fail retrieving the Reports'))
      })
    }
  }
}

export const powerBICheckState = () => {
  return (dispatch: any, currentSate: any) => {
    const token = localStorage.getItem('powerbi.accessToken');
    if(currentSate().auth.idToken ) {
      if(!token || (tokenExpired(token))){
        client.mutate<PowerBiAuthToken>({mutation: POWER_BI_AUTH_TOKEN}).then((response)=>{
          if(response.data){
            const { access_token } = response.data.powerBiAuthToken;
            const tokenInfo = {
              accessToken:access_token,
            } as PowerBiSuccess;
            dispatch(powerBiSuccess(tokenInfo));
          } else {
            dispatch(powerBiFail('Fail retrieving the power bi token'))
          }
        })
      } else if(tokenExpired(token)){
          client.mutate<PowerBiAuthToken>(
          {
            mutation: POWER_BI_AUTH_TOKEN
          }).then((response)=>{
            if(response.data){
              const { access_token } = response.data.powerBiAuthToken;
              const tokenInfo = {
                accessToken:access_token,
              } as PowerBiSuccess;
              dispatch(powerBiSuccess(tokenInfo));
            } else {
              dispatch(powerBiFail('Fail retrieving the power bi token'))
            }
          })
      } else {
        dispatch(powerBiSuccess({
          accessToken: token,
        }))
      }
    }
  }
}