import { gql } from "@apollo/client";

const CONFIRM_PASSWORD = gql`
mutation ConfirmPassword(
  $username: String!
  $code: String!
  $newPassword: String!
){
  confirmPassword(AuthConfirmPassword:{
    name: $username
    password: $newPassword
    code:$code
  })
}
`;

export default CONFIRM_PASSWORD