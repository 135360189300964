import BasicLayout from 'src/layout/Basic';
import PowerBiReport from 'src/components/PowerBiReport';

const MapaPerspectivas = () => {
  return (
    <BasicLayout>
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><a href="homepage.html">Inicio</a></li>
            <li className="breadcrumb-item active">Mapas</li>
          </ol>
          <h1 className="mt-4">Perspectivas Climáticas</h1>
          <div className="card mb-4">
            <div className="card-body">
            <PowerBiReport 
                className="general-report"
                pageName="Mapa de Fincas"
                reportName="Satihcafe"
              />
            </div>
          </div>
        </div>
      </main>
    </BasicLayout>
  )
};

export default MapaPerspectivas;