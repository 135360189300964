/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum TipoCafe {
  Arabico = "Arabico",
}

export enum TipoFinca {
  Centinela = "Centinela",
  Demostrativa = "Demostrativa",
  Exploracion = "Exploracion",
}

export enum TipoHuerta {
  BPA = "BPA",
  Convencional = "Convencional",
  Diversificada = "Diversificada",
  Organico = "Organico",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
