import { gql } from "@apollo/client";

const FORGOT_PASSWORD = gql`
mutation ForgotPassword(
  $username: String!
){
  forgotPassword(AuthUser: {
    name: $username
  })
}
`;

export default FORGOT_PASSWORD;