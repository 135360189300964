import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import LOGIN from 'src/apollo/queries/authenticate';
import { login, loginVariables } from 'src/apollo/queries/types/login';
import LoginHeader from 'src/components/Auth/Header';
import PreLoaderImage from 'src/assets/images/Preloader.gif';
import LoginLayout from 'src/layout/Login';
import LoginForm from 'src/components/Auth/LoginForm';
import { NavLink, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authCheckState, authFail, authSuccess } from 'src/store/actions';
import { AuthSuccess } from 'src/store/types/auth.types';
import ConfirmCode from 'src/components/Auth/ConfirmCodeForm';
import CONFIRM from 'src/apollo/queries/confirm';
import { Confirm, ConfirmVariables } from 'src/apollo/queries/types/Confirm';
import { resendCode, resendCodeVariables } from 'src/apollo/queries/types/resendCode';
import RESEND_CODE from 'src/apollo/queries/resendCode';

const initialUserInformation = {
  userName: '',
  password: '',
} as loginVariables;

const Login = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: any) => state);
  const [userInformation, setUserInformation] = useState({ ...initialUserInformation});
  const [errorMessage, setErrorMessage] = useState('');
  const [submited, setSubmited] = useState(false);
  const [mustConfirm, setMustConfirm] = useState(false);

  const [code, setCode] = useState('');
  const [initCode, setInitCode] = useState(false);
  const [step, setStep] = useState('register');
  const [initResend, setInitResend] = useState(false);
  
  const [loginUser, { error , data, loading}] = useMutation<login, loginVariables>(
    LOGIN, 
    {
      variables: {
        userName: userInformation.userName,
        password: userInformation.password
      },
      onError(){},
    },
  );
  const handleSubmit = (userInfo: loginVariables) => {
    setUserInformation({
       ...{ 
         userName: userInfo.userName,
         password: userInfo.password
      }
    });
    setSubmited(true);
  }

  const [confirmUser, {
    error: errorConfirm, 
    data: dataConfirm, 
    loading: loadingConfirm
  }] = useMutation<Confirm, ConfirmVariables>(
  CONFIRM,
  {
    variables: {
      name: userInformation.userName,
      code: code
    },
    onError(){},
  }
)

const [resendCode, {
  error: errorResend,
  data: dataResend,
  loading: loadingResend
}] = useMutation<resendCode, resendCodeVariables>(
  RESEND_CODE,
  {
    variables : {
      username: userInformation.userName,
    },
    onError(){}
  },
)

const onSubmitCode = (code: string) : void => {
  setCode(code);
  setInitCode(true);
}
const onChangeStep = (step: string): void => {
  setStep(step);
}
const onSendAnotherCode = () : void => {
  setInitResend(true);
}

useEffect(()=>{
  if(initCode){
    confirmUser();
  }
}, [confirmUser, initCode]);

useEffect(()=>{
  if(dataConfirm){
    setMustConfirm(false);
    setErrorMessage('');
  }
  if(errorConfirm){
    if(errorConfirm?.message === 'Invalid verification code provided, please try again.'){
      setErrorMessage('Código invalido de verificacion por favor intente de nuevo');
    }else if(errorConfirm){
      setErrorMessage('Opps! Algo ha pasado al verificar el usuario');
    }
  }
  setInitCode(false)
}, [dataConfirm, loginUser, errorConfirm]);

useEffect(()=>{
  if(submited){
    dispatch(authCheckState());
    loginUser();
    setSubmited(false);
  }
}, [dispatch, loginUser, submited])

useEffect(()=>{
  if(initResend){
    resendCode();
  }
}, [resendCode, initResend])

  useEffect(()=>{
    let newMessage;
    if(data){
      const tokenData = {
        accessToken : data.authenticate.accessToken.jwtToken,
        idToken: data.authenticate.idToken.jwtToken,
        refreshToken: data.authenticate.refreshToken.token,
        userId: data.authenticate.accessToken.payload.username,
      } as AuthSuccess;

      if (parseInt(data.authenticate.idToken.payload["custom:approved"]) === 0) {
        newMessage = 'Su usuario no ha sido aprobado, por favor comunicarse con su administrador.';
        setErrorMessage(newMessage);
        dispatch(authFail(newMessage));      
    } else {
      dispatch(authSuccess(tokenData));
      dispatch(authCheckState());
    }

  }

    if(error){
      if(error.message==='User is not confirmed.'){
        setMustConfirm(true);
      }
      else{
        if(error.message === 'Incorrect username or password.') {
          newMessage = 'Nombre de usuario o contraseña incorrecta';

        } else {
          newMessage = 'Opps! Algo ha pasado intente de nuevo';
        }
        setErrorMessage(newMessage);
        dispatch(authFail(newMessage));
    }
    }
  }, [data, dispatch, error]);


  if(auth.accessToken !== ''){
    return <Redirect to={auth.authRedirectPath} />
  }

  if(loading || loadingConfirm || loadingResend){
    return (
      <LoginLayout>
        <div className="card shadow-lg border-0 rounded-lg mt-5">
          <LoginHeader
            title="Bienvenido a SATIHCAFE" 
            description="Ingresa tus credenciales para continuar" />
          <div className="card-body">
            <img src={PreLoaderImage} alt="loading" style={{ width: '100%'}}/>
          </div>
        </div>
      </LoginLayout>
    )
  }

  if(mustConfirm){
    return(
      <LoginLayout>
        <div className="card shadow-lg border-0 rounded-lg mt-5">
        <LoginHeader
            title="Bienvenido a SATIHCAFE" 
            description="Ingresa tus credenciales para continuar" />
        <ConfirmCode 
              onSubmit={onSubmitCode} 
              onChangeStep={onChangeStep} 
              onSendAnotherCode={onSendAnotherCode}
              code={code}
              error={errorConfirm ? errorMessage: ''}/> 
        </div>
      </LoginLayout>
    );
  }

  return (
    <LoginLayout>
      <div className="card shadow-lg border-0 rounded-lg mt-5">
          <LoginHeader
            title="Bienvenido a SATIHCAFE" 
            description="Ingresa tus credenciales para continuar" />
          <LoginForm 
            userInformation={userInformation}
            error={errorMessage ? errorMessage : ''} 
            onSubmit={handleSubmit}></LoginForm>
          <div className="card-footer text-center">
              <NavLink to="/register">
                ¿No tienes cuenta? Regístrate
              </NavLink>
          </div>
      </div>
    </LoginLayout>
  )
}

export default Login;