import { useState } from "react";
import { ForgotInformationType } from 'src/models/Auth';
import { useHistory } from "react-router-dom";


interface ForgotPasswordFormProps {
  error: string;
  step: string;
  onSubmit(user: ForgotInformationType): void;
  onSendAnotherCode(): void
  onSendStep(step: string) : void
  userInformation: ForgotInformationType
}
const ForgotPassowrdForm = (props: ForgotPasswordFormProps) => {
  const history = useHistory();
  const [userName, setUserName] = useState(props.userInformation.username);
  const [code, setCode] = useState(props.userInformation.code);
  const [password, setPassword] = useState(props.userInformation.newPassword);
  const [confirmPassword, setConfirmPassword] = useState(props.userInformation.confirmPassword);
  const [submited, setSubmited] = useState(false);
  let passwordErrorMessage = '';
  let userNameErrorMessage = '';
  let confirmPasswordErrorMessage;
  let codeErrorMessage;
  const errorStyle = {
    borderColor: 'red',
  }
  const validations = {
    isValidUserName: () => {
      if(userName === ''){
        userNameErrorMessage = 'El usuario no puede ir vacío';
        return false;
      }
      return true;
    },
    isValidPassword : () => {
      if(password === ''){
        passwordErrorMessage = 'La contraseña no puede ir vacía';
        return false;
      }
      if (
        password.length < 8 ||
        !/[a-z]/.test(password) ||
        !/[A-Z]/.test(password) ||
        !/[0-9]/.test(password)
      ){
        passwordErrorMessage = `La contraseña debe contener almenos 7 
          caracteres incluyendo mayusculas, minisculas, numeros y 
          simbolos`;
        return false;
      }
      return true;
    },
    isValidConfirmPassword: () => {
      if(confirmPassword === ''){
        confirmPasswordErrorMessage = 'La contraseña no puede ir vacía';
        return false;
      }
      if(password !== confirmPassword){
        confirmPasswordErrorMessage = 'Las contraseñas no coinciden';
        return false
      }
      return true;
    },
    isValidCode: () => {
      if(code === ''){
        codeErrorMessage = 'El Código no puede ir vacío';
        return false;
      }
      if(!(/^\d+$/).test(code)){
        codeErrorMessage = 'El Código solo debe tener numeros';
        return false;
      }
      return true;
    },

  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmited(true);
    const validationForgot = validations.isValidUserName();
    const validationConfirm = validations.isValidCode() &&
      validations.isValidPassword() &&
      validations.isValidConfirmPassword();
    const info = {
      username: userName,
      code,
      newPassword: password,
      confirmPassword,
    } as ForgotInformationType;
    if(props.step === 'forgot' && validationForgot) {
      props.onSubmit(info);
    }

    if(props.step === 'confirm' && validationConfirm) {
      props.onSubmit(info);
    }
  }

  const sendNewCode = (event : React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    props.onSendAnotherCode();
  }

  const back = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if(props.step === 'confirm'){
      props.onSendStep('forgot');
    }else{
      history.push('/login');
    }
  }
  return (
    <>
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          {props.step === 'forgot' ? 
          <>
          <div className="form-group mb-4">
            <label className="small mb-1" htmlFor="inputUsername">Nombre de Usuario</label>
            <input 
              className="form-control py-4" 
              id="inputUsername" 
              type="text" 
              value={userName}
              onChange={(event)=>{setUserName(event.target.value)}}
              placeholder="Ingresar Nombre de Usuario"
              style={submited && !validations.isValidUserName() ? errorStyle : {}} />
            {
              userNameErrorMessage ? 
              <h4 className="text-center red" >
                {userNameErrorMessage}
              </h4>
              : null
            }
          </div>
          </> : null }
          {props.step === 'confirm' ?
          <>
            <div className="form-group">
              <label 
                className="small mb-1" 
                htmlFor="inputCode">Código</label>
              <input 
                className="form-control py-4" 
                id="inputCode" 
                type="text" 
                placeholder="Ingrese su Código aqui"
                value={code}
                maxLength={6}
                onChange={(event)=>{setCode(event.target.value)}}
                style={submited && !validations.isValidCode() ? errorStyle : {}} 
              />
              {
                codeErrorMessage ? 
                <h4 className="text-center red" >
                  {codeErrorMessage}
                </h4>
                : null
              }
            </div>
            <div className="form-group mb-4">
              <label className="small mb-1" htmlFor="inputPassword">Contraseña</label>
              <input 
                className="form-control py-4" 
                id="inputPassword" 
                type="password" 
                value={password}
                onChange={(event)=> setPassword(event.target.value)}
                placeholder="Ingresar Contraseña"
                style={submited && !validations.isValidPassword() ? errorStyle : {}} />
              {
                passwordErrorMessage ? 
                <h4 className="text-center red" >
                  {passwordErrorMessage}
                </h4>
                : null
              }
            </div>
            <div className="form-group">
            <label 
              className="small mb-1" 
              htmlFor="inputConfirmPassword">Cambiar Contraseña</label>
            <input 
              className="form-control py-4" 
              id="inputConfirmPassword" 
              type="password" 
              placeholder="Cambiar Contraseña"
              value={confirmPassword}
              onChange={(event)=>{setConfirmPassword(event.target.value)}}
              style={submited && !validations.isValidConfirmPassword() ? errorStyle : {}} 
            />
            {
              confirmPasswordErrorMessage ? 
              <h4 className="text-center red" >
                {confirmPasswordErrorMessage}
              </h4>
              : null
            }
          </div> 
        </> : null }
          <button className="btn btn-primary btn-lg btn-block mb-4">
            {props.step === 'forgot' ? 'Enviar Código' : 'Cambiar Contraseña'} 
          </button>
          {props.step === 'confirm' ?
            <div className="card-footer text-center">
                <div className="small">
                  <a href="/" onClick={sendNewCode}>
                    Reenviar Código
                  </a>
                </div>
            </div>
          : null }
          {props.error ?
            <div className="card-footer text-center">
              <h4 className="text-center font-weight-extrabold red" >{props.error}</h4>
            </div>
          : null }
          <div className="card-footer text-center">
            <a href="/" onClick={back}>
              <h4 
                className="text-center font-weight-extrabold" 
                style={{color: '#333', textDecoration: 'none', backgroundColor: "transparent"}}>
                  {props.step === 'forgot' ? 'Regresar al Login' : 'Regresar'}
              </h4>
            </a>
          </div>
        </form>
      </div>
    </>
  )
}

export default ForgotPassowrdForm;