import BasicLayout from 'src/layout/Basic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@apollo/client';
import GET_USERS from 'src/apollo/queries/getUsers'
import { useEffect, useState } from 'react';
import { Checkbox, Modal} from '@material-ui/core';
import UPDATE_USER from 'src/apollo/queries/updateUser';
import { useMutation } from '@apollo/client';
import { userUpdate, userUpdateVariables } from 'src/apollo/queries/types/userUpdate';
import client from 'src/apollo';
import { authCheckState } from 'src/store/actions';
import { checkDocument } from '@apollo/client/utilities';
import DELETE_USER from 'src/apollo/queries/deleteUser';
import PageLoader from 'src/components/PageLoader';
import AddUserForm from 'src/components/Auth/AddUserForm';
import CREATE_USER from 'src/apollo/queries/createUser';
import PreLoaderImage from 'src/assets/images/Preloader.gif';
import ReactPaginate from 'react-paginate';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
const newUserInitialInformation = {
  name: '',
  username: '',
  phone_number: '',
  family_name: '',
  password: '',
  confirmPassword: '',
  level: 'Admin',
  gender: 'Femenino'
}
const AdminUsuarios = () => {
  const [datos, setDatos] = useState([]);
  const [elements, setElements] = useState([]);
  const [offset, setOffset]=useState(0);
  const [perPage, setPerPage]=useState(15);
  const [currentPage, setCurrentPage]=useState(0)
  const [pageCount, setPageCount]=useState(0)
  const [disabled, setDisabled] = useState(false);
  const [enable, setEnable] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);  
  const [modalAgregar, setModalAgregar] = useState(false);
  const [userSeleccionado, setUserSeleccionado] = useState({
    username: '',
    name: '',
    family_name: '',
    level: [],
    approved: '0'
  })
  const { loading: loadingUsers, error: errorUsers, data: dataUsers } = useQuery(GET_USERS);
  const [updateUser, { error: errorUpdate, loading: loadingUpdate }] = useMutation<userUpdate, userUpdateVariables>(
    UPDATE_USER, {
      update() {
        client.resetStore();
      },
    }
  )
  
  const [newUserInformation, setNewUserInformation] = useState({ ...newUserInitialInformation});
  const [initNewUser, setInitNewUser] = useState(false);
  const [createUser, {
    error: errorCreate,
    data: dataCreate,
    loading: loadingCreate
  }] = useMutation(CREATE_USER, {
    variables: {
      name: newUserInformation.name,
      family_name: newUserInformation.name,
      username: newUserInformation.username,
      password: newUserInformation.password,
      gender: newUserInformation.gender,
      level: [newUserInformation.level],
      phone_number: `+504${newUserInformation.phone_number}`
    },
    onError(){}
  });
  const [errorCreateMessage, setErrorCreateMessage] = useState('');
  const [deleteUser, {error:errorDelete, loading:loadinDelete}]= useMutation(DELETE_USER,{variables:{username:userSeleccionado.username}})

  useEffect(()=>{
    if(initNewUser){
      createUser();
    }
  },[initNewUser, createUser])

  useEffect(()=>{
    if(dataCreate){      
      abrirCerrarModalAgregar();
      setErrorCreateMessage('');      
      client.resetStore();
    }
    if(errorCreate){
      setErrorCreateMessage("Algo salió mal, verifique que no extista otro usuario registrado con el mismo nombre de usuario o teléfono e inténtelo más tarde.");
    }
    setInitNewUser(false);
  }, [dataCreate, errorCreate, client]);

  useEffect(() => {
    
    if (!loadingUsers && dataUsers && dataUsers.findAllUsers) {
      setDatos(dataUsers.findAllUsers);
    }
  }, [dataUsers, loadingUsers]);

  if (loadingUpdate) {
    return <PageLoader section="Administracion" title="Usuarios"></PageLoader>
  }
  if (errorUpdate) {
    return (
      <div>En este momento estamos presentando problemas, por favor intente mas tarde. {errorUpdate.message}</div>
    )
  }
 

  const peticionPut = async () => {
    try {
      await updateUser({
        variables: {
          username: userSeleccionado.username,
          name: userSeleccionado.name,
          family_name: userSeleccionado.family_name,
          level: userSeleccionado.level,
          approved: userSeleccionado.approved === '1',
        }
      });
      setEnable(false)
      abrirCerrarModalEditar();
    } catch (err) {
      console.log('err', err);
    }
  }

  const onSubmitNewUser = (userInformation: any) : void => {
    const {phone_number, name, family_name, password, username, confirmPassword, gender, level } = userInformation;
    setNewUserInformation({
      phone_number,
      name,
      family_name,
      password,
      username,
      confirmPassword,
      gender,
      level
    });
    setInitNewUser(true);
  };

  const handleChange = (e: any) => {
    let { name, value } = e.target;
    if ( e.target.selectedOptions ) {
      value = Array.from(e.target.selectedOptions, (option: any) => option.value); 
    }
    setUserSeleccionado((prevState:any) => {
      if(prevState[name]!== value){
        setEnable(true);
      }
      return{
        ...prevState,
        [name]: value
      } 
      
    })
  }
  
  const abrirCerrarModalEditar = () => {
    setEnable(false);
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar = () => {    
    setModalEliminar(!modalEliminar);
  }

  const abrirCerrarModalAgregar = () => {
    setModalAgregar(!modalAgregar);    
    setNewUserInformation({ ...newUserInitialInformation});
    setErrorCreateMessage('');
  }

  const editarUsuario = (item: any, caso: any) => {
    
    setUserSeleccionado(item);
    (caso === 'Editar') ? abrirCerrarModalEditar() : abrirCerrarModalEliminar()
  }
  

  const eliminar = async () =>{
    try{
      await deleteUser();
      abrirCerrarModalEliminar();
      client.resetStore()
    }catch(e){
      console.log(e.message);
    }
  }

  const deleteUserSeleccionado = async (item:any) => {
     try{
      setUserSeleccionado(item)
      abrirCerrarModalEliminar()
     }catch(e){
       console.log(e.message);
     }
  }
 

  const handlePageClick=(page:any)=>{
    const selectedPage = page.selected;

     setOffset(selectedPage*perPage);
     setCurrentPage(selectedPage);
  }

  const registerCount=()=>{
    const ultimoRegistro = offset+perPage+1;
    if(ultimoRegistro>datos.length){
      return datos.length
    }else{
      return ultimoRegistro
    }

  }
  
  const bodyEditar = () =>{
    return(
      <div className="col-10 col-lg-6 col-xl-4 p-4 mx-auto my-auto"> 
        <div className="card">
          <div className="card-header pt-0 text-right">
              <a 
                style={{cursor: 'pointer'}} 
                onClick={(e)=>{
                e.preventDefault();
                abrirCerrarModalEditar();
                }}
              >
                <FontAwesomeIcon icon={ faTimes }/>
              </a>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <label >Usuario: <span style={{color: 'rgb(0,176,145)', fontSize:'15'}}>{userSeleccionado.username}</span></label>
            </div>
            <br/>   
            <div className="row">
              <input className="form-control " type="hidden" name="username" id="username" value={userSeleccionado && userSeleccionado.username} disabled />
              <br /> 
              <label className="small mb-1" htmlFor="inputCountry">Seleccione uno o mas roles:</label>
              <select name="level" id="level" onChange={handleChange}  className="form-control custom-select" value={userSeleccionado && userSeleccionado.level} multiple size={3}>
                <option value="Tecnico">Técnico</option>
                <option value="Admin">Admin</option>
              </select>
            </div>
            <br/>
            <div className="row">
            <label className="small mb-1" htmlFor="inputCountry">Seleccione un estado:</label> 
            </div>
            
            
              <div className="row">
                <ul style={{listStyle:'none'}}>
                  <li>
                <input type="radio" className="small mr-2" onChange={handleChange} name="approved" value="1" checked={userSeleccionado.approved === "1"} id="rad1" ></input>
                <label className="small mr-2" htmlFor="rad1" style={{color: 'rgb(0,176,145)', fontSize:'10'}}>Aprobado</label>
                </li> 
                <li>
                <input type="radio" className="small mr-2" onChange={handleChange} name="approved" value="0" checked={userSeleccionado.approved === "0"} id="rad2"></input>
                <label className="label small mr-2" htmlFor="rad2" style={{color: 'rgb(0,176,145)', fontSize:'10'}}>No Aprobado</label>
                </li>
                </ul> 
              </div>
            
            <footer style={{ paddingTop: '21px', marginLeft: 'auto' }}>
              <button id="actualizar" onChange={handleChange}  onClick={peticionPut} disabled={!enable} className="btn btn-aceptar btn-block btn-sm" >
                Actualizar
              </button>
              <button className="btn btn-cancelar btn-block btn-sm"  onClick={() => abrirCerrarModalEditar()}>Cancelar</button>
            </footer>  
          </div>
        </div>
      </div>
    )
  }
  const bodyEliminar=()=>{
    if(!loadinDelete){
      return(
        <div className="col-10 col-lg-6 col-xl-4 p-4 mx-auto my-auto"> 
          <div className="card">
            <div className="card-header pt-0 text-right">
              <a 
                style={{cursor: 'pointer'}} 
                onClick={(e)=>{
                e.preventDefault();
                abrirCerrarModalEliminar();
                }}
              >
                <FontAwesomeIcon icon={ faTimes }/>
              </a>
            </div>
            <div className="card-body">  
              <label htmlFor="nombre" style={{color: 'rgb(0,176,145)', fontSize:'10'}}>Esta seguro que desea eliminar al usuario: {userSeleccionado.username}</label>
              <br />
              <footer style={{ paddingTop: '21px', marginLeft: 'auto' ,marginTop:20}}>
                <button id="aceptar" onClick={()=>eliminar()}    className="btn btn-aceptar btn-block btn-sm">
                  Aceptar
                </button>
                <button className="btn btn-cancelar btn-block btn-sm"  onClick={() => abrirCerrarModalEliminar()}>Cancelar</button>
              </footer>
            </div>
          </div>
        </div>
      )
    }else{
      return(
        <div className="col-10 col-lg-6 col-xl-4 p-4 mx-auto my-auto"> 
          <div className="card">        
            <div className="card-body">
              <img src={PreLoaderImage} alt="loading" style={{ width: '100%'}}/>
            </div>
          </div>
        </div>
      );
    }
  }

  const bodyAgregar = () =>{
    if(!loadingCreate){    
      return(
        <div className="col-10 col-lg-6 col-xl-4 p-4 mx-auto my-auto"> 
          <div className="card">        
            <AddUserForm
              onSubmit={onSubmitNewUser} 
              onClose={abrirCerrarModalAgregar}
              error={errorCreateMessage}
              userInformation = {newUserInformation}
            />
          </div>
        </div>          
      );}
    else{
      return(
        <div className="col-10 col-lg-6 col-xl-4 p-4 mx-auto my-auto"> 
          <div className="card">        
            <div className="card-body">
              <img src={PreLoaderImage} alt="loading" style={{ width: '100%'}}/>
            </div>
          </div>
        </div>
      );
    }
  };
           
    
  if (loadingUsers) {
    return <PageLoader section="Administración" title="Usuarios"/>
  }
  if (errorUsers) {
    return (
      <div>Error: {errorUsers.message}</div>
    )
  }

  return (
    <BasicLayout>
      {errorUsers}
      {loadingUsers}
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><a href="homepage.html">Inicio</a></li>
            <li className="breadcrumb-item active">Administración</li>
          </ol>
          <div className="row">
            <h1 className="col-lg-10">Usuarios</h1>
            <div className="col-lg-2"><a className="btn btn-primary btn-sm mb-4" href="#" onClick={(e)=>{e.preventDefault();setModalAgregar(true)}}>Agregar Usuario</a></div>
          </div>
          <div className="card mb-4 table-responsive">
            <div className="card-body">
              <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                <thead >
                  <tr>
                    <th>USUARIO</th>
                    <th>NOMBRE COMPLETO</th>
                    <th>ROL</th>
                    <th>ESTADO</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {datos.slice(offset,offset+perPage).map((item: any, estadoUser: any, tecnico:any) => {
                    if (item.approved === '1') {
                      estadoUser = 'Aprobado';
                    } if (item.approved === null || item.approved==='0') {
                      estadoUser = 'No Aprobado';}
                   
                    return (
                      <tr key={item.username}>
                        <td>{item.username}</td>
                        <td>{item.name}</td>
                        <td>{item.level==null?item.level:item.level.join(', ').replace('Tecnico', 'Técnico')}</td>
                        <td>{estadoUser}</td>
                        <td>
                          <div className="row">
                            <div className="col-lg-6"><button className="btn table-small-btn" onClick={() => editarUsuario(item, 'Editar')} ><FontAwesomeIcon icon={faPencilAlt} /></button></div>
                            <div className="col-lg-6"><button className="btn table-small-btn" onClick={() => deleteUserSeleccionado(item)} ><FontAwesomeIcon icon={faTrashAlt} /></button></div>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div className="row" >
              <div className="col-lg-6">
                  <p>Mostrando registros del {offset+1} al {registerCount()} de un total de {datos.length} registros</p>
              </div>
              <div className="col-lg-6">
              <ReactPaginate
                previousLabel={"Anterior"}
                nextLabel={"Siguiente"}
                breakLabel={<span className="mx-2">...</span>}
                pageCount={Math.ceil(datos.length/perPage)}
                onPageChange={handlePageClick}
                forcePage={currentPage}
                containerClassName={"pagination justify-content-end mr-5"}
                pageClassName={"paginate_button page-item"}
                previousClassName={"paginate_button page-item previous"}
                previousLinkClassName={"page-link"}
                nextClassName={"paginate_button page-item next"}
                nextLinkClassName={"page-link"}
                disabledClassName={"disabled"}
                activeClassName={"active"}
                activeLinkClassName={"page-link"}
                pageRangeDisplayed={2}
                pageLinkClassName={"page-link"}
                marginPagesDisplayed={2}
              />
                  
              </div>
          </div>
          </div>
          
        </div>
      </main>
      <Modal open={modalEditar} onClose={abrirCerrarModalEditar}>{bodyEditar()}</Modal>
      <Modal open={modalEliminar} onClose={abrirCerrarModalEliminar}>{bodyEliminar()}</Modal>
      <Modal open={modalAgregar} onClose={abrirCerrarModalAgregar} className="row" style={{overflowY: 'scroll'}}>{bodyAgregar()}</Modal>
    </BasicLayout>
  )
};

export default AdminUsuarios;