export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_START_APP = 'AUTH_START_APP';
export const AUTH_STEP = 'AUTH_STEP';

export interface AuthState {
  idToken: string;
  accessToken: string;
  refreshToken: string;
  userId: string;
  error: string;
  loading: boolean;
  startApp: boolean;
  authRedirectPath: string;
  step: string;
}

export interface AuthSuccess {
  idToken: string;
  accessToken: string;
  refreshToken: string;
  userId: string;
}

export interface AuthStartAction {
  type: typeof AUTH_START
}

export interface AuthSuccessAction {
  type: typeof AUTH_SUCCESS
  tokenInfo: AuthSuccess
}

export interface AuthFailAction {
  type: typeof AUTH_FAIL
  error: string
}

export interface AuthLogoutAction {
  type: typeof AUTH_LOGOUT
}


export interface AuthStartApp {
  type: typeof AUTH_START_APP
}

export interface AuthStep {
  type: typeof AUTH_STEP,
  step: string
}

export 
  type AuthActionTypes = 
    AuthStartAction | 
    AuthSuccessAction | 
    AuthLogoutAction | 
    AuthFailAction |
    AuthStartApp |
    AuthStep;
