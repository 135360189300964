import BasicLayout from 'src/layout/Basic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faTrashAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory, useLocation} from 'react-router-dom';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import PreLoaderImage from 'src/assets/images/Preloader.gif';
import TabTitles from 'src/components/Tabs';
import { getMuestreoInfo_getMuestreo_muestreo_detail } from 'src/apollo/queries/types/getMuestreoInfo';
import useGetMuestreo from 'src/hooks/getMuestreo';
import DELETE_MUESTREO from 'src/apollo/queries/deleteMuestreo';
import GrilloView from 'src/components/Muestreos/MuestreoGrilloView';
import numeral from 'numeral';
import PowerBiReport from 'src/components/PowerBiReport';
import { useMutation } from '@apollo/client';
import { Modal} from '@material-ui/core';

const Muestreos = () => {
  const tabs = [
    "Información General",
    "Muestreo"
  ]
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [selectedMuestreo, setSelectedMuestreo] = useState(0);
  const search = useLocation().search;
  const idMuestreo = new URLSearchParams(search).get('id');
  const { data, loading, error } = useGetMuestreo(Number(idMuestreo));
  const [filters, setFilters] = useState<any>([]);
  const reporte = process.env.REACT_APP_REPORT as string;
  const history = useHistory();
  const [modalEliminar, setModalEliminar] = useState(false);
  const [deleteMuestreo, {error:errorDelete, loading:loadinDelete}]= useMutation(DELETE_MUESTREO,{variables:{MuestreoId:Number(idMuestreo)}})
  useEffect(() => {
    setFilters([{
      table: 'satihcafe muestreo',
      column: 'id',
      value: Number(idMuestreo)
    }]);
  }, [idMuestreo])

  if (loading) {
    return (
      <BasicLayout>
        <main>
          <div className="col-lg-12">
            <div className="card-body" style={{ textAlign: 'center' }}>
              <img src={PreLoaderImage} alt="loading" style={{ height: '200px' }} />
            </div>
          </div>
        </main>
      </BasicLayout>
    );
  }

  if (error) {
    return (
      <BasicLayout>
        <main>
          <div className="col-lg-12">
            <div className="card-body" style={{ textAlign: 'center' }}>
              <div>Algo ha ocurrido por favor intente denuevo!</div>
            </div>
          </div>
        </main>
      </BasicLayout>
    );
  }

  if (data) {
    const { fecha, bandolas, fenologia, metadata } = data.getMuestreo;
    const { nombre } = data.getMuestreo.finca;
    const renderInformacion = (
      muestreo: getMuestreoInfo_getMuestreo_muestreo_detail,
      index: number) => {
      return (
        <div
          key={`${index}-information`}
          className={`tab-pane table-responsive fade ${currentTab === tabs[0] ? 'show active' : ''}`}
          id="tab1" role="tabpanel" aria-labelledby="tab1">
          <table className="table table-bordered mt-4" width="100%" cellSpacing="0">
            <tbody>
              <tr>
                <td>Fecha</td>
                <td>
                  <Moment format="YYYY-MM-DD">{fecha}</Moment>
                </td>
              </tr>
              <tr>
                <td>Plantas</td>
                <td>{muestreo.puntos}</td>
              </tr>
              <tr>
                <td>Bandolas</td>
                <td>{bandolas}</td>
              </tr>
              <tr>
                <td>Huerta</td>
                <td>{nombre}</td>
              </tr>
              <tr>
                <td>Fenologia</td>
                <td>{fenologia}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>{muestreo.total}</td>
              </tr>
              <tr>
                <td>Positivos</td>
                <td>{muestreo.positivos}</td>
              </tr>
              <tr>
                <td>Incidencia</td>
                <td>{numeral(muestreo.incidencia).format('0.00')}</td>
              </tr>
              <tr>
                <td>Metadata</td>
                <>
                  {metadata ?
                    <td>{Object.keys(metadata).map((element: string) => (
                      <div key={element}>{`${element.toUpperCase()}: ${metadata[element]}`}</div>
                    ))}</td>
                    : null}
                </>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }

    const renderMuestreos = (
      muestreo: getMuestreoInfo_getMuestreo_muestreo_detail,
      index: number) => {
      return (
        <div
          key={`${index}-muestreos`}
          className={`tab-pane fade ${currentTab === tabs[1] ? 'show active' : ''}`}
          id="tab2"
          role="tabpanel"
          aria-labelledby="tab2">
          <h4>{`Muestreo de ${muestreo.tipo_plaga_enfermedad}`}</h4>
          { muestreo.registros ?
            <table className="table-striped table table-bordered mb-3" width="100%" cellSpacing="0">
              <thead>
                <tr>
                  <td></td>
                  <td colSpan={2}>
                    Bandola 1
                </td>
                  {bandolas > 1 ?
                    <td colSpan={2}>
                      Bandola 2
                    </td>
                    : null}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Planta</td>
                  <td>Totales</td>
                  <td>Positivos</td>
                  {bandolas > 1 ?
                    <>
                      <td>Totales</td>
                      <td>Positivos</td>
                    </>
                    : null}
                </tr>
                {muestreo.registros.map((registro: any, index: number) => {
                  if (bandolas > 1) {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{registro.bandola_1.total}</td>
                        <td>{registro.bandola_1.positivo}</td>
                        <td>{registro.bandola_2.total}</td>
                        <td>{registro.bandola_2.positivo}</td>
                      </tr>
                    )
                  }
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{registro.bandola_1.total}</td>
                      <td>{registro.bandola_1.positivo}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            :
            <h5>La Información del registro no se encuentra disponible</h5>
          }
          <div className="text-center mt-5 mb-0 pb-0" style={{width: "100%"}}>
            <PowerBiReport
              className="general-report"
              pageName="Registro Muestreos"
              filters={filters}
              reportName={reporte}
            />
          </div>
        </div>
      )
    };


    const abrirCerrarModalEliminar = () => {    
      setModalEliminar(!modalEliminar);
    }
    const eliminar = async () =>{
      try{
        await deleteMuestreo();
        abrirCerrarModalEliminar();
        history.push('/herramienta-registros')
      }catch(e){
        console.log(e.message);
      }
    }
    const bodyEliminar=()=>{
      if(!loadinDelete){
        return(
          <div className="col-10 col-lg-6 col-xl-4 p-4 mx-auto my-auto"> 
            <div className="card">
              <div className="card-header pt-0 text-right">
                <a 
                  style={{cursor: 'pointer'}} 
                  onClick={(e)=>{
                  e.preventDefault();
                  abrirCerrarModalEliminar();
                  }}
                >
                  <FontAwesomeIcon icon={ faTimes }/>
                </a>
              </div>
              <div className="card-body text-center">  
                <label htmlFor="nombre" className="" style={{color: 'rgb(0,176,145)', fontSize:'10'}}>¿Esta seguro que desea eliminar este muestreo?</label>
                <br />
                <footer style={{ paddingTop: '21px', marginLeft: 'auto' ,marginTop:20}}>
                  <button id="aceptar" onClick={()=>eliminar()}    className="btn btn-aceptar btn-block btn-sm">
                    Aceptar
                  </button>
                  <button className="btn btn-cancelar btn-block btn-sm"  onClick={() => abrirCerrarModalEliminar()}>Cancelar</button>
                </footer>
              </div>
            </div>
          </div>
        )
      }else{
        return(
          <div className="col-10 col-lg-6 col-xl-4 p-4 mx-auto my-auto"> 
            <div className="card">        
              <div className="card-body">
                <img src={PreLoaderImage} alt="loading" style={{ width: '100%'}}/>
              </div>
            </div>
          </div>
        );
      }
    }
    return (
      <BasicLayout>
        <main>
          <div className="container-fluid">
            <ol className="breadcrumb mb-4">
              <li className="breadcrumb-item">
                <Link to="/">
                  Inicio
              </Link>
              </li>
              <li className="breadcrumb-item active">Herramientas</li>
              <li className="breadcrumb-item">
                <Link to="/herramienta-registros">
                  Registro Historial
              </Link>
              </li>
            </ol>
            <div className="row">
              <h1 className="col-lg-8 mt-4">Muestreos</h1>
              <div className="col-lg-2">
                <Link
                  className="nav-link btn btn-aceptar btn-sm mb-4"
                  to="/herramienta-registros">
                  <FontAwesomeIcon icon={faArrowAltCircleLeft}></FontAwesomeIcon>
                  <span className="ml-2 d-none d-lg-inline">Regresar</span>
                </Link>
              </div>
              <div className="col-lg-2">
                <button className="nav-link btn btn-cancelar btn-sm mb-4 w-100"  onClick={() => abrirCerrarModalEliminar()}>
                  <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                  <span className="ml-2 d-none d-lg-inline">Eliminar</span>
                </button>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card col-md-12 card-muestreos">
                <div className="card-body">
                  <div className="row">
                    {data.getMuestreo.muestreo_detail.map((muestreo: getMuestreoInfo_getMuestreo_muestreo_detail, index: number) => {
                      return (
                        <>
                          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a
                            key={`${index}-list-enfermedad`}
                            className={`col-2 nav-link btn ${index === selectedMuestreo ? 'btn-primary': 'btn-aceptar'} btn-sm ml-4 mt-4 mb-4`}
                            onClick={() => setSelectedMuestreo(index)}>
                            <span className="ml-2 d-lg-inline">{`${muestreo.tipo_plaga_enfermedad}/${muestreo.tipo}`}</span>
                          </a>
                        </>
                      )
                    })}
                  </div>
                  <TabTitles
                    titles={tabs}
                    onClickTab={setCurrentTab}
                    current={currentTab}
                  />
                  <div className="tab-content" id="myTabContent">
                    {
                    data.getMuestreo.muestreo_detail[0].tipo_plaga_enfermedad === 'Grillo Indiano' ? 
                    <GrilloView 
                      muestreo={data.getMuestreo} 
                      muestreoDetail={data.getMuestreo.muestreo_detail[0]}
                      tabs={tabs}
                      currentTab={currentTab} /> : 
                    data.getMuestreo.muestreo_detail.map(
                      (muestreo: getMuestreoInfo_getMuestreo_muestreo_detail,
                        index: number) => {
                        return (
                          <>
                            {
                              index === selectedMuestreo ? (
                                <>
                                  {renderInformacion(muestreo, index)}
                                  {renderMuestreos(muestreo, index)}
                                </>
                              )
                                : null
                            }
                          </>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Modal open={modalEliminar} onClose={abrirCerrarModalEliminar}>{bodyEliminar()}</Modal>
      </BasicLayout>
    );
  }
};

export default Muestreos;