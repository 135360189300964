
import * as jwt from 'jsonwebtoken'
export const tokenExpired = (token: string | null) => {
  if (token === null) {
    return true
  }
  const tokenInfo = jwt.decode(token) as any
  if (!tokenInfo) {
    return true
  }

  const expirationDate = new Date(tokenInfo.exp * 1000)
  return new Date() > expirationDate
}

export const decodeToken = (token: string) : any => {
  return jwt.decode(token)
}