import { useRef } from 'react';
import numeral from 'numeral';
import PreLoaderImage from 'src/assets/images/Preloader.gif';

import {
  getMuestreoInfo_getMuestreo_muestreo_detail as MuestreoDetailType,
} from 'src/apollo/queries/types/getMuestreoInfo';
import MuestreoRegistro from './MuestreoRegistro';

interface FormData {
  id: number
  bandola_1: {
    total: number
    positivo: number
  }
  bandola_2?: {
    total: number
    positivo: number
  }
}

interface MuestreoFormProps {
  muestreoDetail: MuestreoDetailType[]
  bandolas: number
  plantaIndex: number
  savingStatus: boolean
  onSaveForm(data: FormData[]): void
}

const MuestreoForm = (props: MuestreoFormProps) => {
  const hojasRef = useRef<any>(null);
  const frutosRef = useRef<any>(null);
  const { muestreoDetail, bandolas, plantaIndex, savingStatus } = props;
  const onSaveForm = () => {
    const hojasData = hojasRef.current?.getRegisterData();
    const frutosData = frutosRef.current?.getRegisterData();
    const object: any = {};
    hojasData.bandolaInfo.forEach((info: any) => {
      object[info.id] = {
        bandola_1: {
          total: hojasData.total1,
          positivo: Number(info.bandola1),
        },
        ...(bandolas > 1 && {
          bandola_2: {
            total: hojasData.total2,
            positivo: Number(info.bandola2),
          }
        })
      }
    });

    frutosData.bandolaInfo.forEach((info: any) => {
      object[info.id] = {
        bandola_1: {
          total: frutosData.total1,
          positivo: Number(info.bandola1),
        },
        ...(bandolas > 1 && {
          bandola_2: {
            total: frutosData.total2,
            positivo: Number(info.bandola2),
          }
        })
      }
    });
    props.onSaveForm(object);
  };
  const renderTable = () => {
    return (
      <div
        className="tab-pane fade show active"
        id="tab2"
        role="tabpanel"
        aria-labelledby="tab2">
        <table className="table-striped table table-bordered" width="100%" cellSpacing="0">
          <tbody>
            <tr>
              <td></td>
              <td colSpan={1}>
                Totales
            </td>
              <td colSpan={1}>
                Positivos
            </td>
              <td colSpan={1}>
                %
            </td>
            </tr>
            {muestreoDetail.map((detail: MuestreoDetailType, index: number) => (
              <tr key={index}>
                <td>{detail.tipo_plaga_enfermedad}</td>
                <td>{numeral(detail.total).format()}</td>
                <td>{numeral(detail.positivos).format()}</td>
                <td>{numeral(detail.incidencia).format('0.00')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  };
  return (
    <div className={
      `${muestreoDetail[0].registros ? 'col-lg-9' : 'col-lg-12'} card-finca-table mb-4 table-responsive`
    }>
      {renderTable()}
      {
        !muestreoDetail[0].registros ?
          <div className="card mb-4 mt-4">
            La Información del registro no se encuentra disponible
        </div> :
          <div className="card mb-4 mt-4">
            <div className="card-header">
              <h5 className="font-weight-extrabold">{`Planta ${plantaIndex + 1}`}</h5>
            </div>
            <div className="card-body">
              <MuestreoRegistro
                tipo="hoja"
                title="Hojas"
                bandolas={bandolas}
                muestreoDetail={muestreoDetail}
                plantaIndex={plantaIndex}
                ref={hojasRef}
              />
              <MuestreoRegistro
                tipo="fruto"
                title="Frutos"
                bandolas={bandolas}
                muestreoDetail={muestreoDetail}
                plantaIndex={plantaIndex}
                ref={frutosRef}
              />
              <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                {
                  savingStatus ? (
                      <img src={PreLoaderImage} alt="loading" style={{
                        height: '150px',
                      }}/>
                  ) : (
                    <button
                      className="btn btn-primary btn-lg"
                      onClick={() => { onSaveForm() }}>
                      Guardar
                    </button>
                  )
                }
              </div>
            </div>
          </div>
      }
    </div>
  )
};

export default MuestreoForm;