import XLSX from 'xlsx';
import fileSaver from 'file-saver';
export function s2ab(s: any) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}

export function convertCSVDataToXlsFormat(data: string) {
  const convertedArray = data.split('\n').map((row) => {
    return row.replace(/(".+")/g, function (value) {
      return value.replaceAll(",", " ").replaceAll("\"","");
    }).split(',');
  });
  const heading = [[...convertedArray[0]]];
  const bodyData = convertedArray.slice(1).map((row) => {
    const elements: any = {};
    heading[0].forEach((key: string, index: number) => {
      elements[key] = row[index] || "";
    });
    return elements;
  });

  return {
    heading,
    bodyData,
  }
}

export function exportXls(heading: any[][], bodyData: any[], title: string, fileName: string) {
  const ws = XLSX.utils.aoa_to_sheet(heading);
  XLSX.utils.sheet_add_json(ws, bodyData, {
    header: heading[0],
    skipHeader: true,
    origin: -1
  });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, title);
  const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });
  fileSaver.saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), fileName);
}