import { useState } from 'react';
import {
  getMuestreoInfo_getMuestreo_muestreo_detail as MuestreoDetailType,
} from 'src/apollo/queries/types/getMuestreoInfo';

interface MuestreoListProps {
  muestreoDetail: MuestreoDetailType[]
  onChangePlanta(planta: number): void
}

const getPlantasList = (muestreoDetail : MuestreoDetailType[]) => {
  if(muestreoDetail.length){
    const maxPuntos = muestreoDetail.reduce((ac: number, item: MuestreoDetailType) => {
      if(item.puntos > ac){
        return item.puntos;
      }
      return ac;
    }, 0 );
    return Array.from({ length: maxPuntos}, (_item, index)=> index);
  }
  return [];
}
const MuestreoList = (props: MuestreoListProps) => {
  const { muestreoDetail, onChangePlanta } = props;
  const [planta , setPlanta] = useState(0);
  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
    event.preventDefault();
    setPlanta(index);
    onChangePlanta(index);
  };
  const puntos = getPlantasList(muestreoDetail);
  if (puntos) {
    return (
      <div className="col-lg-3">
        <div className="card card-fincas mb-4">
          <div className="card-body">
            <ul className="mt-4">
              {puntos.map((registro: any, index: number) => (
                <li key={index} className={`mb-3 ${index === planta ? 'selected': ''}`}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="#" onClick={(event) => onClick(event, index)}>
                    <i className="fas fa-home"></i>
                    <span>{`Planta ${index + 1}`}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  } else {
    return null;
  }
};

export default MuestreoList;