import { 
  PowerBiActionTypes, PowerBiFailAction, PowerBiSetReportsAction, PowerBiState, 
  PowerBiSuccessAction, POWER_BI_FAIL, POWER_BI_SET_REPORTS, POWER_BI_SUCCESS 
} from "../types/powerbi.types";

const initialState : PowerBiState = {
  accessToken: '',
  refreshToken: '',
  error: '',
  reports: [],
}

const powerBiSuccess = (state: PowerBiState, action: PowerBiSuccessAction) : PowerBiState => {
  return {
    ...state,
    ...{
      accessToken: action.accessToken,
      error: '',
    }
  }
}

const powerBiFail = (state:PowerBiState, action: PowerBiFailAction): PowerBiState => {
  return {
    ...state,
    ...{
      error: action.error
    }
  }
}

const powerBiSetReports = (state: PowerBiState, action: PowerBiSetReportsAction) : PowerBiState => {
  return {
    ...state,
    ...{
      reports: [...action.reports]
    }
  }
}

const reducer = (state = initialState, action: PowerBiActionTypes) => {
  switch(action.type){
    case POWER_BI_SUCCESS: return powerBiSuccess(state, action);
    case POWER_BI_FAIL: return powerBiFail(state, action);
    case POWER_BI_SET_REPORTS: return powerBiSetReports(state,action);
    default:
      return state;
  }
}

export default reducer;