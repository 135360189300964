import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
const ProtectedRoute = ({view, component: Component,  onTryAutoSignup, ...rest}: any) => {
  const { auth, user, refresh } = useSelector((state : any) => state);
  const renderComponent =(<Route {...rest} render={(props : any) => (
    <Component {...props} />
    )} />);
  const validRoute: Function = (): JSX.Element => {
    if (auth.accessToken !== '' || refresh.isRefreshing) {
      if (view?.length) {
        let checkRolesView = view.includes(user.currentLevel);
        if (!checkRolesView && !refresh.isRefreshing) {
            return (<Redirect to={{ pathname: '/'}} /> );
          }
          return renderComponent;
        }
        else {
          return renderComponent;
      }

    }
    return (<Route {...rest} render={(props : any) => (
      <Redirect to={{ pathname: '/login', state: { from: props.location }}} />   
      )} />);
  };

  return (
    <React.Fragment>
      {validRoute()}
    </React.Fragment>
  )
};

export default ProtectedRoute;