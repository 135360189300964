import * as Icons from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SideBarItem from 'src/components/SidebarItem';
import { ListItem } from 'src/models/ListItem';

const SideBarContent = () => {
  const [items, setItems] = useState<ListItem[]>([]);
  const { user } = useSelector((state: any) => state);

  useEffect(()=>{
    setItems(user.sidebar);
  }, [user]);
  
  const sidebarFunctions = {
    // TODO test function should be removed
    test() {
      console.log('test');
    },
  }

  const showList = (itemIndex: number) => {
    const newItems = items.map(
      (item: ListItem, 
      index: number
    ) => {
      if(itemIndex !== index){
        item.open = false;
      }else{
        item.open = !item.open;
      }
      return item;
    });
    setItems(newItems);
  };
  return (
    <div id="layoutSidenav_nav">
      <nav
        className="sb-sidenav accordion sb-sidenav-dark"
        id="sidenavAccordion"
      >
        <div className="sb-sidenav-menu">
          <div className="nav">
            {items.map((item: ListItem, index: number) => (
              <SideBarItem
                key={index}
                id={index}
                classValue={item.classValue}
                title={item.title}
                items={item.items}
                link={item.link}
                icon={item.icon}
                navFunctions={sidebarFunctions}
                open={item.open}
                onOpenList={showList}
              />
            ))}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default SideBarContent;