import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import LoginHeader from 'src/components/Auth/Header';
import { Register as RegisterQuery, RegisterVariables } from 'src/apollo/queries/types/Register';
import REGISTER from 'src/apollo/queries/register';
import ConfirmCode from 'src/components/Auth/ConfirmCodeForm';
import RegisterForm from 'src/components/Auth/RegisterForm';
import LoginLayout from 'src/layout/Login';
import CONFIRM from 'src/apollo/queries/confirm';
import { Confirm, ConfirmVariables } from 'src/apollo/queries/types/Confirm';
import PreLoaderImage from 'src/assets/images/Preloader.gif';
import SuccessForm from 'src/components/Auth/Success';
import { resendCode, resendCodeVariables } from 'src/apollo/queries/types/resendCode';
import RESEND_CODE from 'src/apollo/queries/resendCode';

const initialUserInformation = {
  name: '',
  username: '',
  phone: '',
  familyName: '',
  password: '',
  confirmPassword: ''
};

const Register = () => {
  const [step, setStep] = useState('register');
  const [userInformation, setUserInformation] = useState({ ...initialUserInformation});
  const [initUser, setInitUser] = useState(false);
  const [initCode, setInitCode] = useState(false);
  const [initResend, setInitResend] = useState(false);
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [registerUser, {
    error: errorRegister, 
    data: dataRegister, 
    loading: loadingRegister
  }] = useMutation<RegisterQuery, RegisterVariables>(
    REGISTER,
    {
      variables: {
        name: userInformation.name,
        familyName: userInformation.name,
        username: userInformation.username,
        password: userInformation.password,
        phone: `+504${userInformation.phone}`,
      },
      onError(){},
    }
  )

  const [confirmUser, {
      error: errorConfirm, 
      data: dataConfirm, 
      loading: loadingConfirm
    }] = useMutation<Confirm, ConfirmVariables>(
    CONFIRM,
    {
      variables: {
        name: userInformation.username,
        code: code
      },
      onError(){},
    }
  )
  
  const [resendCode, {
    error: errorResend,
    data: dataResend,
    loading: loadingResend
  }] = useMutation<resendCode, resendCodeVariables>(
    RESEND_CODE,
    {
      variables : {
        username: userInformation.username,
      },
      onError(){}
    },
  )

  useEffect(()=>{
      if(dataRegister){
        setStep('confirmCode');
      }
      if(errorRegister){
        if(errorRegister.message === "Duplicate phone number in signup."){
          setErrorMessage('Numero de teléfono duplicado');
        }else if(errorRegister.message === 'Error: User already exists'){
          setErrorMessage('El usuario ya existe');
        }else if(errorRegister.message === "Duplicate email address in signup."){
          setErrorMessage('Email Duplicado en el registro');
        }else{
          setErrorMessage('Opps! Algo ha pasado al registrar el usuario');
        }
      }
      setInitUser(false); 
  }, [dataRegister, errorRegister]);

  useEffect(()=>{
    if(dataConfirm){
      setStep('finish');
    }
    if(errorConfirm){
      if(errorConfirm?.message === 'Invalid verification code provided, please try again.'){
        setErrorMessage('Código invalido de verificacion por favor intente de nuevo');
      }else if(errorConfirm){
        setErrorMessage('Opps! Algo ha pasado al verificar el usuario');
      }
    }
    setInitCode(false)
  }, [dataConfirm, errorConfirm]);

  useEffect(()=>{
    if(errorResend){
      setErrorMessage('Error retrieving code')
    }
    setInitResend(false)
  }, [dataResend, errorResend]);

  const onSubmitUser = (userInformation: any) : void => {
    const {phone, name, familyName, password, username, confirmPassword } = userInformation;
    setUserInformation({
      phone,
      name,
      familyName,
      password,
      username,
      confirmPassword,
    });
    setInitUser(true);
  };

  const onSubmitCode = (code: string) : void => {
    setCode(code);
    setInitCode(true);
  }

  const onChangeStep = (step: string): void => {
    setStep(step);
  }

  const onSendAnotherCode = () : void => {
    setInitResend(true);
  }

  useEffect(()=>{
    if(initUser){
      registerUser();
    }
  }, [initUser, registerUser])

  useEffect(()=>{
    if(initCode){
      confirmUser();
    }
  }, [confirmUser, initCode])

  useEffect(()=>{
    if(initResend){
      resendCode();
    }
  }, [resendCode, initResend])

  if(loadingRegister || loadingConfirm || loadingResend){
    return (
      <LoginLayout>
      <div className="card shadow-lg border-0 rounded-lg mt-5">
          <LoginHeader
            title="Registro de Cuenta" />
        <div className="card-body">
          <img src={PreLoaderImage} alt="loading" style={{ width: '100%'}}/>
        </div>
      </div>
    </LoginLayout>
    )
  }
  return (
    <LoginLayout>
      <div className="card shadow-lg border-0 rounded-lg mt-5">
          <LoginHeader
            title="Registro de Cuenta" />
          {step === 'register' ? 
            <RegisterForm 
              userInformation={userInformation} 
              onSubmit={onSubmitUser} 
              error={errorRegister ? errorMessage: ''}/> 
          : null}
          {step === 'confirmCode' ? 
            <ConfirmCode 
              onSubmit={onSubmitCode} 
              onChangeStep={onChangeStep} 
              onSendAnotherCode={onSendAnotherCode}
              code={code}
              error={errorConfirm ? errorMessage: ''}/> 
          : null}
          {step === 'finish' ? 
            <SuccessForm title="El usuario ha sido Registrado exitosamente!"></SuccessForm>
          : null}
      </div>
    </LoginLayout>
  )
}

export default Register;