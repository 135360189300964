import { gql } from '@apollo/client';
const REGISTER = gql`
mutation Register(
  $name: String!
  $familyName: String!
  $username: String!
  $password: String!
  $phone: String!
){
  register(AuthRegister: {
    name: $name
    family_name: $familyName
    username:$username
    password:$password
    phone_number: $phone
  }){
    id
    username
    email
    phone_number
    name
    family_name
  }
}
`;

export default REGISTER;