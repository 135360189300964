import BasicLayout from '../layout/Basic';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
const NotFound = (props: any) => {
  return (
    <div id="layoutError">
    <div id="layoutError_content">
      <main>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="text-center mt-4">
                <h1 className="display-1">404</h1>
                <p className="lead">La pagina no ha sido encontrada</p>
                <Link to="/">
                  <i className="fas fa-arrow-left mr-1"></i>Ir a la Pagina Principal
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
  )
};

export default NotFound;