import {gql} from '@apollo/client';

const FIND_ALL_FINCAS = gql`
query findAllFincas{
    absoluteFincas{
      id
      nombre
      latitud
      longitud
      altura
      porcentaje_sombra
      densidad_arboles_ha
      superficie
      anio_plantacion
      area
      lote
      aldea_n{
        nombre
        municipios{
          nombre
          departamento{
            nombre
          }
        }
      }
      tecnico{
        name
      }
      tipo_cafe
      tipo_finca
      variedad      
    }
  }
`;

export default FIND_ALL_FINCAS;