import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import {decodeToken} from 'src/utils/auth';

const errorMiddleWare = onError(({ graphQLErrors, networkError, forward, operation }) => {
  let forbiddenResource=false;
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  };
  if (graphQLErrors) {    
    graphQLErrors.map(({ message, locations, path }) =>{
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
      if(message==='Forbidden resource'){
        forbiddenResource = true;
      }
    });    
  }
  if(forbiddenResource){
    const idToken = localStorage.getItem('idToken') || '';
    const refreshToken = localStorage.getItem('refreshToken') || '';
    const info = decodeToken(idToken);
    return new Observable ( observer => {
      fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: `
          mutation{
            refreshToken(AuthRefreshTokenDto: {
              username: "${info['cognito:username'] || ''}"
              refreshToken: "${refreshToken}"
            }){
              idToken
              accessToken
              refreshToken
            }
          }` 
        }),
      })
      .then(res => res.json())
      .then(res => {
        try{
          const oldHeaders = operation.getContext().headers;
          localStorage.setItem('idToken', res.data.refreshToken.idToken);
          localStorage.setItem('accessToken', res.data.refreshToken.accessToken);
          localStorage.setItem('refreshToken', res.data.refreshToken.refreshToken);
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${localStorage.getItem('idToken')}`,
            },
          });
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          };
          forward(operation).subscribe(subscriber);
        }
        catch{}
      })})
    }
  }
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('idToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    authLink,
    errorMiddleWare,
    new HttpLink({
      uri: `${process.env.REACT_APP_API_URL}/graphql`,
    })
  ])
});

export default client;