import ErrorImage from 'src/assets/images/Alert.png';
import BasicLayout from 'src/layout/Basic';
import { Link } from 'react-router-dom';

interface PageLoaderProps {
    section: string
    title: string
}

const PageError = (props: PageLoaderProps) => {
    return (
        <BasicLayout>
          <main>
            <div className="container-fluid">
              <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item"><a href="homepage.html">Inicio</a></li>
                <li className="breadcrumb-item active">{props.section}</li>
              </ol>
              <div className="row">
                <h1 className="col-lg-6">{props.title}</h1>
              </div>
              <div className="card mb-4 table-responsive">
                <div className="card-body">
                  <div style={{textAlign: 'center'}}>
                  <div><h5 className="font-weight-extrabold col-lg-12">En este momento estamos presentando problemas, por favor intente más tarde. <br></br> <Link to="/">Volver a la página Principal</Link> o recarga la página</h5></div>
                    <img src={ErrorImage} alt="error" style={{ height: '70px'}}/>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </BasicLayout>
    );
}

export default PageError;