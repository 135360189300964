import BasicLayout from 'src/layout/Basic';
const MapaViento = () => {
  return (
    <BasicLayout>
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><a href="homepage.html">Inicio</a></li>
            <li className="breadcrumb-item active">Mapas</li>
          </ol>
          <h1 className="mt-4">Viento</h1>
          <div className="card mb-4">
            <div className="card-body">
              <p>Espacio para Mapa</p>
            </div>
          </div>
        </div>
      </main>
    </BasicLayout>
  )
};

export default MapaViento;