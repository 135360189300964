interface LoginProps {
  children: any;
}
const Login = ({children} : LoginProps) =>{
  return (
    <div id="authentication">
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 p-4">
                  {children}
                </div>
              </div>
            </div>
          </main>
        </div>
    </div>
  </div>
  )
}

export default Login;