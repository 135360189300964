import { gql } from '@apollo/client';
const DELETE_FINCA = gql`
mutation delete_finca(
    $id: Float!
){
    delete_finca(finca:{
    id:$id
    }){
        mensaje
    }
}
`
export default DELETE_FINCA;