import { tokenExpired, decodeToken } from 'src/utils/auth';
import { AUTH_START, AUTH_SUCCESS, AUTH_LOGOUT, 
  AuthActionTypes, AuthSuccess, AuthFailAction, 
  AUTH_FAIL, AuthStartApp, AUTH_START_APP, AuthStep, AUTH_STEP 
} from '../types/auth.types';

import { userStart } from '../actions/user';
import { UserStart } from '../types/user.types';
import { getNavBarContent } from 'src/utils/navBarContent';
import { AdminList, TechnicalList } from 'src/utils/sideBarContent';
import { powerBICheckState } from './powerbi';
import {refreshStart, refreshEnd} from '../actions/refresh';
import client from 'src/apollo';
import REFRESH_TOKEN from 'src/apollo/queries/refreshToken';

export const authStart = () : AuthActionTypes => {
  return {
    type: AUTH_START
  }
}

export const authSuccess = (tokenInfo: AuthSuccess) : AuthActionTypes => {
  localStorage.setItem('accessToken', tokenInfo.accessToken);
  localStorage.setItem('idToken', tokenInfo.idToken);
  localStorage.setItem('refreshToken', tokenInfo.refreshToken);
  localStorage.setItem('userId', tokenInfo.userId);
  return {
    type: AUTH_SUCCESS,
    tokenInfo
  }
}

export const authFail = (error: string) : AuthFailAction => {
  return {
    type: AUTH_FAIL,
    error,
  }
}

export const authLogout = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('idToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('userId');
  localStorage.removeItem('powerBiAccess');
  localStorage.removeItem('powerBiRefresh')
  return {
      type: AUTH_LOGOUT
  };
};

export const authStartApp = () : AuthStartApp => {
  return {
    type: AUTH_START_APP,
  }
}

export const authCheckState = () => {
  return (dispatch : any) => {
      const token = localStorage.getItem('idToken');
      const refresh_token = localStorage.getItem('refreshToken');
      if (!token) {
        dispatch(authLogout());
      } else {
          const isExpired = tokenExpired(token);
          if (isExpired && refresh_token) {
            dispatch(refreshStart());
            const info = decodeToken(token);
            client.mutate({mutation: REFRESH_TOKEN, variables:{
              username: info['cognito:username'],
              refreshToken: refresh_token
            }}).then((response) => {
              if(response.data){
                const {accessToken, idToken, refreshToken} = response.data.refreshToken;
                const info = decodeToken(idToken);
                let customRole;
                const tokenInfo = {
                  accessToken,
                  idToken,
                  refreshToken,
                  userId: info['cognito:username'] || '',
                } as AuthSuccess;
                customRole = JSON.parse(info["custom:roles"]);
                const userInfo = {
                  userId: info['cognito:username'] || '',
                  navBar: getNavBarContent(customRole[0] ? customRole: 'Admin', info['name'], customRole),
                  roles: customRole,
                  sidebar: customRole[0] === 'Admin' ? [...AdminList]: TechnicalList,
                  name: info['name'],
                  currentLevel: customRole[0],
                } as UserStart;
                dispatch(authSuccess(tokenInfo));
                dispatch(userStart(userInfo));
                dispatch(powerBICheckState());
                dispatch(authStartApp());
                dispatch(refreshEnd());
              }
              else{
                dispatch(refreshEnd());
                dispatch(authLogout());
                
              }
            }).catch(refreshError => {
              dispatch(refreshEnd());
              dispatch(authLogout());              
            });
        } else {
              const accessToken = localStorage.getItem('accessToken');
              const idToken = localStorage.getItem('idToken');
              const refreshToken = localStorage.getItem('refreshToken');
              const info = decodeToken(token);
              let customRole;
              const tokenInfo = {
                accessToken,
                idToken,
                refreshToken,
                userId: info['cognito:username'] || '',
              } as AuthSuccess;
              customRole = JSON.parse(info["custom:roles"]);
              const userInfo = {
                userId: info['cognito:username'] || '',
                navBar: getNavBarContent(customRole[0] ? customRole: 'Admin', info['name'], customRole),
                roles: customRole,
                sidebar: customRole[0] === 'Admin' ? [...AdminList]: TechnicalList,
                name: info['name'],
                currentLevel: customRole[0],
              } as UserStart;
              dispatch(authSuccess(tokenInfo));
              dispatch(userStart(userInfo));
              dispatch(powerBICheckState());
              dispatch(authStartApp());
          }   
      }
  };
};

export const authStep = (step: string) : AuthStep => {
  return {
    type: AUTH_STEP,
    step,
  }
}

