import { gql } from '@apollo/client';
const GET_PROPIETARIOS= gql`
query getPropietarios{
    propietarios {
      id
      name
      family_name
    }
  }`;

  export default GET_PROPIETARIOS;