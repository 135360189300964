interface TabTitlesProps {
  titles: string[];
  current: string
  onClickTab(tab: string): void
};
const TabTitles = (props: TabTitlesProps) => {
  const { titles, onClickTab, current } = props;
  const clickTab = (event: any, title: string) =>{
    event.preventDefault();
    onClickTab(title);
  };
  return (
    <ul className="nav nav-tabs nav-fill nav-justified" id="myTab">
    { 
      titles.map((title: string, index: number) => (
        <li className="nav-item" key={index}>
          <a 
            className={`nav-link ${current === title ? 'active': ''}`}
            onClick={(e)=>{clickTab(e, title)} }
            // eslint-disable-next-line jsx-a11y/anchor-is-valid  
            href="#/" 
            data-toggle="tab" 
            role="tab" 
            aria-controls="tab1" 
            aria-selected="true">
            {title}
          </a>
        </li>
      ))
    }
    </ul>
  )
};

export default TabTitles;