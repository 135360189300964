import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report, Page } from 'powerbi-client';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import usePowerBiReport from 'src/hooks/getReportId';
import useReportEmbed from 'src/hooks/getReportEmbed';
import PreLoaderImage from 'src/assets/images/Preloader.gif';
import { convertCSVDataToXlsFormat, exportXls } from 'src/utils/xls';
import {
  isMobile
} from "react-device-detect";

const initialConfig = {
  id: '',
  type: 'report',
  tokenType: models.TokenType.Embed,
  accessToken: '',
  embedUrl: '',
  pageName: '',
  viewMode: models.ViewMode.View,
  settings: {
    hyperlinkClickBehavior: models.HyperlinkClickBehavior.RaiseEvent,
    hideErrors: true,
    filterPaneEnabled: false,
    navContentPaneEnabled: false,
    layoutType: isMobile ? models.LayoutType.MobilePortrait : models.LayoutType.Master,
    visualSettings: {
      visualHeaders: [{
        settings: {
          visible: false
        }
      }]
    },
  }
} as models.IReportEmbedConfiguration;

interface FilterReport {
  table: string
  column: string
  value: string
}

interface PowerBiReportProps {
  pageName: string
  reportName: string
  className?: string
  customEvent?(event: any): void
  onDataSelected?(event: any): void
  onFinishExport?(): void
  filters?: FilterReport[]
  settings?: any
}

const initializeFilters = (filters: FilterReport[]) => {
  return filters.map<models.ReportLevelFilters>((filter: FilterReport) => {
    const { table, column, value } = filter;
    return {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table,
        column,
      },
      operator: 'In',
      values: [value],
      filterType: 1,
    }
  });
}

const PowerBiReport = forwardRef((props: PowerBiReportProps, ref: any) => {
  const [config, setConfig] = useState<models.IReportEmbedConfiguration>(initialConfig);
  const [reportLoading, setReportLoading] = useState(true);
  const { reportId, reportEmbedUrl } = usePowerBiReport(props.reportName);
  const { embedToken, error: errorEmbed } = useReportEmbed(reportId);
  const [report, setReport] = useState<Report>();
  const [currentPage, setPage] = useState<Page>()
  const [loadedReport, setLoadedReport] = useState(false);
  useEffect(() => {
    if (embedToken) {
      setConfig({
        ...config,
        id: reportId,
        embedUrl: reportEmbedUrl,
        accessToken: embedToken,
        ...(props.filters && {
          filters: initializeFilters(props.filters)
        }
        ),
        ...(props.settings && {
          settings: { ...props.settings }
        })
      });
      setReportLoading(false);
    }
    if (errorEmbed) {
      setReportLoading(false);
    }
  }, [embedToken, errorEmbed]);

  useEffect(() => {
    if (loadedReport) {
      report?.getPages().then((pages: any) => {
        for (const page of pages) {
          if (props.pageName === page.displayName) {
            page.setActive();
            setPage(page);
            break;
          }
        }
      });
      report?.on('dataHyperlinkClicked', function (event) {
        if (props.customEvent) {
          props.customEvent(event);
        }
      });
      report?.on('dataSelected', function (event) {
        if(props.onDataSelected){
          props.onDataSelected(event);
        }
      });
    }
  }, [loadedReport, props.pageName, report]);


  useEffect(() => {
    if (props.filters && loadedReport) {
      setConfig({
        ...config,
        ...(props.filters && {
          filters: initializeFilters(props.filters)
        }
        ),
      })
    }
  }, [props.filters]);

  useImperativeHandle(
    ref,
    () => ({
      exportData(exportFormatType: string, title: string, filename: string) {
        currentPage?.getVisuals().then(async (visuals) => {
          const tableVisual = visuals.find((visual: any) => (visual.type === 'tableEx'));
          try {
            if (tableVisual) {
              let result = await tableVisual.exportData(models.ExportDataType.Summarized);
              if(exportFormatType === 'xls') {
                const converted = convertCSVDataToXlsFormat(result.data);
                exportXls(converted.heading, converted.bodyData,title, filename);
              } else{
                const blob = new Blob([result.data], { type: 'text/plain'});
                const encodedUri = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", filename);
                link.click();
              }
            }
            if (props.onFinishExport) {
              props.onFinishExport();
            }
          } catch (error) {
            if (props.onFinishExport) {
              props.onFinishExport();
            }
          }
        });
      }
    }),
  );

  if (reportLoading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <img src={PreLoaderImage} alt="loading" style={{ height: '200px' }} />
      </div>
    );
  }

  if (errorEmbed) {
    return (
      <div>Algo ha ocurrido por favor intente denuevo!</div>
    )
  }
  return (
    <>
      <PowerBIEmbed
        embedConfig={config}
        cssClassName={props.className || 'powerbi-report'}
        eventHandlers={
          new Map([
            ['loaded', function (_event: any) { setLoadedReport(true) }],
            ['rendered', function (_event: any) { }],
            ['error', function (event: any) { console.log(event.detail); }]
          ])
        }
        getEmbeddedComponent={(embedObject) => {
          setReport(embedObject as Report);
        }}></PowerBIEmbed>
    </>
  )
});

export default PowerBiReport;