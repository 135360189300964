import { gql } from '@apollo/client';
const ADD_MUNICIPIO = gql` 
mutation Add_municipio(
    $nombre: String!
    $departamento: Float!
){
    Add_municipio(Municipio:{
        nombre: $nombre
        departamento:$departamento
    }){
        id
        nombre
        departamento{
            nombre
        }
    }
}
`;

export default ADD_MUNICIPIO;