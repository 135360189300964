import BasicLayout from 'src/layout/Basic';
const RecuperarRegistros = () => {
  return (
    <BasicLayout>
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><a href="homepage.html">Inicio</a></li>
            <li className="breadcrumb-item active">Administracion</li>
          </ol>
          <div className="row">
            <h1 className="col-lg-10">Recuperar Registros</h1>
            <div className="col-lg-2"><a className="btn btn-primary btn-sm mb-4" href="#">Ver Ayuda</a></div>
          </div>
          <div className="card mb-4">
            <div className="card-body">
              <div className="form-group">
                <label className="small mb-1" htmlFor="inputCountry">País:</label>
                <select className="form-control py-4 custom-select">
                  <option>Honduras IHCAFE</option>
                </select>
              </div>
              <div className="form-group">
                <label className="small mb-1" htmlFor="inputCountry">Técnico:</label>
                <select className="form-control py-4 custom-select">
                  <option>David Fernando</option>
                </select>
              </div>
              <div className="form-group">
                <label className="small mb-1" htmlFor="inputCountry">IMEI:</label>
                <select className="form-control py-4 custom-select">
                  <option>Imei</option>
                </select>
              </div>
              <div className="form-group">
                <label className="small mb-1" htmlFor="inputCountry">Archivos:</label>
                <select className="form-control py-4 custom-select">
                  <option>Archivos</option>
                </select>
              </div>
              <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                <button className="btn btn-primary">Guardar</button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </BasicLayout>
  )
};

export default RecuperarRegistros;