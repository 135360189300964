import { useState, forwardRef, useImperativeHandle } from "react";
import { getMuestreoInfo_getMuestreo_muestreo_detail } from "src/apollo/queries/types/getMuestreoInfo";
import PreLoaderImage from 'src/assets/images/Preloader.gif';

interface MuestreoGilloFormProps {
  muestreoDetail: getMuestreoInfo_getMuestreo_muestreo_detail
  savingStatus: boolean
  onSaveForm(data: RegisterInfoType[]): void
}

interface RegisterInfoType {
  perforaciones: number
}

interface RegisterDataType {
  registros: RegisterInfoType[],
}
const MuestreoGrilloForm = forwardRef((props: MuestreoGilloFormProps, ref: any) => {
  const { muestreoDetail, onSaveForm, savingStatus } = props;
  const [registros, setRegistros] = useState(muestreoDetail.registros.map((register: RegisterInfoType) => {
    return {
      perforaciones: register.perforaciones
    }
  }));

  useImperativeHandle(
    ref,
    () => ({
      getRegisterData(): RegisterDataType {
        return {
          registros
        }
      }
    })
  )

  const onChange = (e: any, index: number) => {
    const value = e.target.value.replace(/[^0-2]/g, '');
    const newRegistros = [...registros];
    newRegistros[index].perforaciones = value;
    setRegistros(newRegistros);
  }
  return (
    <div className="row">
      {registros.map((register: RegisterInfoType, index: number) => (
        <div className="row col-6" key={index}>
          <div className="col-4 mt-3">
            <label className="small mb-1" htmlFor="inputCountry">{`Planta ${index + 1}`}</label>
          </div>
          <div className="col-8 mt-3">
            <input
              className="form-control py-4"
              value={register.perforaciones}
              maxLength={1}
              onChange={(e) => onChange(e, index)}
            />
          </div>
        </div>
      ))}
      <div className="col-12 mt-3">
        {
          savingStatus ? (
            <img src={PreLoaderImage} alt="loading" style={{
              height: '150px',
            }} />
          ) : (
            <button
              className="btn btn-primary btn-lg"
              onClick={() => { onSaveForm(registros) }}
            >Guardar</button>
          )
        }

      </div>
    </div>
  )
});

export default MuestreoGrilloForm;