import BasicLayout from 'src/layout/Basic';
import GET_ALLFINCAS from 'src/apollo/queries/getAllFincas'
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useQuery, gql } from '@apollo/client';
import {Modal} from '@material-ui/core';
import { isTypeNode } from 'graphql';
import UPDATE_FINCA from 'src/apollo/queries/updateFinca';
import { useMutation } from '@apollo/client';
import client from 'src/apollo';
import { update_finca, update_fincaVariables } from 'src/apollo/queries/types/update_finca';
import { TipoFinca } from 'src/apollo/types/graphql-global-types';
import { TipoHuerta } from 'src/apollo/types/graphql-global-types';
import { TipoCafe } from 'src/apollo/types/graphql-global-types';
import {ModalBody, ModalHeader } from 'reactstrap';
import GET_USER_TECNICO from 'src/apollo/queries/getUserTecnico'
import GET_DEPARTAMENTOS from 'src/apollo/queries/getDepartamento'
import GET_MUNICIPIOS from 'src/apollo/queries/getMunicipios'
import GET_ALDEAS from 'src/apollo/queries/getAldeas'
import { Add_municipio, Add_municipioVariables } from 'src/apollo/queries/types/Add_municipio';
import ADD_MUNICIPIO from 'src/apollo/queries/addMunicipio';
import { Add_Aldea, Add_AldeaVariables } from 'src/apollo/queries/types/Add_Aldea';
import ADD_ALDEA from 'src/apollo/queries/addAldea';
import GET_PROPIETARIOS from 'src/apollo/queries/getPropietarios';
import ADD_FINCA from 'src/apollo/queries/addFinca'
import { add_finca, add_fincaVariables } from 'src/apollo/queries/types/add_finca';
import DELETE_FINCA from 'src/apollo/queries/deleteFinca';
import FIND_INSTITUCION from 'src/apollo/queries/findInstitucion'
import PreLoaderImage from 'src/assets/images/Preloader.gif';
import PageLoader from 'src/components/PageLoader';
import FIND_ALL_FINCAS from '../../apollo/queries/findAllFincas';
import { useLazyQuery } from '@apollo/client';
import XLSX from 'xlsx';
import ReactPaginate from 'react-paginate';
import { SettingsCellOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ADD_PROPIETARIO from 'src/apollo/queries/addPropietario';
import { add_propietario, add_propietarioVariables } from 'src/apollo/queries/types/add_propietario';
import PageError from 'src/components/PageError';

const  fileSaver = require('file-saver');

function s2ab(s: any){
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}
    
const PadronFincas = () => {
  const [datos, setDatos] = useState([]);
  const [datosPropietarios,setDatosPropietarios]=useState([]);
  const [enable, setEnable] = useState(false);
  const [datosInstitucion, setDatosInstitucion]= useState([]);
  const [datosTecnico,setDatosTecnico]=useState([]);
  const [datosDepto,setDatosDepto]=useState([]);
  const [datosAldea,setDatosAldea]=useState([]);
  const [datosMunicipios, setMunicipios]=useState([]);
  const [inputProp, setInputProp] = useState(false);
  const [inputProd, setInputProd] = useState(false);
  const [hide, setHide] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageMunicipio, setErrorMessageMunicipio] = useState('');
  const [errorMessagePropietario, setErrorMessagePropietario] = useState('');
  const [errorMessageProductor, setErrorMessageProductor] = useState('');
  const [handledValidation, setHandledValidation] = useState(true);
  const [finishValid, setFinishValid] = useState('');
  const [errorValidCampo, setValidCampo] = useState(false);
  const [errorValidCampoPropietario, setValidCampoPropietario] = useState(false);
  const [errorValidCampoProductor, setValidCampoProductor] = useState(false);
  const [errorValidCampoMunicipio, setValidCampoMunicipio] = useState(false);
  const [ocultar, setOcultar]=useState(true);
  const [showAldea,setShowAldea]=useState(false);
  const [enabledMunicipio, setEnabledMunicipio] = useState(false);
  const [enabledAldea_n, setEnabledAldea_n] = useState(false);
  const [skip, setSkip]=useState(0);
  const [take, setTake]=useState(15);
  const [count, setCount]=useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const [currentPage, setCurrentPage]=useState(0);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [buttonPushed, setButtonPushed] = useState(false);
  const [parametro, setParametro] = useState('');
  const [add, setAdd]=useState(false);
  const fincaInit ={
    id:'',
    tipo_finca: TipoFinca.Centinela,
    tecnico:'',
    nombre: '',
    latitud: '',
    longitud:'',
    departamento:'',
    municipio: '',
    municipioNew:'',
    aldea_n:'',
    aldeaNew:'',
    area:'',
    lote:'',
    tipo_huerta:TipoHuerta.Convencional,
    variedad:'',
    porcentaje_sombra:'',
    densidad_arboles_ha:'',
    superficie:'',
    altura:'',
    anio_plantacion:'',
    tipo_cafe:TipoCafe.Arabico,
    propietario:'',
    propietarioNew:'',
    productor:'',
    productorNew:'',
    institucion:''
  }
  const [fincaSeleccionada, setFincaSeleccionada]=useState(fincaInit);
  let munErrorMessage; let latErrorMessage; let longErrorMessage; let suErrorMessage;
  let areaErrorMessage; let sombraErrorMessage; let denErrorMessage; let altErrorMessage;
  let anioErrorMessage; let prodErrorMessage; let proErrorMessage; let insErrorMessage;
  let addErrorMessage; let aldeaErrorMessage; let aldea_nErrorMessage; let tecErrorMessage;
  let finishMessage='Asegúrese de que todos los campos estén ingresados de forma correcta';
  const errorStyle={
    borderColor: 'red'
  }

  const { loading:loadingPropietarios, error:errorPropietarios, data:dataPropietarios}= useQuery(GET_PROPIETARIOS); 
  const {loading:loadingTecnico, error:errorTecnico, data:dataTecnico}=useQuery(GET_USER_TECNICO);
  const{loading:loadingDepto, error:errorDepto, data:dataDepto}=useQuery(GET_DEPARTAMENTOS);
  const [findAldeas,{loading:loadingAldeas, error:errorAldeas, data:dataAldeas}]=useLazyQuery(GET_ALDEAS,{
    onCompleted(dataAldeas){
      setDatosAldea(dataAldeas)
     }
   });

  const [findMunicipios,{loading:loadingMunicipio, error:errorMunicipio, data:dataMunicipio}]=useLazyQuery(GET_MUNICIPIOS,{
   onCompleted(dataMunicipio){
     setMunicipios(dataMunicipio.find_Municipios)
    }
  });

  const{loading:loadingInstitucion, error:errorInstitucion, data:dataInstitucion}=useQuery(FIND_INSTITUCION);

  const [deleteFinca, {error:errorDelete, loading:loadinDelete}]= useMutation(DELETE_FINCA,{variables:{id:parseFloat(fincaSeleccionada.id)}})

  const [addFinca,{error:errorAdd, loading:loadingAdd}]= useMutation<add_finca,add_fincaVariables>(ADD_FINCA,
    {
      update(){
        client.resetStore();
      }
    })
  const [addAldea,{error:errorAddAldea, loading: loadingAddAldea}]=useMutation<Add_Aldea, Add_AldeaVariables>(
      ADD_ALDEA)
  
  const [addMunicipio,{error: errorAddMun, loading: loadingAddMun }]=useMutation<Add_municipio, Add_municipioVariables>(
      ADD_MUNICIPIO)
    
  const [addPropieario,{error: errorAddProp, loading: loadingAddProp,data: dataNewProp }]=useMutation<add_propietario, add_propietarioVariables>(ADD_PROPIETARIO)

  const { loading:loadingFincas, error:errorFincas, data:dataFincas}= useQuery(GET_ALLFINCAS,{variables:{skip:skip,take:15, parametro: parametro}}); 
  const [updateFinca, { error: errorUpdate, loading: loadingUpdate }] = useMutation<update_finca, update_fincaVariables>(
    UPDATE_FINCA, {
      update() {
        client.resetStore();
      },
    }
  )
    
  const [exportFincas, {loading: loadingExport, data: dataExport, called: calledExport}] = useLazyQuery(FIND_ALL_FINCAS);
  useEffect(()=>{
    if(calledExport && dataExport && buttonPushed){
      var heading = [[
        "id", "name", "lat", "lon", "altitud", "sombra", "densidad", 
        "plantacion", "nivel2", "nivel3", "nivel4", 
        "tecnico", "tipo_cafe", "tipo_finca", "variedad"
      ]];
      var fincas:any = [];
      dataExport.absoluteFincas.map((finca: any) =>{
        let aldea = finca.aldea_n!==null? finca.aldea_n.nombre: '';
        let municipio = finca.aldea_n!==null? finca.aldea_n.municipios.nombre: '';
        let departamento = finca.aldea_n!==null? finca.aldea_n.municipios.departamento.nombre: '';  
        fincas.push({
          id: finca.id, name: finca.nombre, lat: finca.latitud, lon: finca.longitud, altitud: finca.altura, sombra: Number(finca.porcentaje_sombra)>0? 'si':'no',
          densidad: finca.densidad_arboles_ha, plantacion: finca.anio_plantacion,
          nivel2: departamento, nivel3: municipio, nivel4: aldea, tecnico: finca.tecnico!=null ? finca.tecnico.name : null,
          tipo_cafe: finca.tipo_cafe, tipo_finca: finca.tipo_finca, variedad: finca.variedad
        });
      });      
      var ws = XLSX.utils.aoa_to_sheet(heading);
      XLSX.utils.sheet_add_json(ws, fincas, {
        header: heading[0],
        skipHeader: true,
        origin: -1
      });
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Fincas");
      var wbout = XLSX.write(wb, {bookType:'xlsx', bookSST:true, type: 'binary'});
      fileSaver.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), "fincas.xlsx");
      setButtonPushed(false);
      client.resetStore();
    }
  },[calledExport, dataExport, buttonPushed]);
  useEffect(()=>{
    if(!loadingTecnico && dataTecnico && dataTecnico.users){
      setDatosTecnico(dataTecnico.users);}
    },[dataTecnico,loadingTecnico]);

    useEffect(()=>{
      if(!loadingInstitucion && dataInstitucion && dataInstitucion.findAllInstitucion){
        setDatosInstitucion(dataInstitucion.findAllInstitucion);
      }
    },[dataInstitucion,loadingInstitucion]);

    useEffect(()=>{
      if(!loadingPropietarios && dataPropietarios && dataPropietarios.propietarios){
        setDatosPropietarios(dataPropietarios.propietarios);}
    },[dataPropietarios,loadingPropietarios]);
     
  useEffect(()=>{
    if(!loadingDepto && dataDepto && dataDepto.find_departamentos){
      setDatosDepto(dataDepto.find_departamentos);
    }
  },[dataDepto,loadingDepto]);
 
  useEffect(()=>{
    if(!loadingMunicipio && dataMunicipio && dataMunicipio.find_municipios){
      setMunicipios(dataMunicipio.find_municipios);
    }
  },[dataMunicipio,loadingMunicipio]);

  useEffect(()=>{
    if(!loadingAldeas && dataAldeas && dataAldeas.find_Aldeas){
      setDatosAldea(dataAldeas.find_Aldeas);
    }
  },[dataAldeas,loadingAldeas]);

  useEffect(() => {
    if (!loadingFincas && dataFincas.findFincasFilter.fincas) {
      setDatos(dataFincas.findFincasFilter.fincas);
      setCount(dataFincas.findFincasFilter.cantidad)
      setFirstLoad(false);
    }
  }, [dataFincas, loadingFincas]);

  const validations = {
    isValidTec: () =>{
      if(fincaSeleccionada.tecnico===''){
        tecErrorMessage = 'Técnico no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }return true;
    },
    isValidMun: () =>{
      if(fincaSeleccionada.municipio===''){
        munErrorMessage = 'Municipio no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }return true;
    }, isValidMunNew: () =>{
      if(fincaSeleccionada.municipio==='0' && fincaSeleccionada.municipioNew===''){
        munErrorMessage = 'Municipio no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }return true;
    },isValidAldea_n: () =>{
      if(fincaSeleccionada.municipio!=='0' && fincaSeleccionada.aldea_n===''){
        aldea_nErrorMessage = 'Aldea no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }return true;
    },isValidAldea: () =>{
      if(fincaSeleccionada.aldea_n==='0' &&fincaSeleccionada.aldeaNew===''){
        aldeaErrorMessage = 'Aldea no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }return true;
    },isValidAldeaNew: () =>{
      if(fincaSeleccionada.municipio==='0' &&fincaSeleccionada.aldeaNew===''){
        aldeaErrorMessage = 'Aldea no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }return true;
    },
    isValidLat: () =>{
      if(fincaSeleccionada.latitud===''){
        latErrorMessage = 'Latitud no puede ir vacío';
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      } return true;
    },isValidLong: () =>{
      if(fincaSeleccionada.longitud===''){
        longErrorMessage = 'Longitud no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    }, isValidArea: () =>{
      if(fincaSeleccionada.area===''){
        areaErrorMessage = 'Área no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }return true;
    },isValidSom: () =>{
      if(fincaSeleccionada.porcentaje_sombra===''){
        sombraErrorMessage = 'Sombra no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    },isValidDen: () =>{
      if(fincaSeleccionada.densidad_arboles_ha===''){
        denErrorMessage = 'Densidad no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    },isValidSu: () =>{
      if(fincaSeleccionada.superficie===''){
        suErrorMessage = 'Superficie no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    },isValidAlt: () =>{
      if(fincaSeleccionada.altura===''){
        altErrorMessage = 'Altura no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    },isValidAnio: () =>{
      if(fincaSeleccionada.anio_plantacion===''){
        anioErrorMessage = 'Plantación no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    },isValidPro: () =>{
      if(fincaSeleccionada.propietario===''){
        proErrorMessage = 'Propietario no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    },isValidPropietarioNew: () =>{
      if(fincaSeleccionada.propietario==='0' && fincaSeleccionada.propietarioNew===''){
        proErrorMessage = 'Propietario no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    },isValidProd: () =>{
      if(fincaSeleccionada.productor===''){
        prodErrorMessage = 'Productor no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    },isValidProductorNew: () =>{
      if(fincaSeleccionada.productor==='' && fincaSeleccionada.productorNew==='0'){
        proErrorMessage = 'Propietario no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    },isValidIns: () =>{
      if(fincaSeleccionada.institucion===''){
        insErrorMessage = 'Institución no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    }
    
  }

  if (loadingUpdate) {
    return <PageLoader section="Administración" title="Padrón de Fincas"></PageLoader>
  }
  if (errorUpdate) {
    return (
      <PageError section="Reportes" title="Padrón de Fincas"></PageError>
    )
  }

  const peticionPropietario=async()=>{
    try{ if(fincaSeleccionada.propietarioNew){
      const responsePropietario = await addPropieario({
        variables:{
          name: fincaSeleccionada.propietarioNew,
          family_name: fincaSeleccionada.propietarioNew
        }
      }); return responsePropietario;
    }
    }catch (err) {
      err?.graphQLErrors?.map((item: any) => {
      if (item.handled) {
          setErrorMessagePropietario(item.message);
          setFinishValid(finishMessage);
          setValidCampoPropietario(true);
          setHandledValidation(false);
          throw "el propietario ya existe";
      }else{
        <PageError section="Reportes" title="Padrón de Fincas"></PageError>
      }
  }); 
      console.log('err', err);
  }}

  const peticionProductor=async()=>{
    try{ if(fincaSeleccionada.productorNew){
      const responseProductor = await addPropieario({
        variables:{
          name: fincaSeleccionada.productorNew,
          family_name: fincaSeleccionada.productorNew
        }
      }); return responseProductor;
    }
    }catch (err) {
      err?.graphQLErrors?.map((item: any) => {
        if (item.handled) {
            setErrorMessageProductor('El nombre del productor ya existe');
            setFinishValid(finishMessage);
            setValidCampoProductor(true);
            setHandledValidation(false);
            throw "el productor ya existe";
        }else{
          <PageError section="Reportes" title="Padrón de Fincas"></PageError>
        }
    });
      console.log('err', err);
  }}

  const peticionMunicipio=async()=>{
    try{ if(fincaSeleccionada.municipioNew){
      const resp = await addMunicipio({
        variables:{
          nombre: fincaSeleccionada.municipioNew,
          departamento: parseInt(fincaSeleccionada.departamento)
        }
      }); return resp;
    }
    }catch (err) {
      err?.graphQLErrors?.map((item: any) => {
        if (item.handled) {
          setErrorMessageMunicipio(item.message);
          setFinishValid(finishMessage);
          setValidCampoMunicipio(true);
          setHandledValidation(false);
          throw "El Municipio ya existe";
      }else{
        <PageError section="Reportes" title="Padrón de Fincas"></PageError>
      } 
      });
      console.log('err', err);
  }}

  const peticionAldea=async()=>{
    const responseMunicipio = await peticionMunicipio();
    try{
     if(fincaSeleccionada.aldeaNew ){ 
      if(fincaSeleccionada.aldeaNew || (handledValidation || responseMunicipio?.data?.Add_municipio.id)){
      const response =  await addAldea({
          variables:{
            nombre: fincaSeleccionada.aldeaNew, 
            municipio: responseMunicipio?.data?.Add_municipio.id || parseInt(fincaSeleccionada.municipio)
          } 
        });
      return response; 
    } 
    }}catch (err) {
      err?.graphQLErrors?.map((item: any) => {
        if (item.handled) {
          setErrorMessage(item.message);
          setFinishValid(finishMessage);
          setValidCampo(true);
          setHandledValidation(false);
          throw "La aldea ya existe";
      }else{
        return(
          <PageError section="Reportes" title="Padrón de Fincas"></PageError>
        )
      }
      });
      console.log('err', err);
  }
}
 
  const peticionPut = async () => {
    setAdd(true);
    try {
    if(validations.isValidLat() && validations.isValidLong() && validations.isValidArea() &&
      validations.isValidSom() && validations.isValidDen() && validations.isValidSu()&&
      validations.isValidAlt() && validations.isValidAnio() && validations.isValidPro()&&
      validations.isValidProd() && validations.isValidIns() && validations.isValidMunNew()&& validations.isValidMun()&&  validations.isValidAldea()&&
      validations.isValidAldeaNew() && validations.isValidAldea_n()&& validations.isValidPropietarioNew() && validations.isValidProductorNew()){
      const responseAldea = await peticionAldea();
      const responsePropietario = await peticionPropietario();
      const responseProductor = await peticionProductor();
      if(handledValidation || responsePropietario?.data?.add_propietario.id || responseAldea?.data?.Add_Aldea.id || responseProductor?.data?.add_propietario.id){
      await updateFinca({
        variables: {
          id:parseFloat(fincaSeleccionada.id),
          tipo_finca: fincaSeleccionada.tipo_finca,
          tecnico: parseFloat(fincaSeleccionada.tecnico),
          nombre: fincaSeleccionada.nombre,
          latitud:parseFloat(fincaSeleccionada.latitud),
          longitud: parseFloat(fincaSeleccionada.longitud),
          area: parseFloat(fincaSeleccionada.area),
          lote: fincaSeleccionada.lote,
          aldea_n: responseAldea?.data?.Add_Aldea.id || parseFloat(fincaSeleccionada.aldea_n),
          tipo_huerta: fincaSeleccionada.tipo_huerta,
          variedad: fincaSeleccionada.variedad,
          porcentaje_sombra: parseFloat(fincaSeleccionada.porcentaje_sombra),
          densidad_arboles_ha:parseFloat(fincaSeleccionada.densidad_arboles_ha),
          superficie:parseFloat(fincaSeleccionada.superficie),
          altura:parseFloat(fincaSeleccionada.altura),
          anio_plantacion:parseFloat(fincaSeleccionada.anio_plantacion),
          tipo_cafe: fincaSeleccionada.tipo_cafe,
          propietario: responsePropietario?.data?.add_propietario.id || parseFloat(fincaSeleccionada.propietario),
          productor: responseProductor?.data?.add_propietario.id || parseFloat(fincaSeleccionada.productor)
        }
      });
      setEnable(false)
      setShowAldea(false)
      setHide(false);
      abrirCerrarModalEditar();
    }else{
      return null;
    }}
    } catch (err) {
      console.log('err', err);
      }
  }

  const peticionAdd = async () => {
    setAdd(true);
    try {
      if(validations.isValidLat() && validations.isValidLong() && validations.isValidArea() &&
      validations.isValidSom()&& validations.isValidDen()&& validations.isValidSu()&&
      validations.isValidAlt()&& validations.isValidAnio()&& validations.isValidPro()&&
      validations.isValidProd()&& validations.isValidIns()&& validations.isValidMun()&& validations.isValidMunNew()&& validations.isValidAldea()&&
      validations.isValidAldeaNew()&& validations.isValidAldea_n()&& validations.isValidTec()&& validations.isValidPropietarioNew() && validations.isValidProductorNew()){
        const responseAldea = await peticionAldea();
        const responsePropietario = await peticionPropietario();
        const responseProductor = await peticionProductor();
        if(handledValidation || responsePropietario?.data?.add_propietario.id || responseAldea?.data?.Add_Aldea.id || responseProductor?.data?.add_propietario.id){
        await addFinca({
        variables: {
          tipo_finca: fincaSeleccionada.tipo_finca,
          tecnico: parseFloat(fincaSeleccionada.tecnico),
          nombre: fincaSeleccionada.nombre,
          latitud:parseFloat(fincaSeleccionada.latitud),
          longitud: parseFloat(fincaSeleccionada.longitud),
          area: parseFloat(fincaSeleccionada.area),
          lote: fincaSeleccionada.lote,
          aldea_n: responseAldea?.data?.Add_Aldea.id || parseFloat(fincaSeleccionada.aldea_n),
          tipo_huerta: fincaSeleccionada.tipo_huerta,
          variedad: fincaSeleccionada.variedad,
          porcentaje_sombra: parseFloat(fincaSeleccionada.porcentaje_sombra),
          densidad_arboles_ha:parseFloat(fincaSeleccionada.densidad_arboles_ha),
          superficie:parseFloat(fincaSeleccionada.superficie),
          altura:parseFloat(fincaSeleccionada.altura),
          anio_plantacion:parseFloat(fincaSeleccionada.anio_plantacion),
          tipo_cafe: fincaSeleccionada.tipo_cafe,
          propietario: responsePropietario?.data?.add_propietario.id || parseFloat(fincaSeleccionada.propietario),
          productor: responseProductor?.data?.add_propietario.id || parseFloat(fincaSeleccionada.productor),
          institucion: parseFloat(fincaSeleccionada.institucion)
        }
      });
      abrirCerrarModalInsertar();
    } else {
      return null;
  }}
  }catch (err) {
      console.log('err', err);
    }
  }

  const handleChange=(e:any)=>{
    let {name, value}=e.target;
      setFincaSeleccionada((prevState:any)=>{
        if(prevState[name]!==value){
          setEnable(true);
        }
      if(name==='departamento'){
        findMunicipios({ variables: { id:parseFloat(value)} });
        findAldeas({variables: {id:parseFloat(value)}} );
        setHide(false);
        setEnabledMunicipio(true);
        setShowAldea(false);
        setOcultar(true);
      }
      if(name==='municipio'){
        findAldeas({variables: {id:parseFloat(value)}} );
        setHide(false);
        setEnabledAldea_n(true);
        setShowAldea(false);
        setOcultar(true);
      }
      if(name==='municipio' && value==='0' && modalEditar){
        findMunicipios({ variables: { id:parseFloat(value)} });
        setHide(true);
        setShowAldea(true);
        setOcultar(false);
      }
      if(name==='municipio' && value==='0'){
        setHide(true);
        setShowAldea(true);
        setOcultar(false);
      }if(name==='aldea_n' && value==='0'){
        setShowAldea(true)
      }
      if(name==='aldea_n' && value!=='0'){
        setShowAldea(false)
      }
      if(name==='propietario' && value==='0'){
        setInputProp(true)
      }
      if(name==='propietario' && value!=='0'){
        setInputProp(false)
      }
      if(name==='productor' && value==='0'){
        setInputProd(true)
      }
      if(name==='productor' && value!=='0'){
        setInputProd(false)
      }
      return{...prevState,
      [name]: value
      }
    })
  }
  if (loadingAdd) {
    return <PageLoader section="Administración" title="Padrón de Fincas"></PageLoader>
  }
  if (errorAdd) {
    return (
      <PageError section="Reportes" title="Padrón de Fincas"></PageError>
    )
  }
  const abrirCerrarModalInsertar=()=>{
    setEnable(false)
    setShowAldea(false);
    setOcultar(true);
    setHide(false);
    setInputProd(false);
    setInputProp(false);
    setAdd(false);
    setEnabledAldea_n(false);
    setEnabledMunicipio(false);
    setFincaSeleccionada(fincaInit);
    setErrorMessage('');
    setValidCampo(false);
    setValidCampoPropietario(false);
    setValidCampoProductor(false);
    setValidCampoMunicipio(false);
    setHandledValidation(true);
    setFinishValid('');
    setErrorMessagePropietario('');
    setErrorMessageMunicipio('');
    setModalInsertar(!modalInsertar);
  }
  const abrirCerrarModalEditar=()=>{
    setEnable(false);
    setShowAldea(false);
    setOcultar(true);
    setHide(false);
    setInputProd(false);
    setInputProp(false);
    setFinishValid('');
    setValidCampo(false);
    setValidCampoPropietario(false);
    setValidCampoProductor(false);
    setValidCampoMunicipio(false);
    setErrorMessage('');
    setHandledValidation(true);
    setErrorMessagePropietario('');
    setErrorMessageMunicipio('');
    setModalEditar(!modalEditar);
  }
  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }

  const eliminar = async ()=>{
    try{
      await deleteFinca();
      abrirCerrarModalEliminar();
      client.resetStore()
    }catch(e){
      console.log(e.message);
    }
  }

  const deleteFincaSeleccionada = async (item:any) => {
    try{
     setFincaSeleccionada(item)
     abrirCerrarModalEliminar()
    }catch(e){
      console.log(e.message);
    }
 }

  const editarFinca=(item:any, caso:any)=>{
    let finca={...item}
    if(finca.aldea_n!==null){
      finca.aldea_n=item.aldea_n.id;
      finca.departamento=item.aldea_n.municipios.departamento.id;
      finca.municipio=item.aldea_n.municipios.id;
      findMunicipios({ variables: { id:parseFloat(item.aldea_n.municipios.departamento.id)} });
      findAldeas({variables: {id:parseFloat(item.aldea_n.municipios.id)}} );
    }
    if(finca.propietario!==null){
      finca.propietario=item.propietario.id
    }
    if(finca.productor!==null){
      finca.productor=item.productor.id;
    }
    if(finca.tecnico!==null){
      finca.tecnico=item.tecnico.id;
    }
   finca.aldeaNew='';
    setFincaSeleccionada(finca);
    (caso==='Editar')?abrirCerrarModalEditar():abrirCerrarModalEliminar()
  }

  const handlePageClick=(page:any)=>{
     const selectedPage = page.selected;
     setSkip((selectedPage*take));
     setCurrentPage(selectedPage);
  }
  const registerCount=()=>{
    const ultimoRegistro = skip+take+1;
    if(ultimoRegistro>count){
      return count
    }else{
      return ultimoRegistro
    }

  }

  const bodyEliminar=()=>{
    if(!loadinDelete){
    return(
      <div className="col-10 col-lg-6 col-xl-4 p-4 mx-auto my-auto"> 
          <div className="card">
        <div className="card-header pt-0 text-right">
            <a 
            style={{cursor: 'pointer'}} 
            onClick={(e)=>{
            e.preventDefault();
            abrirCerrarModalEliminar();
            }}
            >
            <FontAwesomeIcon icon={ faTimes }/>
            </a>
          </div>
          <div className="card-body">   
          <label htmlFor="nombre" style={{color: 'rgb(0,176,145)', fontSize:'10'}}>¿Esta seguro que desea eliminar la Finca: {fincaSeleccionada.nombre}</label>
          <br />
          <footer style={{ paddingTop: '21px', marginLeft: 'auto' ,marginTop:20}}>
          <button id="aceptar" onClick={()=>eliminar()}    className="btn btn-aceptar btn-block btn-sm" >
            Aceptar
           </button>
          <button className="btn btn-cancelar btn-block btn-sm"  onClick={() => abrirCerrarModalEliminar()}>Cancelar</button>
        </footer>
      </div>
    </div>
    </div>
  )
          }else{
            return(
              <div className="col-10 col-lg-6 col-xl-4 p-4 mx-auto my-auto"> 
          <div className="card">        
            <div className="card-body">
              <img src={PreLoaderImage} alt="loading" style={{ width: '100%'}}/>
            </div>
          </div>
        </div>
            )
          }
    }
  const bodyEditar=(
      <div style={{ overflowY:'auto',overflowX:'hidden',position:'absolute', width:'470px', height:'600px', backgroundColor:'white', top:'50%',  left:'50%', transform: 'translate(-50%, -50%)', borderRadius:10}}>
      <ModalBody className="ml-3" style={{ overflowY:'auto',position:'absolute', backgroundColor:'white', width:'430px'}}>
      <div className="pt-0 float-right">
            <a 
                style={{cursor: 'pointer'}} 
                onClick={(e)=>{
                e.preventDefault();
                abrirCerrarModalEditar();
                }}
              >
                <FontAwesomeIcon icon={ faTimes }/>
              </a>
        </div>
            <div className="form-group mt-4">
              <label  htmlFor="id"></label>
              <input className="form-control " type="hidden" name="id" id="id"  value={fincaSeleccionada && fincaSeleccionada.id} disabled></input>
            </div>
            <div className="form-group">
              <label  htmlFor="tipo_finca">Tipo Finca</label>
              <select className="form-control"  name="tipo_finca" id="tipo_finca" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.tipo_finca}>
                <option  value=''>--seleccione--</option>
                <option  value={TipoFinca.Centinela}>{TipoFinca.Centinela}</option>
                <option  value={TipoFinca.Demostrativa}>{TipoFinca.Demostrativa}</option>
                <option  value={TipoFinca.Exploracion}>Exploración</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="tecnico">Técnico</label>
              <select className="form-control " name="tecnico" id="tecnico" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.tecnico}>
                <option  value=''>--seleccione--</option>
                  {datosTecnico.map((item:any)=>{
                  return(<option  value={item.id}>{item.name}</option>)
                  })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="nombre">Nombre Finca</label>
              <input className="form-control" type="text" name="nombre" id="nombre" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.nombre}/>
            </div>
            <div className="form-group">
              <label className="small mb-1"  htmlFor="latitud">Latitud</label>
              <input style={add && !validations.isValidLat() ? errorStyle:{}} className="form-control " type="number" name="latitud" id="latitud" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.latitud} />          
              {latErrorMessage ? <h6 className="text-center red">{latErrorMessage}</h6>:null}
            </div>
            <div className="form-group">
              <label className="small mb-1" htmlFor="longitud">Longitud</label>
              <input style={add && !validations.isValidLong() ? errorStyle:{}} className="form-control " type="number" name="longitud" id="longitud" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.longitud} />
              {longErrorMessage ? <h6 className="text-center red">{longErrorMessage}</h6>:null}
            </div>
            <div className="form-group">
              <label className="small mb-1" htmlFor="Area">Área(has)</label>
              <input style={add && !validations.isValidArea() ? errorStyle:{}} className="form-control " type="number" name="area" id="area" onChange={handleChange}  value={fincaSeleccionada && fincaSeleccionada.area}/>
              {areaErrorMessage ? <h6 className="text-center red">{areaErrorMessage}</h6>:null}
            </div>
            <div className="form-group">
              <label className="small mb-1" htmlFor="lote">Lote</label>
              <input className="form-control " type="text" name="lote" id="lote" onChange={handleChange}  value={fincaSeleccionada && fincaSeleccionada.lote}/>
            </div>
            <div className="form-group">
              <label htmlFor="departamento">Departamento</label>
              <select className="form-control"  name="departamento" id="departamento" onChange={handleChange}  value={fincaSeleccionada && fincaSeleccionada.departamento}>
                <option  value=''>--seleccione--</option>
                  {datosDepto.map((item:any)=>{
                    return(<option  value={item.id}>{item.nombre}</option>)
                  })}
              </select>
            </div>
            <div className="form-group" id='mun' >
              <label htmlFor="municipio">Municipio</label>
              <select style={add && !validations.isValidMun() ? errorStyle:{}} className="form-control"  name="municipio" id="municipio" onChange={handleChange}  value={fincaSeleccionada && fincaSeleccionada.municipio}>
                <option  value=''>--seleccione--</option>
                {datosMunicipios.map((item:any)=>{
                  return(<option  value={item.id}>{item.nombre}</option>)
                })}
                <option id='newmuni' value='0'>--NUEVO--</option>
              </select>
              {munErrorMessage ? <h6 className="text-center red">{munErrorMessage}</h6>:null}
            </div>
            <div className="form-group" hidden={!hide} >
              <label htmlFor="municipionew">Municipio</label>
              <input style={add && !validations.isValidMunNew()|| errorValidCampoMunicipio ? errorStyle:{}} className="form-control " type="text" name="municipioNew" id="municipioNew" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.municipioNew}/>
              {munErrorMessage ? <h6 className="text-center red">{munErrorMessage}</h6>:null}
              {errorMessageMunicipio ? <h6 className="text-center red">{errorMessageMunicipio}</h6>:null}
            </div>
            <div className="form-group" hidden={!ocultar}>
              <label htmlFor="aldea">Aldea</label>
              <select style={add && !validations.isValidAldea_n() ? errorStyle:{}} className="form-control"  name="aldea_n" id="aldea_n" onChange={handleChange}  value={fincaSeleccionada && fincaSeleccionada.aldea_n}>
                <option  value=''>--seleccione--</option>
                  {datosAldea.map((item:any)=>{
                  return(<option  value={item.id}>{item.nombre}</option>)
                })}
                <option id='aldeaNew' value='0'>--NUEVO--</option>
              </select>
              {aldea_nErrorMessage ? <h6 className="text-center red">{aldea_nErrorMessage}</h6>:null}
            </div>
            <div className="form-group" hidden={!showAldea} >
              <label htmlFor="aldea">Aldea</label>
              <input style={add && !validations.isValidAldea() || !validations.isValidAldeaNew() || errorValidCampo ? errorStyle:{}} className="form-control " type="text" name="aldeaNew" id="aldeaNew" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.aldeaNew}/>
              {aldeaErrorMessage ? <h6 className="text-center red">{aldeaErrorMessage}</h6>:null}
              {errorMessage ? <h6 className="text-center red">{errorMessage}</h6>:null}
            </div>
            <div className="form-group">
              <label className="small mb-1" htmlFor="inputCountry">Tipo Huerta</label>
              <select className="form-control" name="tipo_huerta" id="tipo_huerta" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.tipo_huerta}>
                <option value='' >--Seleccione</option>
                <option  value={TipoHuerta.Convencional}>{TipoHuerta.Convencional}</option>
                <option  value={TipoHuerta.BPA}>{TipoHuerta.BPA}</option>
                <option  value={TipoHuerta.Diversificada}>{TipoHuerta.Diversificada}</option>
                <option  value={TipoHuerta.Organico}>Orgánico</option>
              </select>
            </div>  
            <div className="form-group">
              <label className="small mb-1" htmlFor="inputCountry">Variedad</label>
              <select className="form-control" name="variedad" id="variedad"  onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.variedad}>
                <option  value=''>--seleccione--</option>
                <option value='CATURRA'>CATURRA</option><option value='CATUAI'>CATUAI</option><option  value='CATIMOR'>CATIMOR</option><option value='MEZCLA'>MEZCLA</option>
                <option value='BORBÓN'>BORBÓN</option><option value='ROBUSTA TROPICAL'>ROBUSTA TROPICAL</option><option value='PACHE COMUN'>PACHE COMUN</option><option value='PACAMARA'>PACAMARA</option>
                <option value='KETESIC'>KETESIC</option><option value='CUSTATECLO'>CUSCATECLO</option><option value='PACAS'>PACAS</option><option value='GEISHA'>GEISHA</option><option value='TYPICA'>TYPICA</option><option value='LEMPIRA'>LEMPIRA</option><option value='T8667'>T8667</option>
                <option value='OBATA'>OBATA</option><option value='TUPI'>TUPI</option><option value='ACAUA'>ACAUA</option><option value='IAAPAR'>IAAPAR</option><option value='PARAINEMA'>PARAINEMA</option><option value='CASTILLO'>CASTILLO</option><option value='BOURBÓN'>BOURBÓN</option><option value='ICATÚ'>ICATÚ</option>
                <option value='IHCAFE-90'>IHCAFE-90</option><option value='PAACHE'>PAACHE</option><option value='VILLA SARCHÍ'>VILLA SARCHÍ</option><option value='HÍBRIDO H-1'>HÍBRIDO H-1</option><option value='HÍBRIDO H-3'>HÍBRIDO H-3</option><option value='CR95'>CR95</option><option value='H27'>H27</option><option value='JAVA'>JAVA</option>
              </select>
            </div>
            <div className="form-group">
              <label className="small mb-1" htmlFor="inputCountry">Propietario</label>
              <select style={add && !validations.isValidPro() ? errorStyle:{}} className="form-control" name="propietario" id="propietario"  onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.propietario}>
                <option value=''>--seleccione--</option>
                {datosPropietarios.map((item:any)=>{
                return(<option  value={item.id}>{item.name}</option>)
                })}
                <option id='propNew' value='0'>--NUEVO--</option>
              </select>
              {proErrorMessage ? <h6 className="text-center red">{proErrorMessage}</h6>:null}
            </div>
            <div className="form-group" hidden={!inputProp} >
              <label htmlFor="propietario">Propietario</label>
              <input style={add && errorValidCampoPropietario || !validations.isValidPropietarioNew() ? errorStyle:{}} className="form-control " type="text" name="propietarioNew" id="propietarioNew" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.propietarioNew}/>
              {proErrorMessage ? <h6 className="text-center red">{proErrorMessage}</h6>:null}
              {errorMessagePropietario ? <h6 className="text-center red">{errorMessagePropietario}</h6>:null}
            </div>
            <div className="form-group">
              <label className="small mb-1" htmlFor="inputCountry">Productor</label>
              <select style={add && !validations.isValidProd() ? errorStyle:{}} className="form-control" name="productor" id="productor"  onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.productor}>
                <option  value=''>--seleccione--</option>
                {datosPropietarios.map((item:any)=>{
                return(<option  value={item.id}>{item.name}</option>)
                })}
                <option id='prodNew' value='0'>--NUEVO--</option>
              </select>
              {prodErrorMessage ? <h6 className="text-center red">{prodErrorMessage}</h6>:null}
            </div>
            <div className="form-group" hidden={!inputProd} >
              <label htmlFor="productor">Productor</label>
              <input style={add && errorValidCampoProductor || !validations.isValidProductorNew() ? errorStyle:{}} className="form-control " type="text" name="productorNew" id="productorNew" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.productorNew}/>
              {prodErrorMessage ? <h6 className="text-center red">{prodErrorMessage}</h6>:null}
              {errorMessageProductor ? <h6 className="text-center red">{errorMessageProductor}</h6>:null}
            </div>
            <div className="form-group">
              <label className="small mb-1" htmlFor="inputCountry">Sombra</label>
              <select style={add && !validations.isValidSom() ? errorStyle:{}} className="form-control" name="porcentaje_sombra" id="porcenaje_sombra"  onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.porcentaje_sombra}>
                <option value='' >--Seleccione</option>
                <option value='1'>SI</option>
                <option value='0'>NO</option>
              </select>
              {sombraErrorMessage ? <h6 className="text-center red">{sombraErrorMessage}</h6>:null}
            </div>
            <div className="form-group">
              <label className="small mb-1" htmlFor="inputCountry">Densidad(Árboles/Ha)</label>
              <input style={add && !validations.isValidDen() ? errorStyle:{}} className="form-control" type="number" name="densidad_arboles_ha" id="densidad_arboles_ha" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.densidad_arboles_ha}/>
              {denErrorMessage ? <h6 className="text-center red">{denErrorMessage}</h6>:null}
            </div>
            <div className="form-group">  
              <label className="small mb-1" htmlFor="inputCountry">Superficie(has)</label>
              <input style={add && !validations.isValidSu() ? errorStyle:{}} className="form-control" type="number" name="superficie" id="superficie" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.superficie}/>
              {suErrorMessage ? <h6 className="text-center red">{suErrorMessage}</h6>:null}
            </div>
            <div className="form-group"> 
              <label className="small mb-1" htmlFor="inputCountry">Altura(msnm)</label>
              <input className="form-control" type="number" name="altura" id="altura" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.altura}/>
            </div>
            <div className="form-group">  
              <label className="small mb-1" htmlFor="inputCountry">Año de Plantación</label>
              <input style={add && !validations.isValidAnio() ? errorStyle:{}} className="form-control" type="number" name="anio_plantacion" id="anio_plantacion" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.anio_plantacion}/>
              {anioErrorMessage ? <h6 className="text-center red">{anioErrorMessage}</h6>:null}
            </div>
            <div className="form-group">  
              <label className="small mb-1" htmlFor="inputCountry">Tipo de Café</label>
              <select className="form-control" name="tipo_cafe" id="tipo_cafe" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.tipo_cafe}>
                <option  value={TipoCafe.Arabico}>Arábico</option>
              </select>
            </div>
            {addErrorMessage ? <h6 className="text-center red">{addErrorMessage}</h6>:null}
            {finishValid ? <h6 className="text-center red">{finishValid}</h6>:null}
            <footer style={{paddingTop:'10px', marginLeft:'auto'}}>
              <button onChange={handleChange} disabled={!enable} onClick={peticionPut} className="btn btn-aceptar btn-block btn-sm" >
                Actualizar
              </button>{' '}
              <button className="btn btn-cancelar btn-block btn-sm" onClick={()=>abrirCerrarModalEditar()}>Cancelar</button>
            </footer>     
          </ModalBody>
        </div>
    )

  const bodyInsertar=(
    <div style={{ overflowY:'auto',overflowX:'hidden',position:'absolute', width:'470px', height:'600px', backgroundColor:'white', top:'50%',  left:'50%', transform: 'translate(-50%, -50%)', borderRadius:10}}>
      <ModalBody className="ml-3" style={{ overflowY:'auto',position:'absolute', backgroundColor:'white', width:'430px'}}>
      <div className="pt-0 float-right">
            <a 
                style={{cursor: 'pointer'}} 
                onClick={(e)=>{
                e.preventDefault();
                abrirCerrarModalInsertar();
                }}
              >
                <FontAwesomeIcon icon={ faTimes }/>
              </a>
        </div>
    <div className="form-group mt-4">
      <label  htmlFor="tipo_finca">Tipo Finca</label>
      <select className="form-control"  name="tipo_finca" id="tipo_finca" onChange={handleChange} >
        <option  value='' >--seleccione--</option>
        <option  value={TipoFinca.Centinela}>{TipoFinca.Centinela}</option>
        <option  value={TipoFinca.Demostrativa}>{TipoFinca.Demostrativa}</option>
        <option  value={TipoFinca.Exploracion}>Exploración</option>
      </select>
    </div>
    <div className="form-group">
      <label htmlFor="tecnico">Técnico</label>
      <select style={add && !validations.isValidTec() ? errorStyle:{}} className="form-control "  name="tecnico" id="tecnico" onChange={handleChange} >
        <option  value='' >--seleccione--</option>
        {datosTecnico.map((item:any)=>{
          return(<option  value={item.id}>{item.name}</option>)
        })}
      </select>
      {tecErrorMessage ? <h6 className="text-center red">{tecErrorMessage}</h6>:null}
    </div>
    <div className="form-group">
      <label htmlFor="nombre">Nombre Finca</label>
      <input className="form-control" type="text" name="nombre" id="nombre" onChange={handleChange} />
    </div>
    <div className="form-group">
      <label className="small mb-1"  htmlFor="inputCountry">Latitud</label>
      <input style={add && !validations.isValidLat() ? errorStyle:{}} className="form-control " type="number" name="latitud" id="latitud" onChange={handleChange} />          
      {latErrorMessage ? <h6 className="text-center red">{latErrorMessage}</h6>:null}
    </div>
    <div className="form-group">
      <label className="small mb-1" htmlFor="inputCountry">Longitud</label>
      <input style={add && !validations.isValidLong() ? errorStyle:{}} className="form-control " type="number" name="longitud" id="longitud" onChange={handleChange}  />
      {longErrorMessage ? <h6 className="text-center red">{longErrorMessage}</h6>:null}
    </div>
    <div className="form-group">
      <label className="small mb-1" htmlFor="Area">Área(has)</label>
      <input style={add && !validations.isValidArea() ? errorStyle:{}} className="form-control " type="number" name="area" id="area" onChange={handleChange}  />
      {areaErrorMessage ? <h6 className="text-center red">{areaErrorMessage}</h6>:null}
    </div>
    <div className="form-group">
      <label className="small mb-1" htmlFor="lote">Lote</label>
      <input className="form-control " type="text" name="lote" id="lote" onChange={handleChange}  />
    </div>
    <div className="form-group">
      <label htmlFor="departamento">Departamento</label>
      <select className="form-control"  name="departamento" id="departamento" onChange={handleChange} >
        <option  value=''>--seleccione--</option>
        {datosDepto.map((item:any)=>{
          return(<option  value={item.id}>{item.nombre}</option>)
        })}
      </select>
    </div>
    <div className="form-group" id='mun' >
      <label htmlFor="municipio">Municipio</label>
      <select style={add && !validations.isValidMun() ? errorStyle:{}} className="form-control"  name="municipio" id="municipio" onChange={handleChange} disabled={!enabledMunicipio}>
        <option  value=''>--seleccione--</option>
        {datosMunicipios.map((item:any)=>{
        return(<option  value={item.id}>{item.nombre}</option>)
        })}
        <option id='newmuni' value='0'>--NUEVO--</option>
      </select>
      {munErrorMessage ? <h6 className="text-center red">{munErrorMessage}</h6>:null}
    </div>
    <div className="form-group" hidden={!hide} >
      <label htmlFor="municipio">Municipio</label>
      <input style={add && !validations.isValidMunNew()|| errorValidCampoMunicipio ? errorStyle:{}} className="form-control " type="text" name="municipioNew" id="municipioNew" onChange={handleChange} />
      {munErrorMessage ? <h6 className="text-center red">{munErrorMessage}</h6>:null}
      {errorMessageMunicipio ? <h6 className="text-center red">{errorMessageMunicipio}</h6>:null}
    </div>
    <div className="form-group" hidden={!ocultar}>
      <label htmlFor="aldea">Aldea</label>
      <select style={add && !validations.isValidAldea_n() ? errorStyle:{}} className="form-control"  name="aldea_n" id="aldea_n" onChange={handleChange} disabled={!enabledAldea_n}>
        <option  value=''>--seleccione--</option>
        {datosAldea.map((item:any)=>{
        return(<option  value={item.id}>{item.nombre}</option>)
        })}
        <option id='aldeaNew' value='0'>--NUEVO--</option>
      </select>
      {aldea_nErrorMessage ? <h6 className="text-center red">{aldea_nErrorMessage}</h6>:null}
    </div>
    <div className="form-group" hidden={!showAldea} >
      <label htmlFor="aldea">Aldea</label>
      <input style={add && !validations.isValidAldea() || !validations.isValidAldeaNew() || errorValidCampo ? errorStyle:{}} className="form-control " type="text" name="aldeaNew" id="aldeaNew" onChange={handleChange} />
      {aldeaErrorMessage ? <h6 className="text-center red">{aldeaErrorMessage}</h6>:null}
      {errorMessage ? <h6 className="text-center red">{errorMessage}</h6>:null}
    </div>
    <div className="form-group">
      <label className="small mb-1" htmlFor="inputCountry">Tipo Huerta</label>
      <select className="form-control" name="tipo_huerta" id="tipo_huerta" onChange={handleChange} >
        <option value='' >--Seleccione</option>
        <option  value={TipoHuerta.Convencional}>{TipoHuerta.Convencional}</option>
        <option  value={TipoHuerta.BPA}>{TipoHuerta.BPA}</option>
        <option  value={TipoHuerta.Diversificada}>{TipoHuerta.Diversificada}</option>
        <option  value={TipoHuerta.Organico}>{TipoHuerta.Organico}</option>
      </select>
    </div>
    <div className="form-group">
      <label className="small mb-1" htmlFor="inputCountry">Variedad</label>
      <select className="form-control" name="variedad" id="variedad"  onChange={handleChange} >
        <option  value='' >--seleccione--</option>
        <option value='CATURRA'>CATURRA</option><option value='CATUAI'>CATUAI</option><option  value='CATIMOR'>CATIMOR</option><option value='MEZCLA'>MEZCLA</option>
        <option value='BORBÓN'>BORBÓN</option><option value='ROBUSTA TROPICAL'>ROBUSTA TROPICAL</option><option value='PACHE COMUN'>PACHE COMUN</option><option value='PACAMARA'>PACAMARA</option>
        <option value='KETESIC'>KETESIC</option><option value='CUSTATECLO'>CUSCATECLO</option><option value='PACAS'>PACAS</option><option value='GEISHA'>GEISHA</option><option value='TYPICA'>TYPICA</option><option value='LEMPIRA'>LEMPIRA</option><option value='T8667'>T8667</option>
        <option value='OBATA'>OBATA</option><option value='TUPI'>TUPI</option><option value='ACAUA'>ACAUA</option><option value='IAAPAR'>IAAPAR</option><option value='PARAINEMA'>PARAINEMA</option><option value='CASTILLO'>CASTILLO</option><option value='BOURBÓN'>BOURBÓN</option><option value='ICATÚ'>ICATÚ</option>
        <option value='IHCAFE-90'>IHCAFE-90</option><option value='PAACHE'>PAACHE</option><option value='VILLA SARCHÍ'>VILLA SARCHÍ</option><option value='HÍBRIDO H-1'>HÍBRIDO H-1</option><option value='HÍBRIDO H-3'>HÍBRIDO H-3</option><option value='CR95'>CR95</option><option value='H27'>H27</option><option value='JAVA'>JAVA</option>
      </select>
    </div>
    <div className="form-group">
      <label className="small mb-1" htmlFor="inputCountry">Propietario</label>
      <select style={add && !validations.isValidPro() ? errorStyle:{}} className="form-control" name="propietario" id="propietario"  onChange={handleChange}>
        <option  value=''>--seleccione--</option>
        {datosPropietarios.map((item:any)=>{
          return(<option  value={item.id}>{item.name}</option>)
        })}
        <option id='propNew' value='0'>--NUEVO--</option>
      </select>
      {proErrorMessage ? <h6 className="text-center red">{proErrorMessage}</h6>:null}
    </div>
    <div className="form-group" hidden={!inputProp} >
        <label htmlFor="propietario">Propietario</label>
        <input style={add && errorValidCampoPropietario || !validations.isValidPropietarioNew() ? errorStyle:{}} className="form-control " type="text" name="propietarioNew" id="propietarioNew" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.propietarioNew}/>
        {proErrorMessage ? <h6 className="text-center red">{proErrorMessage}</h6>:null}
        {errorMessagePropietario ? <h6 className="text-center red">{errorMessagePropietario}</h6>:null}
    </div>
    <div className="form-group">
      <label className="small mb-1" htmlFor="inputCountry">Productor</label>
      <select style={add && !validations.isValidProd() ? errorStyle:{}} className="form-control" name="productor" id="productor"  onChange={handleChange} >
        <option  value=''>--seleccione--</option>
        {datosPropietarios.map((item:any)=>{
          return(<option  value={item.id}>{item.name}</option>)
        })}
        <option id='prodNew' value='0'>--NUEVO--</option>
      </select>
      {prodErrorMessage ? <h6 className="text-center red">{prodErrorMessage}</h6>:null}
    </div>
    <div className="form-group" hidden={!inputProd} >
        <label htmlFor="productor">Productor</label>
        <input style={add && errorValidCampoProductor || !validations.isValidProductorNew() ? errorStyle:{}} className="form-control " type="text" name="productorNew" id="productorNew" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.productorNew}/>
        {prodErrorMessage ? <h6 className="text-center red">{prodErrorMessage}</h6>:null}
        {errorMessageProductor ? <h6 className="text-center red">{errorMessageProductor}</h6>:null}
    </div>
    <div className="form-group">
      <label className="small mb-1" htmlFor="inputCountry">Sombra</label>
      <select style={add && !validations.isValidSom() ? errorStyle:{}} className="form-control" name="porcentaje_sombra" id="porcenaje_sombra"  onChange={handleChange} >
       <option value='' >--Seleccione</option>
       <option value='1'>SI</option>
       <option value='0'>NO</option>
     </select>
     {sombraErrorMessage ? <h6 className="text-center red">{sombraErrorMessage}</h6>:null}
    </div>
    <div className="form-group">
       <label className="small mb-1" htmlFor="inputCountry">Densidad(Árboles/Ha)</label>
       <input style={add && !validations.isValidDen() ? errorStyle:{}} className="form-control" type="number" name="densidad_arboles_ha" id="densidad_arboles_ha" onChange={handleChange} />
       {denErrorMessage ? <h6 className="text-center red">{denErrorMessage}</h6>:null}
    </div>   
    <div className="form-group">
       <label className="small mb-1" htmlFor="inputCountry">Superficie(has)</label>
       <input style={add && !validations.isValidSu() ? errorStyle:{}} className="form-control" type="number" name="superficie" id="superficie" onChange={handleChange} />
       {suErrorMessage ? <h6 className="text-center red">{suErrorMessage}</h6>:null}
    </div>   
    <div className="form-group">
       <label className="small mb-1" htmlFor="inputCountry">Altura(msnm)</label>
       <input style={add && !validations.isValidAlt() ? errorStyle:{}} className="form-control" type="number" name="altura" id="altura" onChange={handleChange} />
       {altErrorMessage ? <h6 className="text-center red">{altErrorMessage}</h6>:null}
    </div>   
    <div className="form-group">
      <label className="small mb-1" htmlFor="inputCountry">Año de Plantación</label>
      <input style={add && !validations.isValidAnio() ? errorStyle:{}} className="form-control" type="number" name="anio_plantacion" id="anio_plantacion" onChange={handleChange} />
      {anioErrorMessage ? <h6 className="text-center red">{anioErrorMessage}</h6>:null}
    </div> 
    <div className="form-group">  
      <label className="small mb-1" htmlFor="inputCountry">Tipo de Café</label>
      <select className="form-control" name="tipo_cafe" id="tipo_cafe" onChange={handleChange} >
        <option  value={TipoCafe.Arabico}>Arábico</option>
      </select>
    </div>
    <div className="form-group">
      <label className="small mb-1" htmlFor="inputCountry">Institución</label>
      <select style={add && !validations.isValidIns() ? errorStyle:{}} className="form-control" name="institucion" id="institucion" onChange={handleChange} >
         <option value='0'>--Seleccione--</option>
         {datosInstitucion.map((item:any)=>{
          return(<option  value={item.id}>{item.nombre}</option>)
        })}
      </select>
      {insErrorMessage ? <h6 className="text-center red">{insErrorMessage}</h6>:null}
    </div>
    {finishValid ? <h6 className="text-center red">{finishValid}</h6>:null}
    {addErrorMessage ? <h6 className="text-center red">{addErrorMessage}</h6>:null}
    <footer style={{paddingTop:'10px', marginLeft:'auto'}}>
      <button onChange={handleChange} disabled={!enable} onClick={peticionAdd} className="btn btn-aceptar btn-block btn-sm" >Agregar</button>
      <button className="btn btn-cancelar btn-block btn-sm" onClick={()=>abrirCerrarModalInsertar()}>Cancelar</button>
    </footer>     
     </ModalBody>
  </div>
      )
      
  if(loadingFincas && firstLoad){
    return(
      <PageLoader section="Administración" title="Padrón de Fincas"></PageLoader>
    );
  }
  if(errorFincas){
    return (
      <PageError section="Reportes" title="Padrón de Fincas"></PageError>
    )
  }
  return (
    <BasicLayout>
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><a href="homepage.html">Inicio</a></li>
            <li className="breadcrumb-item active">Administración</li>
          </ol>
          <div className="row">
            <h1 className="col-lg-6">Padrón de Fincas</h1>
            <div className="col-lg-2"><a className="btn btn-primary btn-sm mb-4" onClick={()=>abrirCerrarModalInsertar()}>Agregar Finca</a></div>
            <div style={{marginLeft: 'auto'}} className="col-lg-2"><a className={!loadingExport? "btn btn-primary btn-sm mb-4": "btn btn-primary btn-sm mb-4 disabled"} href="#" onClick={(e)=>{e.preventDefault();setButtonPushed(true); exportFincas();}} download>{!loadingExport ? 'Exportar Institución': 'Exportando Institución'}</a></div>
            <div className="col-lg-2">
              <Link 
                className="btn btn-primary btn-sm mb-4" 
                to="mapa-fincas">Mapa
              </Link>
            </div>
          </div>
          <div className="card mb-4 table-responsive">
            <div className="card-body">
              <div className="row">
                  <form className="form-inline mb-3 col-sm-12 col-md-6 ml-auto">
                    <div className="form-group ml-md-auto">
                      <label htmlFor="searchBox" className="small pr-2">Buscar:</label>                  
                      <input 
                        type="search" 
                        className="form-control" 
                        id="searchBox"
                        value={parametro}
                        onChange={(e)=>{
                          setSkip(0);
                          setCurrentPage(0);
                          setParametro(e.target.value)
                        }
                        }
                      />                  
                    </div>
                  </form>
              </div>
              <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th>FINCA</th>
                    <th>NOMBRE</th>
                    <th>DEPARTAMENTO</th>
                    <th>MUNICIPIO	</th>
                    <th>ALDEA </th>
                    <th>TÉCNICO</th>
                    <th></th>
                  </tr>
                </thead>
                {(!loadingFincas && !firstLoad)?
                <tbody>
                {
                (!datos.length && !firstLoad && parametro!=="") ? 
                <td colSpan={7} className="text-center">
                  No hay resultados para "{parametro}"
                </td>
                :
                datos.map((item:any, tec:any, ald:any)=>{
                  let mun:any, dep:any;
                  if(item.tecnico===null){
                    tec = '';
                  }if(item.tecnico!=null){
                    tec=item.tecnico.name;
                  }
                  if(item.aldea_n===null){
                    ald='';
                  }
                  if(item.aldea_n!==null){
                    ald=item.aldea_n.nombre;
                    mun=item.aldea_n.municipios.nombre;
                    dep=item.aldea_n.municipios.departamento.nombre;
                  }    
                  return(
                  <tr key={item.id}>
                    <td>{item.tipo_finca}</td>
                    <td>{item.nombre}</td>
                    <td>{dep}</td>
                    <td>{mun}</td>
                    <td>{ald}</td>
                    <td>{tec}</td>
                    <td>
                      <div className="row" style={{flexFlow:'row'}}>
                      <div className="col-lg-6"><button className="btn table-small-btn" onClick={()=>editarFinca(item, 'Editar')} ><FontAwesomeIcon icon={faPencilAlt}/></button></div>
                      <div className="col-lg-6"><button className="btn table-small-btn" onClick={() => deleteFincaSeleccionada(item)}><FontAwesomeIcon icon={faTrashAlt}/></button></div>
                      </div>
                    </td>
                  </tr>
                )})}
                </tbody>
                :
                <tbody>
                  <tr>
                    <td colSpan={7} className="text-center">
                      <img src={PreLoaderImage} alt="loading" style={{ height: '100px'}}/>
                    </td>
                  </tr>
                </tbody>
                }
              </table>
            </div>
            <div className="row" >
              <div className="col-sm-12 col-md-5 col-lg-6">
                  <p>Mostrando registros del {skip+1} al {registerCount()} de un total de {count} registros</p>
              </div>
              <div className="col-sm-12 col-md-7 col-lg-6">
              <ReactPaginate
                previousLabel={"Anterior"}
                nextLabel={"Siguiente"}
                breakLabel={<span className="mx-2">...</span>}
                pageCount={Math.ceil(count/take)}
                onPageChange={handlePageClick}
                forcePage={currentPage}
                containerClassName={"pagination justify-content-end mr-3"}
                pageClassName={"paginate_button page-item"}
                previousClassName={"paginate_button page-item previous"}
                previousLinkClassName={"page-link"}
                nextClassName={"paginate_button page-item next"}
                nextLinkClassName={"page-link"}
                disabledClassName={"disabled"}
                activeClassName={"active"}
                activeLinkClassName={"page-link"}
                pageRangeDisplayed={2}
                pageLinkClassName={"page-link"}
                marginPagesDisplayed={2}
              />    
              </div>
          </div>
          </div>
        </div>
      </main>
      <div >
      </div>
      <Modal open={modalEliminar} onClose={abrirCerrarModalEliminar}>{bodyEliminar()}</Modal>
      <Modal open={modalInsertar} onClose={abrirCerrarModalInsertar}>{bodyInsertar}</Modal>
      <Modal open={modalEditar} onClose={abrirCerrarModalEditar} style={{overflowY:'scroll'}}>{bodyEditar}</Modal>
    </BasicLayout>
  )
};

export default PadronFincas;