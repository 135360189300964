import PreLoaderImage from 'src/assets/images/Preloader.gif';
import BasicLayout from 'src/layout/Basic';

interface PageLoaderProps {
    section: string
    title: string
}

const PageLoader = (props: PageLoaderProps) => {
    return (
        <BasicLayout>
          <main>
            <div className="container-fluid">
              <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item"><a href="homepage.html">Inicio</a></li>
                <li className="breadcrumb-item active">{props.section}</li>
              </ol>
              <div className="row">
                <h1 className="col-lg-6">{props.title}</h1>
              </div>
              <div className="card mb-4 table-responsive">
                <div className="card-body">
                  <div style={{textAlign: 'center'}}>
                    <img src={PreLoaderImage} alt="loading" style={{ height: '200px'}}/>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </BasicLayout>
    );
}

export default PageLoader;