import { useState } from "react";
import { loginVariables } from "src/apollo/queries/types/login";
import { NavLink } from 'react-router-dom';

interface LoginFormProps {
  error: string;
  onSubmit(user: loginVariables): void;
  userInformation: loginVariables
}
const LoginForm = (props: LoginFormProps) => {
  const [userName, setUserName] = useState(props.userInformation.userName);
  const [password, setPassword] = useState(props.userInformation.password);
  const [submited, setSubmited] = useState(false);
  const [keepSession, setKeepSession] = useState(false);
  let passwordErrorMessage = '';
  let userNameErrorMessage = '';
  const errorStyle = {
    borderColor: 'red',
  }

  const validations = {
    isValidUserName: () => {
      if(userName === ''){
        userNameErrorMessage = 'El usuario no puede ir vacío';
        return false;
      }
      return true;
    },
    isValidPassword : () => {
      if(password === ''){
        passwordErrorMessage = 'La contraseña no puede ir vacía';
        return false;
      }
      return true;
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmited(true);
    if(validations.isValidUserName() && validations.isValidPassword()){
      props.onSubmit({
        userName,
        password
      });
    }
  }
  return (
    <>
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-4">
            <label className="small mb-1" htmlFor="inputUsername">Nombre de Usuario</label>
            <input 
              className="form-control py-4" 
              id="inputUsername" 
              type="text" 
              value={userName}
              onChange={(event)=>{setUserName(event.target.value)}}
              placeholder="Ingresar Nombre de Usuario"
              style={submited && !validations.isValidUserName() ? errorStyle : {}} />
            {
              userNameErrorMessage ? 
              <h4 className="text-center red" >
                {userNameErrorMessage}
              </h4>
              : null
            }
          </div>
          <div className="form-group mb-4">
            <label className="small mb-1" htmlFor="inputPassword">Contraseña</label>
            <input 
              className="form-control py-4" 
              id="inputPassword" 
              type="password" 
              value={password}
              onChange={(event)=> setPassword(event.target.value)}
              placeholder="Ingresar Contraseña"
              style={submited && !validations.isValidPassword() ? errorStyle : {}} />
            {
              passwordErrorMessage ? 
              <h4 className="text-center red" >
                {passwordErrorMessage}
              </h4>
              : null
            }
          </div>
          {/* <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input 
                type="checkbox"
                className="custom-control-input" 
                id="rememberPasswordCheck" 
                defaultChecked={keepSession}
                onChange={() => setKeepSession(!keepSession)}
              />
              <label className="custom-control-label" htmlFor="rememberPasswordCheck">Recordarme</label>
            </div>
          </div> */}
          <div className="mt-1 mb-0">
            <NavLink to="/forgot">
              <h4 className="text-right font-weight-extrabold" style={{color: '#333'}}>¿Olvidaste tu contraseña?</h4>
            </NavLink>
          </div>
          <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
            <button className="btn btn-primary btn-lg btn-block">Ingresar</button>
          </div>
          {props.error ? 
            <div className="mt-4 mb-0">
              <h4 className="text-center font-weight-extrabold red" >{props.error}</h4>
            </div>
          : null
          }
        </form>
      </div>
    </>
  )
}

export default LoginForm;