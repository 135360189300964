import BasicLayout from 'src/layout/Basic';
import ExportButton from 'src/components/ExportButton';
import FILTER_USERFINCAS from 'src/apollo/queries/filterUserFincas';
import { Component, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import { useQuery, gql } from '@apollo/client';
import {Modal} from '@material-ui/core';
import { isTypeNode } from 'graphql';
import UPDATE_FINCA from 'src/apollo/queries/updateFinca';
import { useMutation } from '@apollo/client';
import client from 'src/apollo';
import { update_finca, update_fincaVariables } from 'src/apollo/queries/types/update_finca';
import { TipoFinca } from 'src/apollo/types/graphql-global-types';
import { TipoHuerta } from 'src/apollo/types/graphql-global-types';
import { TipoCafe } from 'src/apollo/types/graphql-global-types';
import {ModalBody, ModalHeader} from 'reactstrap';
import GET_USER_TECNICO from 'src/apollo/queries/getUserTecnico'
import GET_DEPARTAMENTOS from 'src/apollo/queries/getDepartamento'
import GET_MUNICIPIOS from 'src/apollo/queries/getMunicipios'
import GET_ALDEAS from 'src/apollo/queries/getAldeas'
import { useLazyQuery } from '@apollo/client';
import GET_PROPIETARIOS from 'src/apollo/queries/getPropietarios';
import ADD_FINCA from 'src/apollo/queries/addFinca'
import { add_finca, add_fincaVariables } from 'src/apollo/queries/types/add_finca';
import { Add_municipio, Add_municipioVariables } from 'src/apollo/queries/types/Add_municipio';
import ADD_MUNICIPIO from 'src/apollo/queries/addMunicipio';
import { Add_Aldea, Add_AldeaVariables } from 'src/apollo/queries/types/Add_Aldea';
import ADD_ALDEA from 'src/apollo/queries/addAldea';
import DELETE_FINCA from 'src/apollo/queries/deleteFinca';
import PageLoader from 'src/components/PageLoader';
import FIND_INSTITUCION from 'src/apollo/queries/findInstitucion'
import { Link } from 'react-router-dom';
import PreLoaderImage from 'src/assets/images/Preloader.gif';
import FIND_ALL_FINCAS from '../../apollo/queries/findAllFincas';
import XLSX from 'xlsx';
import ReactPaginate from 'react-paginate';
import COUNT_FINCAS from '../../apollo/queries/types/countFincas';
import { SettingsCellOutlined } from '@material-ui/icons';
import { start } from 'repl';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { add_propietario, add_propietarioVariables } from 'src/apollo/queries/types/add_propietario';
import ADD_PROPIETARIO from 'src/apollo/queries/addPropietario';
import { type } from 'os';
import PageError from 'src/components/PageError';

const FincasTecnico = () => {
  const [datos, setDatos] = useState([]);
  const [parametro, setParametro] = useState('');
  const [datosTecnico,setDatosTecnico]=useState([]);
  const [datosPropietarios,setDatosPropietarios]=useState([]);
  const [datosDepto,setDatosDepto]=useState([]);
  const [datosAldea,setDatosAldea]=useState([]);
  const [datosUser,setUser]=useState([]);
  const [datosMunicipios, setMunicipios]=useState([]);
  const [datosInstitucion, setDatosInstitucion]= useState([]);
  const [modalNavegar, setModalNavegar]=useState(false);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [enable, setEnable] = useState(false);
  const [inputProp, setInputProp] = useState(false);
  const [inputProd, setInputProd] = useState(false);
  const [enabledMunicipio, setEnabledMunicipio] = useState(false);
  const [enabledAldea_n, setEnabledAldea_n] = useState(false);
  const [hide, setHide] = useState(false);
  const [ocultar, setOcultar]=useState(true);
  const [showAldea,setShowAldea]=useState(false);
  const [showButton, setShowButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageMunicipio, setErrorMessageMunicipio] = useState('');
  const [errorMessagePropietario, setErrorMessagePropietario] = useState('');
  const [errorMessageProductor, setErrorMessageProductor] = useState('');
  const [finishValid, setFinishValid] = useState('');
  const [errorValidCampo, setValidCampo] = useState(false);
  const [errorValidCampoPropietario, setValidCampoPropietario] = useState(false);
  const [errorValidCampoMunicipio, setValidCampoMunicipio] = useState(false);
  const [errorValidCampoProductor, setValidCampoProductor] = useState(false);
  const [handledValidation, setHandledValidation] = useState(true);
  const [add, setAdd]=useState(false);
  const fincaInit ={
    id:'',
    tipo_finca: TipoFinca.Centinela,
    tecnico:'',
    nombre: '',
    latitud: '',
    longitud:'',
    departamento:'',
    municipio: '',
    municipioNew:'',
    aldea_n:'',
    aldeaNew:'',
    area:'',
    lote:'',
    tipo_huerta:TipoHuerta.Convencional,
    variedad:'',
    porcentaje_sombra:'',
    densidad_arboles_ha:'',
    superficie:'',
    altura:'',
    anio_plantacion:'',
    tipo_cafe:TipoCafe.Arabico,
    propietario:'',
    propietarioNew:'',
    productor:'',
    productorNew:'',
    institucion:''
  }
  const [fincaSeleccionada, setFincaSeleccionada]=useState(fincaInit);
  let munErrorMessage; let latErrorMessage; let longErrorMessage; let suErrorMessage;
  let areaErrorMessage; let sombraErrorMessage; let denErrorMessage; let altErrorMessage;
  let anioErrorMessage; let prodErrorMessage; let proErrorMessage; let insErrorMessage;
  let addErrorMessage; let aldeaErrorMessage; let aldea_nErrorMessage;
  let finishMessage='Asegúrese de que todos los campos estén ingresados de forma correcta';
  const errorStyle={
    borderColor: 'red'
  }
 
  const { loading:loadingFincasTecnico, error:errorFincasTecnico, data:dataFincasTecnico}= useQuery(FILTER_USERFINCAS, {variables: {parametro: parametro}}); 
  const { loading:loadingPropietarios, error:errorPropietarios, data:dataPropietarios}= useQuery(GET_PROPIETARIOS); 
  const {loading:loadingTecnico, error:errorTecnico, data:dataTecnico}=useQuery(GET_USER_TECNICO);
  const{loading:loadingDepto, error:errorDepto, data:dataDepto}=useQuery(GET_DEPARTAMENTOS);
  const [buttonPushed, setButtonPushed] = useState(false);
  const [findAldeas,{loading:loadingAldeas, error:errorAldeas, data:dataAldeas}]=useLazyQuery(GET_ALDEAS,{
    onCompleted(dataAldeas){
      setDatosAldea(dataAldeas)
     }
   });

  const [findMunicipios,{loading:loadingMunicipio, error:errorMunicipio, data:dataMunicipio}]=useLazyQuery(GET_MUNICIPIOS,{
   onCompleted(dataMunicipio){
     setMunicipios(dataMunicipio.find_Municipios)
    }
  });

  const{loading:loadingInstitucion, error:errorInstitucion, data:dataInstitucion}=useQuery(FIND_INSTITUCION);

  const [deleteFinca, {error:errorDelete, loading:loadinDelete}]= useMutation(DELETE_FINCA,{variables:{id:parseFloat(fincaSeleccionada.id)}})

  const [addFinca,{error:errorAdd, loading:loadingAdd}]= useMutation<add_finca,add_fincaVariables>(ADD_FINCA,
  {
    update(){
      client.resetStore();
    }
  })

  const [addAldea,{error:errorAddAldea, loading: loadingAddAldea, data: dataNewAldea}]=useMutation<Add_Aldea, Add_AldeaVariables>(
    ADD_ALDEA )

  const [addMunicipio,{error: errorAddMun, loading: loadingAddMun,data: dataNewMuni }]=useMutation<Add_municipio, Add_municipioVariables>(
    ADD_MUNICIPIO)

  const [addPropieario,{error: errorAddProp, loading: loadingAddProp,data: dataNewProp }]=useMutation<add_propietario, add_propietarioVariables>(
    ADD_PROPIETARIO)

  const [updateFinca, { error: errorUpdate, loading: loadingUpdate }] = useMutation<update_finca, update_fincaVariables>(
    UPDATE_FINCA, {
      update() {
        client.resetStore();
      },
    }
  )

  useEffect(()=>{
    if(!loadingTecnico && dataTecnico && dataTecnico.users){
      setDatosTecnico(dataTecnico.users);}
    },[dataTecnico,loadingTecnico]);

    useEffect(()=>{
      if(!loadingInstitucion && dataInstitucion && dataInstitucion.findAllInstitucion){
        setDatosInstitucion(dataInstitucion.findAllInstitucion);
      }
    },[dataInstitucion,loadingInstitucion]);
  

  useEffect(()=>{
    if(!loadingPropietarios && dataPropietarios && dataPropietarios.propietarios){
      setDatosPropietarios(dataPropietarios.propietarios);}
  },[dataPropietarios,loadingPropietarios]);
   
  useEffect(()=>{
    if(!loadingDepto && dataDepto && dataDepto.find_departamentos){
      setDatosDepto(dataDepto.find_departamentos);
    }
  },[dataDepto,loadingDepto]);

  useEffect(()=>{
    if(parametro==='' && !firstLoad){
      client.resetStore();
    }
  },[parametro]);
 
  useEffect(()=>{
    if(!loadingMunicipio && dataMunicipio && dataMunicipio.find_municipios){
      setMunicipios(dataMunicipio.find_municipios);
    }
  },[dataMunicipio,loadingMunicipio]);

  useEffect(()=>{
    if(!loadingAldeas && dataAldeas && dataAldeas.find_Aldeas){
      setDatosAldea(dataAldeas.find_Aldeas);
    }
  },[dataAldeas,loadingAldeas]);

  useEffect(() => {
    if (!loadingFincasTecnico && dataFincasTecnico && dataFincasTecnico.findUserFincas.user.fincas) {
      setDatos(dataFincasTecnico.findUserFincas.user.fincas);
      setUser(dataFincasTecnico.findUserFincas.user.name);
      setFirstLoad(false);
    }
  }, [dataFincasTecnico, loadingFincasTecnico]);

  const validations = {
    isValidMun: () =>{
      if(fincaSeleccionada.municipio===''){
        munErrorMessage = 'Municipio no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }return true;
    }, isValidMunNew: () =>{
      if(fincaSeleccionada.municipio==='0' && fincaSeleccionada.municipioNew===''){
        munErrorMessage = 'Municipio no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }return true;
    }, isValidAldea_n: () =>{
      if(fincaSeleccionada.municipio!=='0' && fincaSeleccionada.aldea_n===''){
        aldea_nErrorMessage = 'Aldea no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }return true;
    },isValidAldea: () =>{
      if(fincaSeleccionada.aldea_n==='0' &&fincaSeleccionada.aldeaNew===''){
        aldeaErrorMessage = 'Aldea no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }return true;
    },isValidAldeaNew: () =>{
      if(fincaSeleccionada.municipio==='0' &&fincaSeleccionada.aldeaNew===''){
        aldeaErrorMessage = 'Aldea no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }return true;
    },
    isValidLat: () =>{
      if(fincaSeleccionada.latitud===''){
        latErrorMessage = 'Latitud no puede ir vacío';
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      } return true;
    },isValidLong: () =>{
      if(fincaSeleccionada.longitud===''){
        longErrorMessage = 'Longitud no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    }, isValidArea: () =>{
      if(fincaSeleccionada.area===''){
        areaErrorMessage = 'Área no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }return true;
    },isValidSom: () =>{
      if(fincaSeleccionada.porcentaje_sombra===''){
        sombraErrorMessage = 'Sombra no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    },isValidDen: () =>{
      if(fincaSeleccionada.densidad_arboles_ha===''){
        denErrorMessage = 'Densidad no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    },isValidSu: () =>{
      if(fincaSeleccionada.superficie===''){
        suErrorMessage = 'Superficie no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    },isValidAlt: () =>{
      if(fincaSeleccionada.altura===''){
        altErrorMessage = 'Altura no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    },isValidAnio: () =>{
      if(fincaSeleccionada.anio_plantacion===''){
        anioErrorMessage = 'Plantación no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    },isValidPro: () =>{
      if(fincaSeleccionada.propietario===''){
        proErrorMessage = 'Propietario no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    },isValidPropietarioNew: () =>{
      if(fincaSeleccionada.propietario==='0' && fincaSeleccionada.propietarioNew===''){
        proErrorMessage = 'Propietario no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    },isValidProd: () =>{
      if(fincaSeleccionada.productor===''){
        prodErrorMessage = 'Productor no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    },isValidProductorNew: () =>{
      if(fincaSeleccionada.productor==='' && fincaSeleccionada.productorNew==='0'){
        proErrorMessage = 'Propietario no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    },isValidIns: () =>{
      if(fincaSeleccionada.institucion===''){
        insErrorMessage = 'Institución no puede ir vacío'
        addErrorMessage = 'Debe llenar todos los campos'
        return false;
      }
      return true;
    }
    
  }

  if (loadingUpdate) {
    return  <PageLoader section="Reportes" title="Padrón de Fincas"></PageLoader>
  }
  if (errorUpdate) {
    return (
      <PageError section="Reportes" title="Padrón de Fincas"></PageError>
    )
  }
 
  const peticionPropietario=async()=>{
    try{ 
      if(fincaSeleccionada.propietarioNew){
      const responsePropietario = await addPropieario({
        variables:{
          name: fincaSeleccionada.propietarioNew,
          family_name: fincaSeleccionada.propietarioNew
        }
      }); setHandledValidation(true);
      return responsePropietario
    }
    }catch (err) {
      err?.graphQLErrors?.map((item: any) => {
        if (item.handled) {
            setErrorMessagePropietario(item.message);
            setFinishValid(finishMessage);
            setValidCampoPropietario(true);
            setHandledValidation(false);
            throw "el propietario ya existe";
        }else{
          return(
            <PageError section="Reportes" title="Padrón de Fincas"></PageError>
          )
        }
    });
    console.log('err', err); 
  }}

  const peticionProductor=async()=>{
    try{ if(fincaSeleccionada.productorNew){
      const res = await addPropieario({
        variables:{
          name: fincaSeleccionada.productorNew,
          family_name: fincaSeleccionada.productorNew
        }
      }); return res;
    }
    }catch (err) {
      err?.graphQLErrors?.map((item: any) => {
        if (item.handled) {
            setErrorMessageProductor('El nombre del productor ya existe');
            setFinishValid(finishMessage);
            setValidCampoProductor(true);
            setHandledValidation(false);
            throw "el productor ya existe";
        }else{
          return(
            <PageError section="Reportes" title="Padrón de Fincas"></PageError>
            )
        }
    });
      console.log('err', err);
  }}

  const peticionMunicipio=async()=>{
    try{ if(fincaSeleccionada.municipioNew){
      const resp = await addMunicipio({
        variables:{
          nombre: fincaSeleccionada.municipioNew,
          departamento: parseInt(fincaSeleccionada.departamento)
        }
      }); return resp;
    }
    }catch (err) {
      err?.graphQLErrors?.map((item: any) => {
        if (item.handled) {
          setErrorMessageMunicipio(item.message);
          setFinishValid(finishMessage);
          setValidCampoMunicipio(true);
          setHandledValidation(false);
          throw "El Municipio ya existe";
      }else{
        return(
          <PageError section="Reportes" title="Padrón de Fincas"></PageError>
          )
      } 
      });
      console.log('err', err);
  }}

  const peticionAldea=async()=>{
    const responseMunicipio = await peticionMunicipio();
    let response;
    try {
        if (fincaSeleccionada.aldeaNew ) {
          if(fincaSeleccionada.aldeaNew || (handledValidation || responseMunicipio?.data?.Add_municipio.id)){
            response = await addAldea({
                variables: {
                    nombre: fincaSeleccionada.aldeaNew,
                    municipio: responseMunicipio?.data?.Add_municipio.id || parseInt(fincaSeleccionada.municipio)
                }
            });
            return response;
        }
    }} catch (err) {
        err?.graphQLErrors?.map((item: any) => {
          if (item.handled) {
            setErrorMessage(item.message);
            setFinishValid(finishMessage);
            setValidCampo(true);
            setHandledValidation(false);
            throw "La aldea ya existe";
        }else{
          return(
            <PageError section="Reportes" title="Padrón de Fincas"></PageError>
          )
        }
        });
        console.log('err',err);
    }
  }
  const peticionPut = async () => {
    setAdd(true);
    try {
    if(validations.isValidLat() && validations.isValidLong() && validations.isValidArea() &&
      validations.isValidSom()&& validations.isValidDen()&& validations.isValidSu()&&
      validations.isValidAlt()&& validations.isValidAnio()&& validations.isValidPro()&&
      validations.isValidProd()&& validations.isValidIns()&& validations.isValidMunNew()&& validations.isValidMun()&& validations.isValidAldea()&&
      validations.isValidAldeaNew()&& validations.isValidAldea_n() && validations.isValidPropietarioNew() && validations.isValidProductorNew()){
    const responseAldea = await peticionAldea();
    const responseProductor = await peticionProductor();
    const responsePropietario = await peticionPropietario();
      if(handledValidation || responsePropietario?.data?.add_propietario.id || responseAldea?.data?.Add_Aldea.id || responseProductor?.data?.add_propietario.id){
        await updateFinca({
        variables: {
          id:parseFloat(fincaSeleccionada.id),
          tipo_finca: fincaSeleccionada.tipo_finca,
          tecnico: parseFloat(fincaSeleccionada.tecnico),
          nombre: fincaSeleccionada.nombre,
          latitud:parseFloat(fincaSeleccionada.latitud),
          longitud: parseFloat(fincaSeleccionada.longitud),
          area: parseFloat(fincaSeleccionada.area),
          lote: fincaSeleccionada.lote,
          aldea_n: responseAldea?.data?.Add_Aldea.id || parseFloat(fincaSeleccionada.aldea_n),
          tipo_huerta: fincaSeleccionada.tipo_huerta,
          variedad: fincaSeleccionada.variedad,
          porcentaje_sombra:parseFloat(fincaSeleccionada.porcentaje_sombra),
          densidad_arboles_ha:parseFloat(fincaSeleccionada.densidad_arboles_ha),
          superficie:parseFloat(fincaSeleccionada.superficie),
          altura:parseFloat(fincaSeleccionada.altura),
          anio_plantacion:parseFloat(fincaSeleccionada.anio_plantacion),
          tipo_cafe: fincaSeleccionada.tipo_cafe,
          propietario: responsePropietario?.data?.add_propietario.id || parseFloat(fincaSeleccionada.propietario),
          productor: responseProductor?.data?.add_propietario.id || parseFloat(fincaSeleccionada.productor)
        }
      });
      setEnable(false);
      abrirCerrarModalEditar();
    }else{
      return null;
    }}
  }catch (err) {
      console.log('err', err);
    }
  }

  const peticionAdd = async () => {
    setAdd(true);
    try {
        if (validations.isValidLat() && validations.isValidLong() && validations.isValidArea() &&
            validations.isValidSom() && validations.isValidDen() && validations.isValidSu() &&
            validations.isValidAlt() && validations.isValidAnio() && validations.isValidPro() &&
            validations.isValidProd() && validations.isValidIns() && validations.isValidMunNew() && validations.isValidMun() && validations.isValidAldea() &&
            validations.isValidAldeaNew() && validations.isValidAldea_n()&& validations.isValidPropietarioNew() && validations.isValidProductorNew()) {
            const responseAldea = await peticionAldea();
            const responsePropietario = await peticionPropietario();
            const responseProductor = await peticionProductor();
            if (handledValidation || responsePropietario?.data?.add_propietario.id || responseAldea?.data?.Add_Aldea.id || responseProductor?.data?.add_propietario.id) {
                await addFinca({
                    variables: {
                        tipo_finca: fincaSeleccionada.tipo_finca,
                        tecnico: parseFloat(dataFincasTecnico.findUserFincas.user.id),
                        nombre: fincaSeleccionada.nombre,
                        latitud: parseFloat(fincaSeleccionada.latitud),
                        longitud: parseFloat(fincaSeleccionada.longitud),
                        area: parseFloat(fincaSeleccionada.area),
                        lote: fincaSeleccionada.lote,
                        aldea_n: responseAldea?.data?.Add_Aldea.id || parseFloat(fincaSeleccionada.aldea_n),
                        tipo_huerta: fincaSeleccionada.tipo_huerta,
                        variedad: fincaSeleccionada.variedad,
                        porcentaje_sombra: parseFloat(fincaSeleccionada.porcentaje_sombra),
                        densidad_arboles_ha: parseFloat(fincaSeleccionada.densidad_arboles_ha),
                        superficie: parseFloat(fincaSeleccionada.superficie),
                        altura: parseFloat(fincaSeleccionada.altura),
                        anio_plantacion: parseFloat(fincaSeleccionada.anio_plantacion),
                        tipo_cafe: fincaSeleccionada.tipo_cafe,
                        propietario: responsePropietario?.data?.add_propietario.id || parseFloat(fincaSeleccionada.propietario),
                        productor: responseProductor?.data?.add_propietario.id || parseFloat(fincaSeleccionada.productor),
                        institucion: parseFloat(fincaSeleccionada.institucion)
                    }
                });
                abrirCerrarModalInsertar();
            } else {
                return null;
            }}
      }catch (err) {
        console.log('err', err);
    }
}
 
  const handleChange=(e:any)=>{
    let {name, value}=e.target;
      setFincaSeleccionada((prevState:any)=>{
        if(prevState[name]!== value){
          setEnable(true);
        }if(name==='departamento'){
          findMunicipios({ variables: { id:parseFloat(value)} });
          findAldeas({variables: {id:parseFloat(value)}} );
          setEnabledMunicipio(true);
          setHide(false);
          setShowAldea(false);
          setOcultar(true);
        }
        if(name==='municipio'){
          findAldeas({variables: {id:parseFloat(value)}} );
          setHide(false);
          setEnabledAldea_n(true);
          setShowAldea(false);
          setOcultar(true);
        }
        if(name==='municipio' && value==='0'&& modalEditar){
          findMunicipios({ variables: { id:parseFloat(value)} });
          setHide(true);
          setShowAldea(true);
          setOcultar(false);
        } if(name==='municipio' && value==='0'){
          setHide(true);
          setShowAldea(true);
          setOcultar(false);
        }if(name==='aldea_n' && value==='0'){
          setShowAldea(true)
        }
        if(name==='aldea_n' && value!=='0'){
          setShowAldea(false)
        }
        if(name==='propietario' && value==='0'){
          setInputProp(true)
        }
        if(name==='propietario' && value!=='0'){
          setInputProp(false)
        }
        if(name==='productor' && value==='0'){
          setInputProd(true)
        }
        if(name==='productor' && value!=='0'){
          setInputProd(false);
        }
        return{
      ...prevState,
      [name]: value,
      }
  })
  }
  if (loadingAdd) {
    return  <PageLoader section="Administración" title="Padrón de Fincas"></PageLoader>
  }
  if (errorAdd) {
    return (
      <PageError section="Reportes" title="Padrón de Fincas"></PageError>
    )
  }
  
  const abrirCerrarModalInsertar=()=>{
    setEnable(false);
    setShowAldea(false);
    setOcultar(true);
    setHide(false);
    setInputProd(false);
    setInputProp(false);
    setAdd(false);
    setEnabledAldea_n(false);
    setEnabledMunicipio(false);
    setFincaSeleccionada(fincaInit);
    setErrorMessage('');
    setValidCampo(false);
    setValidCampoPropietario(false);
    setValidCampoProductor(false);
    setValidCampoMunicipio(false);
    setFinishValid('');
    setHandledValidation(true);
    setErrorMessagePropietario('');
    setErrorMessageMunicipio('');
    setModalInsertar(!modalInsertar);
  }
  
  const abrirCerrarModalEditar=()=>{
    setShowAldea(false);
    setHide(false);
    setOcultar(true);
    setInputProd(false);
    setInputProp(false);
    setFinishValid('');
    setValidCampo(false);
    setValidCampoProductor(false);
    setValidCampoPropietario(false);
    setValidCampoMunicipio(false);
    setErrorMessage('');
    setHandledValidation(true);
    setErrorMessagePropietario('');
    setErrorMessageMunicipio('');
    setErrorMessageProductor('');
    setModalEditar(!modalEditar);
  }
  
  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }
  const abrirCerrarModalNavegar=()=>{
    setModalNavegar(!modalNavegar);
  }

  const editarFinca=(item:any, caso:any)=>{
    setEnable(false)
    let finca={...item}
    if(finca.aldea_n!==null){
      finca.aldea_n=item.aldea_n.id;
      finca.departamento=item.aldea_n.municipios.departamento.id;
      finca.municipio=item.aldea_n.municipios.id;
      findMunicipios({ variables: { id:parseFloat(item.aldea_n.municipios.departamento.id)} });
      findAldeas({variables: {id:parseFloat(item.aldea_n.municipios.id)}} );
    }
    if(finca.propietario!==null){
      finca.propietario=item.propietario.id
    }
    if(finca.productor!==null){
      finca.productor=item.productor.id;
    }
    if(finca.tecnico!==null){
      finca.tecnico=dataFincasTecnico.findUserFincas.user.id;
    }
    finca.aldeaNew='';
    setFincaSeleccionada(finca);
    (caso==='Editar')?abrirCerrarModalEditar():abrirCerrarModalEliminar()
  }
  const navegar=(item:any)=>{
    let finca={...item}
    if(finca.aldea_n===null){
      finca.departamento='';
      finca.municipio='';
      finca.aldea_n='';
    }
    if(item.aldea_n!==null){
      finca.departamento=item.aldea_n.municipios.departamento.nombre;
      finca.municipio=item.aldea_n.municipios.nombre;
      finca.aldea_n=item.aldea_n.nombre;
    }
    if(finca.propietario!==null){
      finca.propietario=item.propietario.name;
    }
    if(finca.productor!==null){
      finca.productor=item.productor.name;
    }
    setFincaSeleccionada(finca);
    abrirCerrarModalNavegar();
  }
  const bodyNavegar=(
    <div className="col-10 col-lg-6 col-xl-4 p-4 mx-auto my-auto"> 
          <div className="card">
            <div className="card-header pt-0 text-right">
              <a 
                style={{cursor: 'pointer'}} 
                onClick={(e)=>{
                e.preventDefault();
                abrirCerrarModalNavegar();
                }}
              >
                <FontAwesomeIcon icon={ faTimes }/>
              </a>
            </div>
            <div className="card-body"> 
     <div className="card-title"> 
      <p>
        <ul className="list-group list-group-flush">
          <li className="list-group-item"><label>Id Finca: </label> {fincaSeleccionada && fincaSeleccionada.id}</li>
      <li className="list-group-item"><label>Nombre: </label> {fincaSeleccionada && fincaSeleccionada.nombre}</li>
      <li className="list-group-item"><label>Propietario: </label> {fincaSeleccionada && fincaSeleccionada.propietario}</li>
      <li className="list-group-item"><label>Productor: </label> {fincaSeleccionada && fincaSeleccionada.productor}</li>
      <li className="list-group-item"><label>Departamento: </label> {fincaSeleccionada && fincaSeleccionada.departamento}</li>
      <li className="list-group-item"><label>Municipio: </label> {fincaSeleccionada && fincaSeleccionada.municipio}</li>
      <li className="list-group-item"><label>Aldea: </label> {fincaSeleccionada && fincaSeleccionada.aldea_n}</li>
      <li className="list-group-item"><label>Plantación: </label> {fincaSeleccionada && fincaSeleccionada.anio_plantacion}</li>
      <li className="list-group-item"><label>Variedad: </label> {fincaSeleccionada && fincaSeleccionada.variedad}</li>
        </ul>
      </p>
      </div>
      <footer style={{paddingTop:'10px', marginLeft:'auto'}}>
    
    </footer>     
     </div>
  </div>
  </div>
  )

  const bodyInsertar=(
    <div style={{ overflowY:'auto',overflowX:'hidden',position:'absolute', width:'470px', height:'600px', backgroundColor:'white', top:'50%',  left:'50%', transform: 'translate(-50%, -50%)', borderRadius:10}}>
      <ModalBody className="ml-3" style={{ overflowY:'auto',position:'absolute', backgroundColor:'white', width:'430px'}}>
      <div className="pt-0 float-right">
            <a 
                style={{cursor: 'pointer'}} 
                onClick={(e)=>{
                e.preventDefault();
                abrirCerrarModalInsertar();
                }}
              >
                <FontAwesomeIcon icon={ faTimes }/>
              </a>
        </div>
    <div className="form-group mt-4">
      <label  htmlFor="tipo_finca">Tipo Finca</label>
      <select className="form-control"  name="tipo_finca" id="tipo_finca" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.tipo_finca}>
        <option  value=''>--seleccione--</option>
        <option  value={TipoFinca.Centinela}>{TipoFinca.Centinela}</option>
        <option  value={TipoFinca.Demostrativa}>{TipoFinca.Demostrativa}</option>
        <option  value={TipoFinca.Exploracion}>Exploración</option>
      </select>
    </div>
    <div className="form-group">
      <label htmlFor="tecnico">Técnico</label>
      <input className="form-control " type="text" name="tecnico" id="tecnico"  value={datosUser} disabled></input>
    </div>
    <div className="form-group">
      <label htmlFor="nombre">Nombre Finca</label>
      <input className="form-control" type="text" name="nombre" id="nombre" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.nombre}/>
    </div>
    <div className="form-group">
      <label className="small mb-1"  htmlFor="inputCountry">Latitud</label>
      <input style={add && !validations.isValidLat() ? errorStyle:{}} className="form-control " type="number" name="latitud" id="latitud" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.latitud}/>          
      {latErrorMessage ? <h6 className="text-center red">{latErrorMessage}</h6>:null}
    </div>
    <div className="form-group">
      <label className="small mb-1" htmlFor="inputCountry">Longitud</label>
      <input style={add && !validations.isValidLong() ? errorStyle:{}} className="form-control " type="number" name="longitud" id="longitud" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.longitud}/>
      {longErrorMessage ? <h6 className="text-center red">{longErrorMessage}</h6>:null}
    </div>
    <div className="form-group">
      <label className="small mb-1" htmlFor="Area">Área(has)</label>
      <input style={add && !validations.isValidArea() ? errorStyle:{}} className="form-control " type="number" name="area" id="area" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.area}/>
      {areaErrorMessage ? <h6 className="text-center red">{areaErrorMessage}</h6>:null}
    </div>
    <div className="form-group">
      <label className="small mb-1" htmlFor="lote">Lote</label>
      <input className="form-control " type="text" name="lote" id="lote" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.lote}  />
    </div>
    <div className="form-group">
      <label htmlFor="departamento">Departamento</label>
      <select className="form-control"  name="departamento" id="departamento" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.departamento} >
        <option  value=''>--seleccione--</option>
        {datosDepto.map((item:any)=>{
          return(<option  value={item.id}>{item.nombre}</option>)
        })}
      </select>
    </div>
    <div className="form-group" id='mun' >
      <label htmlFor="municipio">Municipio</label>
      <select style={add && !validations.isValidMun() ? errorStyle:{}} className="form-control"  name="municipio" id="municipio" onChange={handleChange} disabled={!enabledMunicipio}>
        <option  value=''>--seleccione--</option>
        {datosMunicipios.map((item:any)=>{
        return(<option  value={item.id}>{item.nombre}</option>)
        })}
        <option id='newmuni' value='0'>--NUEVO--</option>
      </select>
      {munErrorMessage ? <h6 className="text-center red">{munErrorMessage}</h6>:null}
    </div>
    <div className="form-group" hidden={!hide} >
      <label htmlFor="municipio">Municipio</label>
      <input style={add && !validations.isValidMunNew() || errorValidCampoMunicipio ? errorStyle:{}} className="form-control " type="text" name="municipioNew" id="municipioNew" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.municipioNew}/>
      {errorMessageMunicipio ? <h6 className="text-center red">{errorMessageMunicipio}</h6>:null}
      {munErrorMessage ? <h6 className="text-center red">{munErrorMessage}</h6>:null}
    </div>
    <div className="form-group" hidden={!ocultar}>
      <label htmlFor="aldea">Aldea</label>
      <select style={add && !validations.isValidAldea_n() ? errorStyle:{}} className="form-control"  name="aldea_n" id="aldea_n" onChange={handleChange} disabled={!enabledAldea_n}>
        <option  value=''>--seleccione--</option>
        {datosAldea.map((item:any)=>{
        return(<option  value={item.id}>{item.nombre}</option>)
        })}
        <option id='aldeaNueva' value='0'>--NUEVO--</option>
      </select>
      {aldea_nErrorMessage ? <h6 className="text-center red">{aldea_nErrorMessage}</h6>:null}
    </div>
    <div className="form-group" hidden={!showAldea} >
      <label htmlFor="aldeaNew">Aldea</label>
      <input style={add && !validations.isValidAldea() || !validations.isValidAldeaNew() || errorValidCampo ? errorStyle:{}} className="form-control " type="text" name="aldeaNew" id="aldeaNew" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.aldeaNew} />
      {errorMessage ? <h6 className="text-center red">{errorMessage}</h6>:null}
      {aldeaErrorMessage ? <h6 className="text-center red">{aldeaErrorMessage}</h6>:null}
    </div>
    <div className="form-group">
      <label className="small mb-1" htmlFor="inputCountry">Tipo Huerta</label>
      <select className="form-control" name="tipo_huerta" id="tipo_huerta" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.tipo_huerta} >
        <option value=''>--Seleccione</option>
        <option  value={TipoHuerta.Convencional}>{TipoHuerta.Convencional}</option>
        <option  value={TipoHuerta.BPA}>{TipoHuerta.BPA}</option>
        <option  value={TipoHuerta.Diversificada}>{TipoHuerta.Diversificada}</option>
        <option  value={TipoHuerta.Organico}>{TipoHuerta.Organico}</option>
      </select>
    </div>
    <div className="form-group">
      <label className="small mb-1" htmlFor="inputCountry">Variedad</label>
      <select className="form-control" name="variedad" id="variedad"  onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.variedad}>
        <option  value=''>--seleccione--</option>
        <option value='CATURRA'>CATURRA</option><option value='CATUAI'>CATUAI</option><option  value='CATIMOR'>CATIMOR</option><option value='MEZCLA'>MEZCLA</option>
        <option value='BORBON'>BORBÓN</option><option value='ROBUSTA TROPICAL'>ROBUSTA TROPICAL</option><option value='PACHE COMUN'>PACHE COMUN</option><option value='PACAMARA'>PACAMARA</option>
        <option value='KETESIC'>KETESIC</option><option value='CUSTATECLO'>CUSCATECLO</option><option value='PACAS'>PACAS</option><option value='GEISHA'>GEISHA</option><option value='TYPICA'>TYPICA</option><option value='LEMPIRA'>LEMPIRA</option><option value='T8667'>T8667</option>
        <option value='OBATA'>OBATA</option><option value='TUPI'>TUPI</option><option value='ACAUA'>ACAUA</option><option value='IAAPAR'>IAAPAR</option><option value='PARAINEMA'>PARAINEMA</option><option value='CASTILLO'>CASTILLO</option><option value='BOURBON'>BOURBÓN</option><option value='ICATU'>ICATÚ</option>
        <option value='IHCAFE-90'>IHCAFE-90</option><option value='PAACHE'>PAACHE</option><option value='VILLA SARCHI'>VILLA SARCHÍ</option><option value='HIBRIDO H-1'>HÍBRIDO H-1</option><option value='HIBRIDO H-3'>HÍBRIDO H-3</option><option value='CR95'>CR95</option><option value='H27'>H27</option><option value='JAVA'>JAVA</option>
      </select>
    </div>
    <div className="form-group">
      <label className="small mb-1" htmlFor="inputCountry">Propietario</label>
      <select style={add && !validations.isValidPro() ? errorStyle:{}} className="form-control" name="propietario" id="propietario"  onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.propietario}>
        <option  value=''>--seleccione--</option>
        {datosPropietarios.map((item:any)=>{
          return(<option  value={item.id}>{item.name}</option>)
        })}
        <option id='propNew' value='0'>--NUEVO--</option>
      </select>
      {proErrorMessage ? <h6 className="text-center red">{proErrorMessage}</h6>:null}
    </div>
    <div className="form-group" hidden={!inputProp} >
        <label htmlFor="propietario">Propietario</label>
        <input style={add && errorValidCampoPropietario || !validations.isValidPropietarioNew() ? errorStyle:{}} className="form-control " type="text" name="propietarioNew" id="propietarioNew" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.propietarioNew}/>
        {proErrorMessage ? <h6 className="text-center red">{proErrorMessage}</h6>:null}
        {errorMessagePropietario ? <h6 className="text-center red">{errorMessagePropietario}</h6>:null}
    </div>
    <div className="form-group">
      <label className="small mb-1" htmlFor="inputCountry">Productor</label>
      <select style={add && !validations.isValidProd() ? errorStyle:{}} className="form-control" name="productor" id="productor"  onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.productor}>
        <option  value=''>--seleccione--</option>
        {datosPropietarios.map((item:any)=>{
          return(<option  value={item.id}>{item.name}</option>)
        })}
        <option id='prodNew' value='0'>--NUEVO--</option>
      </select>
      {prodErrorMessage ? <h6 className="text-center red">{prodErrorMessage}</h6>:null}
    </div>
    <div className="form-group" hidden={!inputProd} >
        <label htmlFor="productor">Productor</label>
        <input style={add && errorValidCampoProductor || !validations.isValidProductorNew() ? errorStyle:{}} className="form-control " type="text" name="productorNew" id="productorNew" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.productorNew} />
        {prodErrorMessage ? <h6 className="text-center red">{prodErrorMessage}</h6>:null}
        {errorMessageProductor ? <h6 className="text-center red">{errorMessageProductor}</h6>:null}
    </div>
    <div className="form-group">
      <label className="small mb-1" htmlFor="inputCountry">Sombra</label>
      <select style={add && !validations.isValidSom() ? errorStyle:{}} className="form-control" name="porcentaje_sombra" id="porcenaje_sombra"  onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.porcentaje_sombra}>
       <option value='' >--Seleccione</option>
       <option value='1'>SI</option>
       <option value='0'>NO</option>
     </select>
     {sombraErrorMessage ? <h6 className="text-center red">{sombraErrorMessage}</h6>:null}
    </div>
    <div className="form-group">
       <label className="small mb-1" htmlFor="inputCountry">Densidad(Árboles/Ha)</label>
       <input style={add && !validations.isValidDen() ? errorStyle:{}} className="form-control" type="number" name="densidad_arboles_ha" id="densidad_arboles_ha" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.densidad_arboles_ha}/>
       {denErrorMessage ? <h6 className="text-center red">{denErrorMessage}</h6>:null}
    </div>   
    <div className="form-group">
       <label className="small mb-1" htmlFor="inputCountry">Superficie(has)</label>
       <input style={add && !validations.isValidSu() ? errorStyle:{}} className="form-control" type="number" name="superficie" id="superficie" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.superficie}/>
       {suErrorMessage ? <h6 className="text-center red">{suErrorMessage}</h6>:null}
    </div>   
    <div className="form-group">
       <label className="small mb-1" htmlFor="inputCountry">Altura(msnm)</label>
       <input style={add && !validations.isValidAlt() ? errorStyle:{}} className="form-control" type="number" name="altura" id="altura" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.altura}/>
       {altErrorMessage ? <h6 className="text-center red">{altErrorMessage}</h6>:null}
    </div>   
    <div className="form-group">
      <label className="small mb-1" htmlFor="inputCountry">Año de Plantación</label>
      <input style={add && !validations.isValidAnio() ? errorStyle:{}} className="form-control" type="number" name="anio_plantacion" id="anio_plantacion" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.anio_plantacion}/>
      {anioErrorMessage ? <h6 className="text-center red">{anioErrorMessage}</h6>:null}
    </div> 
    <div className="form-group">  
      <label className="small mb-1" htmlFor="inputCountry">Tipo de Café</label>
      <select className="form-control" name="tipo_cafe" id="tipo_cafe" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.tipo_cafe}>
        <option  value={TipoCafe.Arabico}>Arábico</option>
      </select>
    </div>
    <div className="form-group">
      <label className="small mb-1" htmlFor="inputCountry">Institución</label>
      <select style={add && !validations.isValidIns() ? errorStyle:{}} className="form-control" name="institucion" id="institucion" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.institucion}>
         <option value='0'>--Seleccione--</option>
         {datosInstitucion.map((item:any)=>{
          return(<option  value={item.id}>{item.nombre}</option>)
        })}
      </select>
      {insErrorMessage ? <h6 className="text-center red">{insErrorMessage}</h6>:null}
    </div>
    {addErrorMessage ? <h6 className="text-center red">{addErrorMessage}</h6>:null}
    {finishValid ? <h6 className="text-center red">{finishValid}</h6>:null}
    <footer style={{paddingTop:'10px', marginLeft:'auto'}}>
      <button type="reset" onChange={handleChange} disabled={!enable} onClick={peticionAdd} className="btn btn-aceptar btn-block btn-sm" >Agregar</button>{' '}
      <button className="btn btn-cancelar btn-block btn-sm" onClick={()=>abrirCerrarModalInsertar()}>Cancelar</button>
    </footer>     
     </ModalBody>
  </div>
      )

  const bodyEditar=(
    <div style={{ overflowY:'auto',overflowX:'hidden',position:'absolute', width:'470px', height:'600px', backgroundColor:'white', top:'50%',  left:'50%', transform: 'translate(-50%, -50%)', borderRadius:10}}>
      <ModalBody className="ml-3" style={{ overflowY:'auto',position:'absolute', backgroundColor:'white', width:'430px'}}>
      <div className="pt-0 float-right">
            <a 
                style={{cursor: 'pointer'}} 
                onClick={(e)=>{
                e.preventDefault();
                abrirCerrarModalEditar();
                }}
              >
                <FontAwesomeIcon icon={ faTimes }/>
              </a>
        </div>
        <div className="form-group mt-4">
          <label  htmlFor="id"></label>
          <input className="form-control " type="hidden" name="id" id="id"  value={fincaSeleccionada && fincaSeleccionada.id} disabled></input>
        </div>
        <div className="form-group">
          <label  htmlFor="tipo_finca">Tipo Finca</label>
          <select className="form-control"  name="tipo_finca" id="tipo_finca" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.tipo_finca}>
            <option  value='' >--seleccione--</option>
            <option  value={TipoFinca.Centinela}>{TipoFinca.Centinela}</option>
            <option  value={TipoFinca.Demostrativa}>{TipoFinca.Demostrativa}</option>
            <option  value={TipoFinca.Exploracion}>Exploración</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="tecnico">Técnico</label>    
          <input className="form-control " type="text" name="tecnico" id="tecnico"  value={datosUser} disabled></input>
        </div>
        <div className="form-group">
          <label htmlFor="nombre">Nombre Finca</label>
          <input className="form-control" type="text" name="nombre" id="nombre" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.nombre}/>
        </div>
        <div className="form-group">
          <label className="small mb-1"  htmlFor="latitud">Latitud</label>
          <input style={add && !validations.isValidLat() ? errorStyle:{}} className="form-control " type="number" name="latitud" id="latitud" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.latitud} />          
          {latErrorMessage ? <h6 className="text-center red">{latErrorMessage}</h6>:null}
        </div>
        <div className="form-group">
          <label className="small mb-1" htmlFor="longitud">Longitud</label>
          <input style={add && !validations.isValidLong() ? errorStyle:{}} className="form-control " type="number" name="longitud" id="longitud" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.longitud} />
          {longErrorMessage ? <h6 className="text-center red">{longErrorMessage}</h6>:null}
        </div>
        <div className="form-group">
          <label className="small mb-1" htmlFor="Area">Área(has)</label>
          <input style={add && !validations.isValidArea() ? errorStyle:{}} className="form-control " type="number" name="area" id="area" onChange={handleChange}  value={fincaSeleccionada && fincaSeleccionada.area}/>
          {areaErrorMessage ? <h6 className="text-center red">{areaErrorMessage}</h6>:null}
        </div>
        <div className="form-group">
          <label className="small mb-1" htmlFor="lote">Lote</label>
          <input className="form-control " type="text" name="lote" id="lote" onChange={handleChange}  value={fincaSeleccionada && fincaSeleccionada.lote}/>
        </div>
        <div className="form-group">
          <label htmlFor="departamento">Departamento</label>
          <select className="form-control"  name="departamento" id="departamento" onChange={handleChange}  value={fincaSeleccionada && fincaSeleccionada.departamento}>
            <option  value=''>--seleccione--</option>
            {datosDepto.map((item:any)=>{
              return(<option  value={item.id}>{item.nombre}</option>)
            })}
          </select>
        </div>
      <div className="form-group" id='mun' >
        <label htmlFor="municipio">Municipio</label>
        <select style={add && !validations.isValidMun() ? errorStyle:{}} className="form-control"  name="municipio" id="municipio" onChange={handleChange}  value={fincaSeleccionada && fincaSeleccionada.municipio}>
        <option  value=''>--seleccione--</option>
          {datosMunicipios.map((item:any)=>{
            return(<option  value={item.id}>{item.nombre}</option>)
          })}
          <option id='newmuni' value='0'>--NUEVO--</option>
        </select>
        {munErrorMessage ? <h6 className="text-center red">{munErrorMessage}</h6>:null}
      </div>
      <div className="form-group" hidden={!hide} >
        <label htmlFor="municipionew">Municipio</label>
        <input style={add && !validations.isValidMunNew()|| errorValidCampoMunicipio ? errorStyle:{}} className="form-control " type="text" name="municipioNew" id="municipioNew" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.municipioNew}/>
        {munErrorMessage ? <h6 className="text-center red">{munErrorMessage}</h6>:null}
        {errorMessageMunicipio ? <h6 className="text-center red">{errorMessageMunicipio}</h6>:null}
      </div>
      <div className="form-group" hidden={!ocultar}>
        <label htmlFor="aldea">Aldea</label>
        <select style={add && !validations.isValidAldea_n() ? errorStyle:{}} className="form-control"  name="aldea_n" id="aldea_n" onChange={handleChange}  value={fincaSeleccionada && fincaSeleccionada.aldea_n}>
          <option  value=''>--seleccione--</option>
          {datosAldea.map((item:any)=>{
            return(<option  value={item.id}>{item.nombre}</option>)
          })}
          <option id='aldeaNew' value='0'>--NUEVO--</option>
        </select>
        {aldea_nErrorMessage ? <h6 className="text-center red">{aldea_nErrorMessage}</h6>:null}
      </div>
      <div className="form-group" hidden={!showAldea} >
        <label htmlFor="aldea">Aldea</label>
        <input style={add && !validations.isValidAldea() || !validations.isValidAldeaNew()|| errorValidCampo ? errorStyle:{}} className="form-control " type="text" name="aldeaNew" id="aldeaNueva" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.aldeaNew}/>
        {aldeaErrorMessage ? <h6 className="text-center red">{aldeaErrorMessage}</h6>:null}
        {errorMessage ? <h6 className="text-center red">{errorMessage}</h6>:null}
      </div>
      <div className="form-group">
        <label className="small mb-1" htmlFor="inputCountry">Tipo Huerta</label>
        <select className="form-control" name="tipo_huerta" id="tipo_huerta" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.tipo_huerta}>
          <option value=''>--Seleccione</option>
          <option  value={TipoHuerta.Convencional}>{TipoHuerta.Convencional}</option>
          <option  value={TipoHuerta.BPA}>{TipoHuerta.BPA}</option>
          <option  value={TipoHuerta.Diversificada}>{TipoHuerta.Diversificada}</option>
          <option  value={TipoHuerta.Organico}>Orgánico</option>
        </select>
      </div>  
      <div className="form-group">
       <label className="small mb-1" htmlFor="inputCountry">Variedad</label>
        <select className="form-control" name="variedad" id="variedad"  onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.variedad}>
          <option  value='' disabled>--seleccione--</option>
          <option value='CATURRA'>CATURRA</option><option value='CATUAI'>CATUAI</option><option  value='CATIMOR'>CATIMOR</option><option value='MEZCLA'>MEZCLA</option>
          <option value='BORBÓN'>BORBÓN</option><option value='ROBUSTA TROPICAL'>ROBUSTA TROPICAL</option><option value='PACHE COMUN'>PACHE COMUN</option><option value='PACAMARA'>PACAMARA</option>
          <option value='KETESIC'>KETESIC</option><option value='CUSTATECLO'>CUSCATECLO</option><option value='PACAS'>PACAS</option><option value='GEISHA'>GEISHA</option><option value='TYPICA'>TYPICA</option><option value='LEMPIRA'>LEMPIRA</option><option value='T8667'>T8667</option>
          <option value='OBATA'>OBATA</option><option value='TUPI'>TUPI</option><option value='ACAUA'>ACAUA</option><option value='IAAPAR'>IAAPAR</option><option value='PARAINEMA'>PARAINEMA</option><option value='CASTILLO'>CASTILLO</option><option value='BOURBÓN'>BOURBÓN</option><option value='ICATÚ'>ICATÚ</option>
          <option value='IHCAFE-90'>IHCAFE-90</option><option value='PAACHE'>PAACHE</option><option value='VILLA SARCHÍ'>VILLA SARCHÍ</option><option value='HÍBRIDO H-1'>HÍBRIDO H-1</option><option value='HÍBRIDO H-3'>HÍBRIDO H-3</option><option value='CR95'>CR95</option><option value='H27'>H27</option><option value='JAVA'>JAVA</option>
        </select>
      </div>
      <div className="form-group">
        <label className="small mb-1" htmlFor="inputCountry">Propietario</label>
        <select style={add && !validations.isValidPro() ? errorStyle:{}} className="form-control" name="propietario" id="propietario"  onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.propietario}>
          <option  value=''>--seleccione--</option>
          {datosPropietarios.map((item:any)=>{
            return(<option  value={item.id}>{item.name}</option>)
          })}
          <option id='propNew' value='0'>--NUEVO--</option>
        </select>
        {proErrorMessage ? <h6 className="text-center red">{proErrorMessage}</h6>:null}
      </div>
      <div className="form-group" hidden={!inputProp} >
        <label htmlFor="propietario">Propietario</label>
        <input style={add && errorValidCampoPropietario ? errorStyle:{}} className="form-control " type="text" name="propietarioNew" id="propietarioNew" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.propietarioNew}/>
        {proErrorMessage ? <h6 className="text-center red">{proErrorMessage}</h6>:null}
        {errorMessagePropietario ? <h6 className="text-center red">{errorMessagePropietario}</h6>:null}
      </div>
      <div className="form-group">
        <label className="small mb-1" htmlFor="inputCountry">Productor</label>
        <select style={add && !validations.isValidProd() ? errorStyle:{}} className="form-control" name="productor" id="productor"  onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.productor}>
          <option  value=''>--seleccione--</option>
          {datosPropietarios.map((item:any)=>{
            return(<option  value={item.id}>{item.name}</option>)
          })}
          <option id='propNew' value='0'>--NUEVO--</option>
        </select>
        {prodErrorMessage ? <h6 className="text-center red">{prodErrorMessage}</h6>:null}
      </div>
      <div className="form-group" hidden={!inputProd} >
        <label htmlFor="productor">Productor</label>
        <input style={add && errorValidCampoProductor ? errorStyle:{}} className="form-control " type="text" name="productorNew" id="productorNew" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.productorNew}/>
        {prodErrorMessage ? <h6 className="text-center red">{prodErrorMessage}</h6>:null}
        {errorMessageProductor ? <h6 className="text-center red">{errorMessageProductor}</h6>:null}
      </div>
      <div className="form-group">
        <label className="small mb-1" htmlFor="inputCountry">Sombra</label>
        <select style={add && !validations.isValidSom() ? errorStyle:{}} className="form-control" name="porcentaje_sombra" id="porcenaje_sombra"  onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.porcentaje_sombra}>
         <option value=''>--Seleccione</option>
         <option value='1'>SI</option>
         <option value='0'>NO</option>
        </select>
        {sombraErrorMessage ? <h6 className="text-center red">{sombraErrorMessage}</h6>:null}
      </div>
      <div className="form-group">
        <label className="small mb-1" htmlFor="inputCountry">Densidad(Árboles/Ha)</label>
        <input style={add && !validations.isValidDen() ? errorStyle:{}} className="form-control" type="number" name="densidad_arboles_ha" id="densidad_arboles_ha" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.densidad_arboles_ha}/>
        {denErrorMessage ? <h6 className="text-center red">{denErrorMessage}</h6>:null}
      </div>
      <div className="form-group">  
        <label className="small mb-1" htmlFor="inputCountry">Superficie(has))</label>
        <input style={add && !validations.isValidSu() ? errorStyle:{}} className="form-control" type="number" name="superficie" id="superficie" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.superficie}/>
        {suErrorMessage ? <h6 className="text-center red">{suErrorMessage}</h6>:null}
      </div>
      <div className="form-group"> 
        <label className="small mb-1" htmlFor="inputCountry">Altura(msnm)</label>
        <input style={add && !validations.isValidAlt() ? errorStyle:{}} className="form-control" type="number" name="altura" id="altura" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.altura}/>
        {altErrorMessage ? <h6 className="text-center red">{altErrorMessage}</h6>:null}
      </div>
      <div className="form-group">  
        <label className="small mb-1" htmlFor="plantacion">Año de Plantación</label>
        <input style={add && !validations.isValidAnio() ? errorStyle:{}} className="form-control" type="number" name="anio_plantacion" id="anio_plantacion" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.anio_plantacion}/>
        {anioErrorMessage ? <h6 className="text-center red">{anioErrorMessage}</h6>:null}
      </div>
      <div className="form-group">  
        <label className="small mb-1" htmlFor="inputCountry">Tipo de Café</label>
        <select className="form-control" name="tipo_cafe" id="tipo_cafe" onChange={handleChange} value={fincaSeleccionada && fincaSeleccionada.tipo_cafe}>
          <option value={TipoCafe.Arabico}>Arábico</option>
        </select>
      </div>
      {finishValid ? <h6 className="text-center red">{finishValid}</h6>:null}
      {addErrorMessage ? <h6 className="text-center red">{addErrorMessage}</h6>:null}
      <footer style={{paddingTop:'10px', marginLeft:'auto'}}>
       <button onChange={handleChange} disabled={!enable} onClick={peticionPut} className="btn btn-aceptar btn-block btn-sm" >
         Actualizar
       </button>
      <button className="tn btn-cancelar btn-block btn-sm" onClick={()=>abrirCerrarModalEditar()}>Cancelar</button>
      </footer>     
       </ModalBody>
    </div>
      )
  
  if(loadingFincasTecnico && firstLoad){
    return <PageLoader section="Reportes" title="Padrón de Fincas"></PageLoader>
  }
  if(errorFincasTecnico){
    return (
      <PageError section="Reportes" title="Padrón de Fincas"></PageError>
    )
  }
  return (
    <BasicLayout>
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><a href="homepage.html">Inicio</a></li>
            <li className="breadcrumb-item active">Reportes</li>
          </ol>
          <div className="row">
            <h1 className="col-lg-6">Padrón de Fincas</h1>
            <div  className="col-lg-2"><a className="btn btn-primary btn-sm mb-4" onClick={()=>abrirCerrarModalInsertar()}>Agregar Finca</a></div>
            <div style={{marginLeft: 'auto'}} className="col-lg-2">
              <ExportButton/>             
            </div>
            <div className="col-lg-2">
            <Link 
                className="btn btn-primary btn-sm mb-4" 
                to="mapa-fincas">Mapa
            </Link>
            </div>
          </div>
          <div className="card mb-4 table-responsive">
            <div className="card-body">
            <div className="row">
              <form className="form-inline mb-3 col-sm-12 col-md-6 ml-auto">
                <div className="form-group ml-md-auto">
                  <label htmlFor="searchBox" className="small pr-2">Buscar:</label>                  
                  <input 
                    type="search" 
                    className="form-control" 
                    id="searchBox"
                    value={parametro}
                    onChange={(e)=>{setParametro(e.target.value);}}
                  />                  
                </div>
              </form>
              </div>
              <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th>NOMBRE</th>
                    <th>DEPARTAMENTO</th>
                    <th>MUNICIPIO</th>
                    <th>ALDEA </th>
                    <th>PRODUCTOR</th>
                    <th>PLANTACIÓN</th>
                    <th>VARIEDAD</th>
                    <th style={{color:'white',flexFlow:'nowrap'}}></th>
                  </tr>
                </thead>
                {(!loadingFincasTecnico && !firstLoad) ?
                <tbody>
                {
                (!datos.length && !firstLoad && parametro!=="") ? 
                <td colSpan={8} className="text-center">
                  No hay resultados para "{parametro}"
                </td>
                :
                datos.map((item:any,  productor:any, ald:any)=>{
                  let mun:any, dep:any;
                  if(item.productor!=null){
                    productor=item.productor.name; 
                  }if(item.productor===null){
                    productor=' ';
                  }
                  if(item.aldea_n===null){
                    ald='';
                  }
                  if(item.aldea_n!==null){
                    ald=item.aldea_n.nombre;
                    mun=item.aldea_n.municipios.nombre;
                    dep=item.aldea_n.municipios.departamento.nombre;
                  }
                  return(
                  <tr key={item.id}>
                    <td>{item.nombre}</td>
                    <td>{dep}</td>
                    <td>{mun}</td>
                    <td>{ald}</td>
                    <td>{productor}</td>
                    <td>{item.anio_plantacion}</td>
                    <td>{item.variedad}</td>
                    <td>
                      <div className="row" style={{flexFlow:'row'}}>
                      <div className="col-lg-4 col-md-4 "><button className="btn table-small-btn" onClick={()=>navegar(item)} ><FontAwesomeIcon icon={faInfoCircle}/></button></div>
                      <div className="col-lg-4 col-md-4"><button className="btn table-small-btn" onClick={()=>editarFinca(item, 'Editar')} ><FontAwesomeIcon icon={faPencilAlt}/></button></div>
                      </div>
                    </td>
                  </tr>
                )})}
                </tbody>
                :
                <tbody>
                  <tr>
                    <td colSpan={8} className="text-center">
                      <img src={PreLoaderImage} alt="loading" style={{ height: '100px'}}/>
                    </td>
                  </tr>
                </tbody>
                }
              </table>
            </div>
          </div>
        </div>
      </main>
      <Modal open={modalNavegar} onClose={abrirCerrarModalNavegar}>{bodyNavegar}</Modal>
      <Modal open={modalInsertar} onClose={abrirCerrarModalInsertar}>{bodyInsertar}</Modal>
      <Modal open={modalEditar} onClose={abrirCerrarModalEditar}>{bodyEditar}</Modal>
    </BasicLayout>
  )
};

export default FincasTecnico;