import { gql } from '@apollo/client';
const GET_MUNICIPIOS = gql`
query Municipios(
  $id:Float!
){
  find_Municipios(
    Municipio:{
      id:$id
    }
  ){
    id
    nombre
  }
}
`;
export default GET_MUNICIPIOS;