import { gql } from "@apollo/client";

const POWER_BI_AUTH_TOKEN = gql`
mutation PowerBiAuthToken{
  powerBiAuthToken{
    access_token
    refresh_token
  }
}
`;

export default POWER_BI_AUTH_TOKEN;