import { UserInfo } from 'os';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { RegisterVariables } from 'src/apollo/queries/types/Register';

interface RegisterFormProps {
  onSubmit(userInfo: any): void
  userInformation: any
  error: string
}
const RegisterForm = (props: RegisterFormProps) =>{
  const [name, setName] = useState(props.userInformation.name);
  const [userName, setUserName] = useState(props.userInformation.username);
  const [password, setPassword] = useState(props.userInformation.password);
  const [confirmPassword, setConfirmPassword] = useState(props.userInformation.confirmPassword);
  const [phone, setPhone] = useState(props.userInformation.phone);
  const [submited, setSubmited] = useState(false);
  let nameErrorMessage;
  let userNameErrorMessage;
  let passwordErrorMessage;
  let phoneErrorMessage;
  let confirmPasswordErrorMessage;
  const errorStyle = {
    borderColor: 'red',
  }

  const validations = {
    isValidName: () => {
      if(name === ''){
        nameErrorMessage = 'El nombre no puede ir vacío';
        return false;
      }
      return true;
    },
    isValidUserName: () => {
      if(userName === ''){
        userNameErrorMessage = 'El nombre de usuario no puede ir vacío';
        return false;
      }
      if(/\s+/.test(userName)){
        userNameErrorMessage = 'Los espacios no son permitidos';
        return false;
      }
      return true;
    },
    isValidPassword : () => {
      if(password === ''){
        passwordErrorMessage = 'La contraseña no puede ir vacía';
        return false;
      }
      if (
        password.length < 8 ||
        !/[a-z]/.test(password) ||
        !/[A-Z]/.test(password) ||
        !/[0-9]/.test(password)
      ){
        passwordErrorMessage = `La contraseña debe contener al menos 7 
          caracteres incluyendo mayúsculas, minúsculas, números y 
          símbolos`;
        return false;
      }
      return true;
    },
    isValidConfirmPassword: () => {
      if(confirmPassword === ''){
        confirmPasswordErrorMessage = 'La contraseña no puede ir vacía';
        return false;
      }
      if(password !== confirmPassword){
        confirmPasswordErrorMessage = 'Las contraseñas no coinciden';
        return false
      }
      return true;
    },
    isValidPhone: () => {
      if(phone === ''){
        phoneErrorMessage = 'El teléfono no puede ir vacío';
        return false;
      }
      if(!(/^\d+$/).test(phone)){
        phoneErrorMessage = 'El teléfono solo debe tener números';
        return false;
      }
      if(phone.length < 8){
        phoneErrorMessage = 'El teléfono debe tener 8 dígitos';
        return false;
      }
      return true;
    },
  }
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmited(true);
    if(validations.isValidName() &&
      validations.isValidUserName() &&
      validations.isValidPassword() &&
      validations.isValidConfirmPassword() &&
      validations.isValidPhone()
    ) {
      props.onSubmit({
        name,
        username: userName,
        password,
        familyName: name,
        phone: phone,
        confirmPassword,
      });
    }
  }
  return (
    <>
    <div className="card-body">
      <form onSubmit={handleSubmit}>
        <div className="form-group" >
          <label 
            className="small mb-1" 
            htmlFor="inputFirstName">Nombre</label>
          <input 
            className="form-control py-4" 
            id="inputFirstName" 
            type="text" 
            placeholder="Ingresar Nombre Completo"
            value={name}
            onChange={(event)=>{setName(event.target.value)}}
            style={submited && !validations.isValidName() ? errorStyle : {}} />
          {
            nameErrorMessage ? 
            <h4 className="text-center red" >
              {nameErrorMessage}
            </h4>
            : null
          }
        </div>
        <div className="form-group">
          <label 
            className="small mb-1" 
            htmlFor="inputUserName">Usuario</label>
          <input 
            className="form-control py-4" 
            id="inputUserName"
            type="text"
            aria-describedby="emailHelp" 
            placeholder="Ingresar Usuario"
            value={userName}
            onChange={(event)=>{setUserName(event.target.value)}}
            style={submited && !validations.isValidUserName() ? errorStyle : {}} 
          />
          {
            userNameErrorMessage ? 
            <h4 className="text-center red" >
              {userNameErrorMessage}
            </h4>
            : null
          }
        </div>
        <div className="form-group">
          <label 
            className="small mb-1" 
            htmlFor="inputPassword">Contraseña</label>
          <input 
            className="form-control py-4" 
            id="inputPassword" 
            type="password" 
            placeholder="Ingresar Contraseña"
            value={password}
            onChange={(event)=>{setPassword(event.target.value)}}
            style={submited && !validations.isValidPassword() ? errorStyle : {}} 
          />
          {
            passwordErrorMessage ? 
            <h4 className="text-center red" >
              {passwordErrorMessage}
            </h4>
            : null
          }
        </div>
        <div className="form-group">
          <label 
            className="small mb-1" 
            htmlFor="inputConfirmPassword">Confirmar Contraseña</label>
          <input 
            className="form-control py-4" 
            id="inputConfirmPassword" 
            type="password" 
            placeholder="Confirmar Contraseña"
            value={confirmPassword}
            onChange={(event)=>{setConfirmPassword(event.target.value)}}
            style={submited && !validations.isValidConfirmPassword() ? errorStyle : {}} 
          />
          {
            confirmPasswordErrorMessage ? 
            <h4 className="text-center red" >
              {confirmPasswordErrorMessage}
            </h4>
            : null
          }
        </div>
        <div className="form-group">
          <label 
            className="small mb-1" 
            htmlFor="inputPhone">Teléfono</label>
          <input 
            className="form-control py-4" 
            id="inputPhone" 
            type="text" 
            maxLength={8}
            placeholder="Ingresar Teléfono"
            value={phone}
            onChange={(event)=>setPhone(event.target.value)}
            style={submited && !validations.isValidPhone() ? errorStyle : {}}  />
            {
            phoneErrorMessage ? 
            <h4 className="text-center red" >
              {phoneErrorMessage}
            </h4>
            : null
          }
        </div>
        <div className="form-group mt-4 mb-0">
          <button className="btn btn-primary btn-block btn-lg">Crear Cuenta</button>
        </div>
        {props.error ? 
            <div className="mt-4 mb-0">
              <h4 className="text-center font-weight-extrabold red" >{props.error}</h4>
            </div>
          : null
          }
      </form>
    </div>
    <div className="card-footer text-center">
      <div className="small">
        <NavLink to="/login">
          Regresar
        </NavLink>
      </div>
  </div>
</>
  )
}

export default RegisterForm;