import { gql } from '@apollo/client';
const ADD_FINCA = gql`
mutation add_finca(
    $tipo_finca:TipoFinca!
    $nombre: String!
    $latitud: Float!
    $longitud: Float!
    $aldea_n:Float!
    $area: Float!
    $lote: String!
    $tipo_huerta:TipoHuerta!
    $variedad: String!
    $porcentaje_sombra:Float!
    $densidad_arboles_ha:Float!
    $superficie: Float!
    $altura: Float!
    $anio_plantacion: Float!
    $tipo_cafe: TipoCafe!
    $tecnico:Float!
    $propietario:Float!
    $productor: Float!
    $institucion: Float!
) {
  add_finca(Fincas: {
    tipo_finca:$tipo_finca
    nombre:$nombre
    latitud:$latitud
    longitud:$longitud
    aldea_n:$aldea_n
    area:$area
    lote:$lote
    tipo_huerta:$tipo_huerta
    variedad:$variedad
    porcentaje_sombra:$porcentaje_sombra
    densidad_arboles_ha:$densidad_arboles_ha
    superficie:$superficie
    altura:$altura
    anio_plantacion:$anio_plantacion
    tipo_cafe:$tipo_cafe
    tecnico:$tecnico
    propietario:$propietario
    productor:$productor
    institucion: $institucion
  }){
    id
    nombre
    tipo_finca
    latitud
    longitud
    area
    aldea_n{
      nombre
    }
    lote
    tipo_huerta
    variedad
    porcentaje_sombra
    densidad_arboles_ha
    superficie
    altura
    anio_plantacion
    tipo_cafe
    propietario{
      id
      name
    } 
    productor{
      id
    }
    }
    
}`;

export default ADD_FINCA;