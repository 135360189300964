import {gql} from '@apollo/client';

const ASSOCIATE_FINCA = gql`
mutation associateFinca(
    $id: Float!
    $tecnico: Float!
    $accion: String!
  ){
    associateFinca(
      AssociateData:{
        id: $id
        tecnico: $tecnico
        accion: $accion      
      }
    ){
      nombre
      id
      tecnico{
        name
        username
      }
    }
  }
`;

export default ASSOCIATE_FINCA;