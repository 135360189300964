import BasicLayout from 'src/layout/Basic';
const ApplicacionesMoviles = () => {
  const sizeRule = {minHeight: '41rem'}
  return (
    <BasicLayout>
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><a href="homepage.html">Inicio</a></li>
            <li className="breadcrumb-item active">Herramientas</li>
          </ol>
          <h1 className="mt-4">Aplicaciones Móviles</h1>
          <div className="row">
            <div className="col-lg-4" style={{display: 'none'}}>
              <div className="card col-md-12 my-4 card-apps">
                <div className="card-body">
                  <h4 className="font-weight-extrabold my-4">Aplicación Nueva</h4>
                  <p>Ahora es posible elegir diferentes plagas (roya, broca, ojo de gallo, antracnosis y mancha de hierro) para realizar el muestreo</p><span className="red font-weight-extrabold">Versión 2.1.0</span>
                  <div className="row mt-4"><a className="col-lg-6" href="#"><img className="img-fluid" src="images/logo-app-store.png" /></a><a className="col-lg-6" href="#"><img className="img-fluid" src="images/logo-google-play.png" /></a></div>
                  </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card col-md-12 my-4" style={sizeRule}>
                    <div className="card-body">
                      <h4 className="font-weight-extrabold my-4 card-title">Pasos para instalar la aplicación en IOS</h4>
                      <p>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">En su correo recibirá una invitación para poder descargar el app.</li>
                          <li className="list-group-item">Aceptar la invitación y crear cuenta.</li>
                          <li className="list-group-item">Entrar desde Safari a <a href="https://install.appcenter.ms/apps">https://install.appcenter.ms/apps</a></li>
                          <li className="list-group-item">Ir hacia <a href="https://install.appcenter.ms/profile">https://install.appcenter.ms/profile</a> o desde la página principal seleccionar el icono superior derecho.</li>
                          <li className="list-group-item">Seleccionar agregar dispositivo, esto descargará un archivo.</li>
                          <li className="list-group-item">En el iPhone ir a Ajustes -&gt; General -&gt; Perfil &amp; Device Management -&gt; Install perfil y seleccionar Permitir.</li>
                          <li className="list-group-item">Regresar hacia <a href="https://install.appcenter.ms/apps">https://install.appcenter.ms/apps</a></li>
                          <li className="list-group-item">Seleccionar la app SATIHCAFE-iOS.</li>
                          <li className="list-group-item">Seleccionar en instalar.</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card col-md-12 my-4" style={sizeRule}>
                    <div className="card-body">
                      <h4 className="font-weight-extrabold my-4 card-title">Pasos para instalar la aplicación en Android</h4>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">En su correo recibirá una invitación para poder descargar el app.</li>
                        <li className="list-group-item">Aceptar la invitación y crear cuenta.</li>
                        <li className="list-group-item">Descargar aplicación Visual Studio App Center - Aplicaciones en Google Play en el celular.</li>
                        <li className="list-group-item">Autenticar con la cuenta creada.</li>
                        <li className="list-group-item">Seleccionar app SATIHCAFE.</li>
                        <li className="list-group-item">Seleccionar descargar.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </main>
  </BasicLayout>
  )
};

export default ApplicacionesMoviles;