import { gql } from "@apollo/client";

const GET_MUESTREO = gql`
query getMuestreoInfo($id: Float!){
  getMuestreo(MuestreoId:$id){
    id
    fecha
    fenologia
    bandolas
    finca{
      nombre
    }
    metadata
    muestreo_detail{
     id
     tipo_plaga_enfermedad
      puntos
      tipo
      total
      positivos
      incidencia
      registros
    }
  }
}
`;

export default GET_MUESTREO;