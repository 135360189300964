import BasicLayout from 'src/layout/Basic';
import PowerBiReport from 'src/components/PowerBiReport';

const ReporteAltitudes = () => {
  const reporte = process.env.REACT_APP_REPORT as string;
  return (
    <BasicLayout>
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><a href="homepage.html">Inicio</a></li>
            <li className="breadcrumb-item active">Reportes</li>
          </ol>
          <h1 className="mt-4">Reporte Altitudes</h1>
          <div className="card-body">
          <PowerBiReport 
            className="general-report"
            pageName="Altitudes"
            reportName={reporte}
          /> 
          </div>
            </div>
        </main>
  </BasicLayout>
  )
};

export default ReporteAltitudes;