import { gql } from "@apollo/client";

const CONFIRM = gql`
mutation Confirm(
  $name: String!
  $code: String!
){
  confirm(AuthConfirm: {
    name: $name
    code: $code
  })
}
`;

export default CONFIRM;