import { 
  AuthState, AuthActionTypes, AUTH_START, AUTH_SUCCESS, 
  AuthStartAction, AuthSuccessAction, AUTH_FAIL, AuthFailAction, 
  AUTH_LOGOUT, AuthLogoutAction, AuthStartApp, AUTH_START_APP, 
  AuthStep, AUTH_STEP 
} from '../types/auth.types';

const initialState : AuthState = {
  idToken: '',
  accessToken: '',
  refreshToken: '',
  userId: '',
  error: '',
  loading: false,
  startApp: true,
  authRedirectPath: '/',
  step: '',
}

const authStartApp = (state: AuthState, _action: AuthStartApp) : AuthState => {
  return {
    ...state,
    startApp: false,
  }
}

const authStart = ( state : AuthState, _action: AuthStartAction) : AuthState => {
  return {
    ...state, 
    ...{ error: '', loading: true }
  }
};

const authSuccess = (state: AuthState, action: AuthSuccessAction) : AuthState => {
  return {
    ...state,
    ...{
      idToken: action.tokenInfo.idToken,
      accessToken: action.tokenInfo.accessToken,
      refreshToken: action.tokenInfo.refreshToken,
      userId: action.tokenInfo.userId,
      loading: false,
      authRedirectPath: '/'
    }
  }
}

const authFail = (state: AuthState, action: AuthFailAction) : AuthState => {
  const { error } = action;
  return {
    ...state,
    ...{
      error,
      loading: false
    }
  }
}

const authLogout = ( state: AuthState, action: AuthLogoutAction) : AuthState => {
  return {
    ...state,
    ...{
      idToken: '',
      accessToken: '',
      refreshToken: '',
      userId: '',
    }
  }
}

const authStep = (state: AuthState, action: AuthStep): AuthState => {
  return {
    ...state,
    ...{
      step: action.step
    }
  }
}

const reducer = (state = initialState, action: AuthActionTypes) : AuthState => {
  switch(action.type){
    case AUTH_START: return authStart(state, action);
    case AUTH_SUCCESS: return authSuccess(state,action);
    case AUTH_FAIL: return authFail(state, action);
    case AUTH_LOGOUT: return authLogout(state, action);
    case AUTH_START_APP: return authStartApp(state, action);
    case AUTH_STEP: return authStep(state, action);
    default:
      return state;
  }
}

export default reducer;