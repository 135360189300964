import { gql } from '@apollo/client';
const ADD_ALDEA = gql`
mutation Add_Aldea(
    $nombre:String!
    $municipio:Float!
){
    Add_Aldea(Aldea:{
      nombre:$nombre
      municipio:$municipio
    }){
      id
      nombre
      municipios{
        nombre
      }
    }
  }
`; 

export default ADD_ALDEA;