import { Redirect, useLocation, Route } from "react-router-dom";
import useReportSSO from "src/hooks/reportSSO";
import { useDispatch } from 'react-redux';
import { useEffect } from "react";
import { AuthSuccess } from 'src/store/types/auth.types';
import { authCheckState, authFail, authSuccess } from "src/store/actions";
import LoginLayout from 'src/layout/Login';
import LoginHeader from 'src/components/Auth/Header';
import ErrorImage from 'src/assets/images/Alert.png';
import { Link } from 'react-router-dom';
import PreLoaderImage from 'src/assets/images/Preloader.gif';
import { userSetLevel } from "src/store/actions/user";

const ReportSSO = () => {
  const search = useLocation().search;
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(search);
  const redirectUrl = searchParams.get('redirect_url') || '';
  const userName = searchParams.get('user_name') || '';
  const refreshToken = searchParams.get('token') || '';
  const {
    data,
    error,
    loading,
  } = useReportSSO(userName, refreshToken);

  useEffect(() => {
    if (data) {
      const tokenData = {
        accessToken: data.refreshToken.accessToken,
        idToken: data.refreshToken.idToken,
        refreshToken: data.refreshToken.refreshToken,
        userId: userName,
      } as AuthSuccess;
      dispatch(authSuccess(tokenData));
      dispatch(authCheckState());
      dispatch(userSetLevel('Tecnico'));
    }
    if (error) {
      dispatch(authFail(error.message));
    }
  }, [data, error]);


  if (loading) {
    return (
      <LoginLayout>
      <div className="card shadow-lg border-0 rounded-lg mt-5">
        <LoginHeader
          title="Bienvenido a SATIHCAFE" 
          description="Cargando su reporte espere un momento" />
        <div className="card-body">
          <img src={PreLoaderImage} alt="loading" style={{ width: '100%'}}/>
        </div>
      </div>
    </LoginLayout>
    );
  }

  if (error) {
    return (
      <LoginLayout>
        <div className="card shadow-lg border-0 rounded-lg mt-5">
          <LoginHeader
            title="Algo ha pasado al cargar su reporte"/>
              <div className="card-body">
                <div style={{ textAlign: 'center' }}>
                  <div>
                    <h5 className="font-weight-extrabold col-lg-12">
                      El reporte no se ha podido cargar correctamente.<br></br>
                      <Link to="/">Volver a la página Principal</Link> o recarga la página</h5>
                  </div>
                  <img src={ErrorImage} alt="error" style={{ height: '70px' }} />
                </div>
              </div>
        </div>
      </LoginLayout>
    );
  }

  if (data) {
    return (
      <Redirect to={{
        pathname: redirectUrl
      }}></Redirect>
    );
  }

  return null;
};

export default ReportSSO;