import { gql } from '@apollo/client';
const GET_ALLFINCAS = gql`
query findFincasFilter(
  $skip: Float!
  $take: Float!
  $parametro: String!
){
  findFincasFilter(Filter:{
      skip:$skip
      take:$take
    	parametro: $parametro
    }
  ){
    cantidad
      fincas{
        id
        tipo_finca
        nombre
        latitud
        longitud
        area
        lote
        aldea_n{
          id
          nombre
          municipios{
            id
            nombre
            departamento{
              id
              nombre
            }
          }
        } 
        tipo_huerta
        variedad
        porcentaje_sombra
        densidad_arboles_ha
        superficie
        altura
        anio_plantacion
        tipo_cafe
        tecnico{
          id
          name
        }
        propietario{
          id
          name
        }
        productor{
          id
          name
        }
    }
	}
}
`;
   export default GET_ALLFINCAS;
  

  