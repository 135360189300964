import BasicLayout from 'src/layout/Basic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faTrashAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link,useHistory, useLocation } from 'react-router-dom';
import useGetMuestreo from 'src/hooks/getMuestreo';
import PreLoaderImage from 'src/assets/images/Preloader.gif';
import MuestreoHeader from 'src/components/Muestreos/MuestreoHeader';
import MuestreoList from 'src/components/Muestreos/MuestreoList';
import MuestreoForm from 'src/components/Muestreos/MuestreoForm';
import { useEffect, useState } from 'react';
import {
  getMuestreoInfo_getMuestreo_muestreo_detail as MuestreoType,
  getMuestreoInfo
} from 'src/apollo/queries/types/getMuestreoInfo';
import { useMutation } from '@apollo/client';
import { updateMuestreoDetail, updateMuestreoDetailVariables } from 'src/apollo/queries/types/updateMuestreoDetail';
import UPDATE_MUESTREO_DETAIL from 'src/apollo/queries/updateMuestreoDetail';
import GET_MUESTREO from 'src/apollo/queries/getMuestreo';
import DELETE_MUESTREO from 'src/apollo/queries/deleteMuestreo';
import MuestreoGrilloForm from 'src/components/Muestreos/MuestreoGrilloForm';
import MuestreoTable from 'src/components/Muestreos/MuestreoTable';
import { Modal} from '@material-ui/core';

const EditMuestreos = () => {
  const search = useLocation().search;
  const idMuestreo = new URLSearchParams(search).get('id');
  const { data, loading, error } = useGetMuestreo(Number(idMuestreo));
  const history = useHistory();
  const [modalEliminar, setModalEliminar] = useState(false);
  const [deleteMuestreo, {error:errorDelete, loading:loadinDelete}]= useMutation(DELETE_MUESTREO,{variables:{MuestreoId:Number(idMuestreo)}})
  const [updateRegistros, {
    error: errorRegistros,
    data: dataRegistros,
    loading: loadingRegistros,
  }] = useMutation<updateMuestreoDetail, updateMuestreoDetailVariables>(
    UPDATE_MUESTREO_DETAIL,
    {
      onError() { },
      update: (cache, { data }) => {
        const muestreoData = cache.readQuery<getMuestreoInfo>(({
          query: GET_MUESTREO,
          variables: {
            id: Number(idMuestreo)
          }
        }));

        if (muestreoData) {
          cache.writeQuery<getMuestreoInfo>({
            query: GET_MUESTREO,
            data: {
              getMuestreo: {
                ...muestreoData.getMuestreo,
                muestreo_detail: muestreoData!.getMuestreo.muestreo_detail.map((muestreo) => {
                  if (muestreo.id === data?.updateMuestreoDetail.id) {
                    return data?.updateMuestreoDetail;
                  }
                  return muestreo;
                })
              },
            },
          });
        }

      }
    }
  );
  const [muestreo, setMuestreo] = useState<any>();
  const [planta, setPlanta] = useState(0);

  useEffect(() => {
    if (data) {
      setMuestreo(data as unknown as getMuestreoInfo);
    }
  }, [data]);
  const onSaveForm = (data: any) => {
    if (muestreo?.getMuestreo.muestreo_detail[0].tipo_plaga_enfermedad === 'Grillo Indiano') {
      updateGrilloInformation(data);
    } else {
      updateInformation(data);
    }
  }

  const updateInformation = (data: any) => {
    let currentMuestreo: getMuestreoInfo = { ...muestreo };
    const detailInfo = currentMuestreo.getMuestreo.muestreo_detail.map((detail: MuestreoType) => {
      const registers = [...detail.registros];
      if (data[detail.id]) {
        registers[planta] = { ...data[detail.id] };
      }

      return {
        ...detail,
        registros: [
          ...registers,
        ]
      };
    });
    const calculatedValues = detailInfo.map((muestreo: MuestreoType) => {
      const newMuestreo = { ...muestreo };
      const total = muestreo.registros.reduce((ac2: number, value: any) => {
        if (currentMuestreo.getMuestreo.bandolas > 1) {
          return ac2 + value.bandola_1.total + value.bandola_2.total;
        }
        return ac2 + value.bandola_1.total;
      }, 0);
      const positivos = muestreo.registros.reduce((ac2: number, value: any) => {
        if (currentMuestreo.getMuestreo.bandolas > 1) {
          return ac2 + value.bandola_1.positivo + value.bandola_2.positivo;
        }
        return ac2 + value.bandola_1.positivo;
      }, 0);
      newMuestreo.total = total;
      newMuestreo.positivos = positivos;
      newMuestreo.incidencia = (positivos / total) * 100;
      return { ...newMuestreo };
    }, 0);

    const submitMuestreo = {
      ...currentMuestreo,
      getMuestreo: {
        ...currentMuestreo.getMuestreo,
        muestreo_detail: calculatedValues
      },
    };
    setMuestreo(submitMuestreo);
    submitChanges(submitMuestreo);
  };

  const updateGrilloInformation = (data: any) => {
    let currentMuestreo: getMuestreoInfo = { ...muestreo };
    const detailInfo = currentMuestreo.getMuestreo.muestreo_detail.map((detail: MuestreoType) => {
      const registers = data.map((registro: any) => {
        return {
          perforaciones: Number(registro.perforaciones)
        }
      });
      const positivos = registers.reduce((ac2: number, value: any) => {
        return ac2 + Number(value.perforaciones);
      }, 0);
      const newDetail = {
        ...detail,
        positivos,
        total: 60,
        puntos: 30,
        incidencia: (positivos / 60) * 100
      };
      return {
        ...newDetail,
        registros: [
          ...registers,
        ]
      };
    });

    const submitMuestreo = {
      ...currentMuestreo,
      getMuestreo: {
        ...currentMuestreo.getMuestreo,
        muestreo_detail: [...detailInfo]
      },
    };

    setMuestreo(submitMuestreo);
    submitChanges(submitMuestreo);
  };

  const submitChanges = (muestreo: getMuestreoInfo) => {
    muestreo.getMuestreo.muestreo_detail.forEach((detail: MuestreoType) => {
      const { id, registros, incidencia, positivos, total } = detail;
      updateRegistros({
        variables: {
          id,
          registros,
          incidencia,
          positivos,
          total
        },
      })
    });
  };
  
  const abrirCerrarModalEliminar = () => {    
    setModalEliminar(!modalEliminar);
  }
  const eliminar = async () =>{
    try{
      await deleteMuestreo();
      abrirCerrarModalEliminar();
      history.push('/herramienta-registros')
    }catch(e){
      console.log(e.message);
    }
  }
  const bodyEliminar=()=>{
    if(!loadinDelete){
      return(
        <div className="col-10 col-lg-6 col-xl-4 p-4 mx-auto my-auto"> 
          <div className="card">
            <div className="card-header pt-0 text-right">
              <a 
                style={{cursor: 'pointer'}} 
                onClick={(e)=>{
                e.preventDefault();
                abrirCerrarModalEliminar();
                }}
              >
                <FontAwesomeIcon icon={ faTimes }/>
              </a>
            </div>
            <div className="card-body text-center">  
              <label htmlFor="nombre" className="" style={{color: 'rgb(0,176,145)', fontSize:'10'}}>¿Esta seguro que desea eliminar este muestreo?</label>
              <br />
              <footer style={{ paddingTop: '21px', marginLeft: 'auto' ,marginTop:20}}>
                <button id="aceptar" onClick={()=>eliminar()}    className="btn btn-aceptar btn-block btn-sm">
                  Aceptar
                </button>
                <button className="btn btn-cancelar btn-block btn-sm"  onClick={() => abrirCerrarModalEliminar()}>Cancelar</button>
              </footer>
            </div>
          </div>
        </div>
      )
    }else{
      return(
        <div className="col-10 col-lg-6 col-xl-4 p-4 mx-auto my-auto"> 
          <div className="card">        
            <div className="card-body">
              <img src={PreLoaderImage} alt="loading" style={{ width: '100%'}}/>
            </div>
          </div>
        </div>
      );
    }
  }
  if (loading) {
    return (
      <BasicLayout>
        <main>
          <div className="col-lg-12">
            <div className="card-body" style={{ textAlign: 'center' }}>
              <img src={PreLoaderImage} alt="loading" style={{ height: '200px' }} />
            </div>
          </div>
        </main>
      </BasicLayout>
    );
  }

  if (error) {
    return (
      <BasicLayout>
        <main>
          <div className="col-lg-12">
            <div className="card-body" style={{ textAlign: 'center' }}>
              <div>Algo ha ocurrido por favor intente denuevo!</div>
            </div>
          </div>
        </main>
      </BasicLayout>
    );
  }

  if (muestreo) {
    return (
      <BasicLayout>
        <main>
          <div className="container-fluid">
            <ol className="breadcrumb mb-4">
              <li className="breadcrumb-item">
                <Link to="/">
                  Inicio
            </Link>
              </li>
              <li className="breadcrumb-item active">Herramientas</li>
              <li className="breadcrumb-item">
                <Link to="/herramienta-registros">
                  Registro Historial
            </Link>
              </li>
            </ol>
            <div className="row">
              <h1 className="col-lg-8 mt-4">Editar Muestreo</h1>
              <div className="col-lg-2">
                <Link
                  className="nav-link btn btn-aceptar btn-sm mb-4"
                  to="/herramienta-registros">
                  <FontAwesomeIcon icon={faArrowAltCircleLeft}></FontAwesomeIcon>
                  <span className="ml-2 d-none d-lg-inline">Regresar</span>
                </Link>
              </div>
              <div className="col-lg-2">
                <button className="nav-link btn btn-cancelar btn-sm mb-4 w-100"  onClick={() => abrirCerrarModalEliminar()}>
                  <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                  <span className="ml-2 d-none d-lg-inline">Eliminar</span>
                </button>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card col-md-12 card-muestreos">
                <div className="card-body">
                  <div className="col-lg-12">
                    <div className="card card-finca-table mb-4 table-responsive">
                      <div className="card-body">
                        <MuestreoHeader muestreo={muestreo} />
                      </div>
                    </div>
                    {muestreo.getMuestreo.muestreo_detail[0].tipo_plaga_enfermedad === 'Grillo Indiano' ?
                      <>
                        <MuestreoTable
                          muestreoDetail={muestreo.getMuestreo.muestreo_detail}
                        />
                        <MuestreoGrilloForm
                          muestreoDetail={muestreo.getMuestreo.muestreo_detail[0]}
                          onSaveForm={onSaveForm}
                          savingStatus={loadingRegistros}
                        />
                      </> :
                      <div className="row">
                        <MuestreoList
                          muestreoDetail={muestreo.getMuestreo.muestreo_detail}
                          onChangePlanta={(planta) => setPlanta(planta)}
                        />
                        <MuestreoForm
                          muestreoDetail={muestreo.getMuestreo.muestreo_detail}
                          bandolas={muestreo.getMuestreo.bandolas}
                          plantaIndex={planta}
                          onSaveForm={onSaveForm}
                          savingStatus={loadingRegistros}
                        />
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Modal open={modalEliminar} onClose={abrirCerrarModalEliminar}>{bodyEliminar()}</Modal>
      </BasicLayout>
    );
  }

  return null;
};

export default EditMuestreos;