import BasicLayout from '../layout/Basic';
import BannerHomePage from '../assets/images/banner-homepage.jpg';
const Home = () => {
  return (
    <BasicLayout>
      <div>
        <main>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <img alt="" className="banner-homepage" src={BannerHomePage} /></div>
            </div>
          </div>
        </main>
      </div>
    </BasicLayout>
  )
};

export default Home;