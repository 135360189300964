import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import LoginHeader from 'src/components/Auth/Header';
import LoginLayout from 'src/layout/Login';
import PreLoaderImage from 'src/assets/images/Preloader.gif';
import SuccessForm from 'src/components/Auth/Success';
import FORGOT_PASSWORD from 'src/apollo/queries/forgotPassword';
import { ForgotPassword as ForgotPasswordQuery, ForgotPasswordVariables } from 'src/apollo/queries/types/ForgotPassword';
import { ConfirmPassword, ConfirmPasswordVariables } from 'src/apollo/queries/types/ConfirmPassword';
import CONFIRM_PASSWORD from 'src/apollo/queries/confirmPassword';
import ForgotPasswordForm from 'src/components/Auth/ForgotPasswordForm';
import { ForgotInformationType } from 'src/models/Auth';

const initialUserInformation = {
  username: '',
  code: '',
  newPassword: '',
  confirmPassword: '',
} as ForgotInformationType;

const ForgotPassword = () => {
  const [step, setStep] = useState('forgot');
  const [userInformation, setUserInformation] = useState({ ...initialUserInformation});
  const [initForgot, setInitForgot] = useState(false);
  const [initConfirm, setInitConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [sendForgot, {
    error: errorForgot, 
    data: dataForgot, 
    loading: loadingForgot
  }] = useMutation<ForgotPasswordQuery, ForgotPasswordVariables>(
    FORGOT_PASSWORD,
    {
      variables: {
        username: userInformation.username,
      },
      onError(){},
    }
  )

  const [sendConfirmForgot, {
      error: errorConfirmPassword, 
      data: dataConfirmPassword, 
      loading: loadingConfirmPassword
    }] = useMutation<ConfirmPassword, ConfirmPasswordVariables>(
    CONFIRM_PASSWORD,
    {
      variables: {
        username: userInformation.username,
        newPassword: userInformation.newPassword,
        code: userInformation.code
      },
      onError(){},
    }
  )

  useEffect(()=>{
      if(dataForgot){
        setStep('confirm');
      }
      if(errorForgot){
        setErrorMessage(errorForgot.message);
      }
      setInitForgot(false); 
  }, [dataForgot, errorForgot]);

  useEffect(()=>{
    if(dataConfirmPassword){
      setStep('finish');
    }
    if(errorConfirmPassword){
      setErrorMessage(errorConfirmPassword.message);
    }
    setInitConfirm(false);
  }, [dataConfirmPassword, errorConfirmPassword]);


  useEffect(()=>{
    if(initForgot){
      sendForgot();
    }
  }, [initForgot, sendForgot])

  useEffect(()=>{
    if(initConfirm){
      sendConfirmForgot();
    }
  }, [initConfirm, sendConfirmForgot])

  const onSubmit = (userInformation: ForgotInformationType) : void => {
    const { username, newPassword, confirmPassword, code } = userInformation;
    setUserInformation({
      username,
      newPassword,
      confirmPassword,
      code,
    });
    if(step === 'forgot'){
      setInitForgot(true);
    }
    if(step === 'confirm'){
      setInitConfirm(true);
    }
  };

  const onSendAnotherCode = () : void => {
    setInitForgot(true);
  }

  const onChangeStep = (step: string) : void => {
    setStep(step);
  }

  if(loadingForgot || loadingConfirmPassword){
    return (
      <LoginLayout>
      <div className="card shadow-lg border-0 rounded-lg mt-5">
          <LoginHeader
            title="Cambiar Contraseña" />
        <div className="card-body">
          <img src={PreLoaderImage} alt="loading" style={{ width: '100%'}}/>
        </div>
      </div>
    </LoginLayout>
    )
  }
  return (
    <LoginLayout>
      <div className="card shadow-lg border-0 rounded-lg mt-5">
          <LoginHeader
            title="Cambiar Contraseña" />
          {step === 'forgot' || step === 'confirm' ? 
            <ForgotPasswordForm 
              userInformation={userInformation} 
              onSubmit={onSubmit}
              onSendAnotherCode={onSendAnotherCode} 
              onSendStep={onChangeStep}
              error={errorMessage}
              step={step}/> 
          : null}
          {step === 'finish' ? 
            <SuccessForm title="La contraseña ha sido reestablecida correctamente!"></SuccessForm>
          : null}
      </div>
    </LoginLayout>
  )
}

export default ForgotPassword;