import BasicLayout from 'src/layout/Basic';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import client from 'src/apollo';
import FIND_INSTITUCION from 'src/apollo/queries/findInstitucion';
import FINCAS_BY_INSTITUCION from 'src/apollo/queries/findFincasByInstitucion';
import ASSOCIATE_FINCAS from 'src/apollo/queries/AssociateFinca';
import GET_TECNICOS from 'src/apollo/queries/getTecnicos';
import { useEffect, useState } from 'react';
import PreLoaderImage from 'src/assets/images/Preloader.gif';
import { onError } from '@apollo/client/link/error';

const initialIstitucion = {
  nombre: '',
  pais: '',
  value: 0
}

const initialFinca = {
  nombre: '',
  value: 0,
  id: '',
  tecnico: {
    id:'',
    name: ''
  }
}

const initialTecnico = {
  username: '',
  name: ''
}

const AsociarFincas = () => {
  const [instituciones, setInstituciones] = useState([initialIstitucion]);
  const [fincas, setFincas] = useState([initialFinca]);
  const [fincaSeleccionada, setFincaSeleccionada] = useState(0);
  const [institucionSeleccionada, setInstitucionSeleccionada] = useState(0);
  const [tecnicos, setTecnicos] = useState([initialTecnico]);
  const [tecnicoSeleccionado, setTecnicoSeleccionado] = useState(0);
  const [institucionSelected, setInstitucionSelected] = useState(false);
  const [isntitucionesErrorMessage, setInstitucionesErrorMessage] = useState('');
  const [fincasErrorMessage, setFincasErrorMessage] = useState('');
  const [fincaSelected, setFincaSelected] = useState(false);
  const [tecnicoSelected, setTecnicoSelected] = useState(false);
  const [associateSuccess, setAssociateSuccess] = useState(false);
  const [tecnicosErrorMessage, setTecnicosErrorMessage] = useState('');
  const [associateErrorMessage, setAssociateErrorMessage] = useState('');
  const [acciones, setAcciones] = useState('');
  const [prevAccion, setPrevAccion] = useState('');
  const { loading: loadingInstituciones, error: errorInstituciones, data: dataInstituciones } = useQuery(FIND_INSTITUCION, {
    onError(){
      setInstitucionesErrorMessage("Se produjo un error al intentar obtener la información de las instituciones");
    }});  
  const [getFincas, {loading: loadingFincas, called: calledFincas, data: dataFincas, error: errorFincas}] = useLazyQuery(FINCAS_BY_INSTITUCION, {variables: {institucionId: Number(institucionSeleccionada)}, 
    onError(){
      setFincasErrorMessage("Se produjo un error al intentar obtener la información de las fincas")
    }});
  const [getTecnicos, {loading: loadingTecnicos, called: calledTecnicos, data: dataTecnicos, error: errorTecnicos}] = useLazyQuery(GET_TECNICOS,{
    onError(){
      setTecnicosErrorMessage("Se produjo un error al intentar obtener la información de los técnicos");
    }});
  const [associateFinca, {loading: loadingAssociate, called: calledAssociate, data: dataAssociate, error: errorAssociate}] = useMutation(ASSOCIATE_FINCAS, {variables: {id: Number(fincaSeleccionada), tecnico: Number(tecnicoSeleccionado), accion: acciones}, 
    onError(){
      setAssociateErrorMessage("Se produjo un error al intentar realizar la acción")
    }});
  
  const startAssoc =  async () => {
    await associateFinca();
  }

  useEffect(()=>{
    if(!loadingInstituciones && dataInstituciones){
      setInstituciones(dataInstituciones.findAllInstitucion);
      setInstitucionSeleccionada(dataInstituciones.findAllInstitucion[0].id);
      setInstitucionSelected(true);
      setInstitucionesErrorMessage('');
    }
  },[loadingInstituciones, dataInstituciones]);

  useEffect(()=>{
    if(institucionSelected){
      getFincas();
      setInstitucionSelected(false);
    }
  },[institucionSelected]);

  useEffect(()=>{
    if(!loadingFincas && dataFincas){
      setFincas(dataFincas.absoluteFincas);
      setFincaSeleccionada(dataFincas.absoluteFincas[0].id);
      setFincaSelected(true);
      setFincasErrorMessage('');
      getTecnicos();
    }
  },[dataFincas, loadingFincas]);

  useEffect(()=>{
    if(!loadingTecnicos && dataTecnicos){
      setTecnicos(dataTecnicos.findUsersByLevel);
      setTecnicoSeleccionado(dataTecnicos.findUsersByLevel[0].id);
      setTecnicosErrorMessage('');
      setTecnicoSelected(true);
    }
  }, [loadingTecnicos, dataTecnicos]);

  useEffect(()=>{
    if((fincaSelected || tecnicoSelected) && (fincaSeleccionada!==0 && tecnicoSeleccionado!==0)){
      let finca2filter = fincas;
      finca2filter = fincas.filter(item => item.id === String(fincaSeleccionada));
      if(!finca2filter[0].tecnico){
        setAcciones('Asociar');
      }
     else if(finca2filter[0].tecnico.id===String(tecnicoSeleccionado)){
        setAcciones('Desasociar');
      }
      else{
        setAcciones('Asociar');
      }
      setFincaSelected(false);
      setTecnicoSelected(false);
    }
  }, [fincaSelected, tecnicoSelected, fincas, fincaSeleccionada, tecnicoSeleccionado]);

  useEffect(()=>{
    if(!loadingAssociate && dataAssociate){      
      setAssociateErrorMessage('');
      setAssociateSuccess(true);
      setPrevAccion(acciones);
      client.resetStore();
    }
  }, [loadingAssociate, dataAssociate]);

  useEffect(()=>{
    if(associateSuccess){
      setTimeout(()=>{
        setAssociateSuccess(false);
      },3000);
    }
  }, [associateSuccess]);

  const bodyAsociar = () => {
    if(loadingInstituciones || loadingFincas || loadingAssociate){
      return(
        <div className="card-body">
            <img src={PreLoaderImage} alt="loading" style={{ width: '100%'}}/>
        </div>          
      );
    }
    else{
      return(
        <div className="card-body">
          <div className="form-group">
            <label className="small mb-1" htmlFor="inputCountry">País:</label>
            <select className="form-control custom-select" onChange={(e)=>{
              setInstitucionSeleccionada(Number(e.target.value));
              setInstitucionSelected(true);
            }}>
              {
                instituciones.map((institucion: any) => {
                  return(
                    <option value={institucion.value} key={institucion.value}>
                      {institucion.pais} | {institucion.nombre}
                    </option>
                  );
                })
              }
            </select>
            <h4 className="text-center red" >
              {isntitucionesErrorMessage}
            </h4>
          </div>
          <div className="form-group">
            <label className="small mb-1" htmlFor="inputCountry">Finca:</label>
            <select className="form-control custom-select" onChange={(e)=>{
              setFincaSeleccionada(Number(e.target.value));
              setFincaSelected(true);
            }}>
              {
                fincas.map((finca: any) => {
                  return(
                    <option value={finca.id} key={finca.id}>
                      {finca.nombre}
                    </option>
                  );
                })
              }
            </select>
            <h4 className="text-center red" >
              {fincasErrorMessage}
            </h4>
          </div>
          <div className="form-group">
            <label className="small mb-1" htmlFor="inputCountry">Técnico:</label>
            <select className="form-control custom-select" onChange={(e)=>{
              setTecnicoSeleccionado(Number(e.target.value));
              setTecnicoSelected(true);
              }}>
              {
                tecnicos.map((tecnico: any)=>{
                  return (
                    <option value={tecnico.id} key={tecnico.id}>{tecnico.name}</option>
                  );
                })
              }
            </select>
            <h4 className="text-center red" >
              {tecnicosErrorMessage}
            </h4>
          </div>
          <div className="form-group">
            <label className="small mb-1" htmlFor="inputCountry">Acción:</label>
            <select className="form-control custom-select" onChange={(e)=>{
              //setAccion(e.target.value)}
            }}>
              <option value={acciones}>{acciones}</option>
            </select>
          </div>
          <div className="mt-4 mb-0">
              <h4 className="text-center font-weight-extrabold red" >{associateErrorMessage}</h4>
          </div>
          {associateSuccess ? 
            <div className="mt-4 mb-0">
              <h4 className="text-center font-weight-extrabold green" >Finca {prevAccion==="Asociar"? 'Asociada':'Desasociada'} Exitosamente</h4>
            </div>
            : null
          }
          <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
            <button className="btn btn-primary btn-lg" onClick={startAssoc}>Guardar</button>
          </div>
        </div>
      );
    }
  }

  return (
    <BasicLayout>
        <main>
          <div className="container-fluid">
            <ol className="breadcrumb mb-4">
              <li className="breadcrumb-item"><a href="homepage.html">Inicio</a></li>
              <li className="breadcrumb-item active">Administracion</li>
            </ol>
            <div className="row">
              <h1 className="mt-4">Asociar Fincas</h1>
            </div>
            <div className="card mb-4">
              <div className="card-header">
                <h5 className="font-weight-extrabold">Asociar Huertas</h5>
              </div>
              {bodyAsociar()}
            </div>
          </div>
        </main>
    </BasicLayout>
  )
};

export default AsociarFincas;