export const REFRESH_START = 'REFRESH_START';
export const REFRESH_END = 'REFRESH_END';

export interface RefreshState {
    isRefreshing: boolean
}

export interface RefreshStartAction {
    type: typeof REFRESH_START;
}
export interface RefreshEndAction {
    type: typeof REFRESH_END;
}

export 
  type RefreshActionTypes = RefreshStartAction | RefreshEndAction;