import numeral from "numeral";
import { getMuestreoInfo_getMuestreo_muestreo_detail as MuestreoDetailType} from "src/apollo/queries/types/getMuestreoInfo";

interface MuestreoTableProps {
  muestreoDetail: MuestreoDetailType[]
}
const MuestreoTable = (props: MuestreoTableProps) => {
  const { muestreoDetail } = props;
  return (
    <div
      className="tab-pane fade show active"
      id="tab2"
      role="tabpanel"
      aria-labelledby="tab2">
      <table className="table-striped table table-bordered" width="100%" cellSpacing="0">
        <tbody>
          <tr>
            <td></td>
            <td colSpan={1}>
              Totales
            </td>
            <td colSpan={1}>
              Positivos
            </td>
            <td colSpan={1}>
              %
            </td>
          </tr>
          {muestreoDetail.map((detail: MuestreoDetailType, index: number) => (
            <tr key={index}>
              <td>{detail.tipo_plaga_enfermedad}</td>
              <td>{numeral(detail.total).format()}</td>
              <td>{numeral(detail.positivos).format()}</td>
              <td>{numeral(detail.incidencia).format('0.00')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default MuestreoTable;