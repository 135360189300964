 export const traducciones = [
    {
        name: 'Wind Direction (cAVG)',
        value: 'Dirección del viento (cAVG)',
    },
    {
        name: 'Daily ETo',
        value: 'ETo diaria',
    },
    {
        name: 'Hourly ETo',
        value: 'ETo por hora',
    },
    {
        name: 'Pyranometer SP-Lite (SUM)',
        value: 'Piranómetro SP-Lite (SUM)',
    },
    {
        name: 'Relative Humidity (AVG)',
        value: 'Humedad relativa (AVG)',
    },
    {
        name: 'Temperature (AVG)',
        value: 'Temperatura (AVG)',
    },
    {
        name: 'Temperature (MAX)',
        value: 'Temperatura (MAX)',
    },
    {
        name: 'Temperature (MIN)',
        value: 'Temperatura (MIN)',
    },
    {
        name: 'Precipitation (SUM)',
        value: 'Precipitación (SUM)',
    },
    {
        name: 'Potential solar radiation (G0)',
        value: 'Radiación solar potencial (G0)',
    },
    {
        name: 'Sunshine duration (Daily)',
        value: 'Duración de la insolación (diaria)',
    },
    {
        name: 'Sunshine duration (Raw)',
        value: 'Duración de la luz solar (Raw)',
    },
    {
        name: 'Wind Speed (AVG)',
        value: 'Velocidad del viento (AVG)',
    },
    {
        name: 'Relative Humidity',
        value: 'Humedad relativa',
    },
    {
        name: 'Wind Direction',
        value: 'Dirección del viento',
    },
    {
        name: 'Pyranometer SP-Lite',
        value: 'Piranómetro SP-Lite',
    },
    {
        name: 'Charging Regulator',
        value: 'Regulador de carga',
    },
    {
        name: 'Wind Speed',
        value: 'Velocidad del viento',
    },
    {
        name: 'Battery Voltage',
        value: 'Voltaje de la batería',
    },
    {
        name: 'Temperature',
        value: 'Temperatura',
    },
    {
        name: 'Precipitation',
        value: 'Precipitación',
    },
    {
        name: 'Leaf Wetness Hours (SUM)',
        value: 'Hora de la humedad de la hoja(SUM)',
    },
    {
        name: 'Leaf Wetness (MAX)',
        value: 'Humedad de la hoja(MAX)',
    },
    {
        name: 'GSM Signal Strength',
        value: 'Intensidad de la señal GSM',
    },
    {
        name: 'Leaf Wetness',
        value: 'Humedad de la hoja',
    },
    {
        name: 'Data Delay',
        value: 'Retraso de datos',
    },
    {
        name: 'Generic Output',
        value: 'Salida genérica',
    },
    {
        name: 'Global Radiation (SUM)',
        value: 'Radiación global(SUM)',
    },{
        name: 'Global Radiation',
        value: 'Radiación global',
    }
];