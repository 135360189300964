import FooterImage from '../assets/images/logos-footer.png';
import NavContent from 'src/containers/NavContent';
import SideBarContent from 'src/containers/SidebarContent';
import { useDispatch, useSelector } from 'react-redux';
import ClassNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { userToggleSidebar } from 'src/store/actions/user';

interface BasicProps {
  children: any;
}
const Basic = (props: BasicProps) =>{
  const { children } = props;
  const { user } = useSelector((state : any) => state);
  const location = useLocation();
  const dispatch = useDispatch();

  const sidebarClass = ClassNames({
    'sb-nav-fixed': true,
    'sb-sidenav-toggled': user.toogleSidebar,
  });

  useEffect(()=>{
    dispatch(userToggleSidebar(false));
  }, [location])

  return (
      <div className={sidebarClass}>
      <NavContent />
      <div id="layoutSidenav">
        <SideBarContent />
        <div id="layoutSidenav_content">
          {children}
          <footer className="py-4 my-4">
            <div className="container-fluid">
              <div className="row">
                <p className="col-lg-6 small">SATIHCAFE&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Sistema de Alerta Temprana para el Cultivo de Café&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;2021</p>
                <div className="col-lg-6"><img src={FooterImage} /></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Basic;